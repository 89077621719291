<div class="emailTemplate-container">
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div class="emailTemplate-header">
        <p>Email Template</p>
        <p-sidebar *ngIf="addTemplateSidebar" class="addTemplate-slider" [(visible)]="addTemplateSidebar"
            styleClass="p-sidebar-lg" [style]="{width: '72vw'}" [showCloseIcon]="true" position="right"
            (onHide)="addTemplateForm.reset(); showPlaceholder = false">
            <div class="addTemplate-header">
                <div class="addTemplate-heading">Add Template</div>
                <div class="header-btn">
                    <button pButton pRipple type="submit" label="Cancel" (click)='cancel()'
                        class="p-button-outlined button-align save-btn"></button>
                    <button pButton pRipple type="submit" label="Save" (click)="saveTemplate()"
                        class="p-button-raised p-button-text button-align" [loading]="saveBtnLoading"></button>
                </div>
            </div>
            <p-divider class="p-divider"></p-divider>
            <form class="templateForm" [formGroup]="addTemplateForm" #form="ngForm" (ngSubmit)="onSave()">
                <span class="p-float-label templateForm-elements">
                    <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name"
                        [options]="categories" formControlName="category" [(ngModel)]="_selectedCategory"
                        [required]="true"
                        [styleClass]="clicked && addTemplateForm.controls['category'].errors ? 'invalid' : ''">
                    </p-dropdown>
                    <label for="dropdown">Category <span class="imp">*</span></label>
                    <app-handle-error [clicked]="clicked" [control]="addTemplateForm.get('category')" name="Category">
                    </app-handle-error>
                </span>

                <span class="p-float-label templateForm-elements">
                    <input type="text" class="inputtext" id="inputtext" pInputText formControlName="templateTitle"
                        [(ngModel)]="_templateTitle"
                        [ngClass]="{'p-filled': addTemplateForm.value.templateTitle, 'invalid': clicked && addTemplateForm.controls['templateTitle'].errors}">
                    <label for="template-title">Template Tiltle <span [style]="{color: '#E24A47'}">*</span></label>
                    <app-handle-error [clicked]="clicked" [control]="addTemplateForm.get('templateTitle')" name="Title">
                    </app-handle-error>
                </span>

                <div class="placeholder-group">
                    <span class="p-float-label templateForm-elements">
                        <input class="inputtext" type="text" id="inputtext" (click)="getElement('title')" pInputText
                            #title (keyup)="getElement('title')" formControlName="subjectTitle"
                            [(ngModel)]="_subjectTitle" (focusout)="removeFocus()"
                            [ngClass]="{'p-filled': addTemplateForm.value.subjectTitle || _subjectTitle, 'invalid': clicked && addTemplateForm.controls['subjectTitle'].errors}">
                        <label for="title">Subject Line <span [style]="{color: '#E24A47'}">*</span></label>
                        <app-handle-error [clicked]="clicked" [control]="addTemplateForm.get('subjectTitle')"
                            name="Subject"></app-handle-error>
                    </span>

                    <button pButton pRipple type="button" (click)="op.hide(); showPlaceholder = true"
                        (mouseenter)="op.toggle($event)" (mouseleave)="op.hide()" label="Add Placeholder"
                        [disabled]="showPlaceholder" class="p-button-outlined"></button>
                </div>

                <textarea placeholder="Template Description" id="content"></textarea>

            </form>
            <p-dialog header="Add Placeholder" [(visible)]="showPlaceholder" class="placeholder-dialog"
                position="top-right"
                [style]="{position: 'fixed', top: '7.875rem', margin: '0 20px', maxWidth: '33.5vw', minWidth: '30vw'}">
                <p-chip *ngFor="let item of placeholders" label="{{item.tag}}" (click)="insertPlaceholder(item.value)">
                </p-chip>
            </p-dialog>
            <p-overlayPanel #op>
                <ng-template pTemplate>
                    <div
                        [style]="{display: 'flex', width: '310px', padding: '10px 16px', boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.06)'}">
                        <em class="info-button"></em>
                        <p [style]="{overflowWrap: 'break-word', color: 'var(--fc-dark)', padding: '2px'}">Set
                            placeholders to customize your email templates automatically.</p>
                    </div>
                </ng-template>
            </p-overlayPanel>
        </p-sidebar>
        <div [style]="{display: 'flex', alignItems:'center'}">
            <span class="p-input-icon-left" [style]="{marginRight: '10px'}">
                <em class="pi pi-search"></em>
                <input class="searchbar" [(ngModel)]="templateSearchTerm" (ngModelChange)="searchTemplate()" type="text"
                    pInputText placeholder="Search Template" />
            </span>
            <button *ngIf="privilege.editEmailTemplate" pButton type="button" label="Add Template"
                (click)="openAddTemplate()" [style]="{height: '38px'}"></button>
        </div>
    </div>

    <div class="emailTemplate-content">
        <div class="categories">
            <div class="category" [ngClass]="{'active' : isAllActive}" (click)="openCategory('All')">
                <p>All</p>
            </div>
            <div class="category" [ngClass]="{'active' : category.isActive}" *ngFor="let category of categories"
                (click)="openCategory(category.name)">
                <p>{{category.name}}</p>
            </div>
            <p-inplace *ngIf="privilege.editEmailTemplate" #ip [style]="{'min-height':'33px'}" class="addCategory">
                <ng-template pTemplate="display">
                    Add Category
                </ng-template>
                <ng-template pTemplate="content">
                    <span class="p-inputgroup newCategory">
                        <input type="text" pInputText placeholder="Category Name" [(ngModel)]="newCategoryName">
                        <button type="button" pButton pRipple icon="pi pi-check" styleClass="p-button-warn"
                            (click)="addCategory(ip)" *ngIf="newCategoryName.length"></button>
                        <button type="button" pButton pRipple icon="pi pi-times" styleClass="p-button-warn"
                            (click)="ip.deactivate($event);" *ngIf="!newCategoryName.length"></button>
                    </span>
                </ng-template>
            </p-inplace>
        </div>
        <div class="templates-container">
            <div class="pageInfo">
                <em class="info-button"></em>
                <p>Recruiters, Job posting information, Candidate related notification, Interview information, Offer
                    information, Candidates conversation notifications and Employee Referrals information email
                    templates will be grouped here.
                </p>
            </div>
            <div *ngFor="let template of _templateList">
                <p-panel [toggleable]="true" [collapsed]="template.collapse" (mouseover)="template.showIcons = true"
                    (mouseout)="template.showIcons = false">
                    <ng-template pTemplate="header">
                        {{template.templateTitle}}
                    </ng-template>
                    <ng-template pTemplate="icons" class="p-panel-icons ">
                        <div [ngClass]="{'hidden': !template.showIcons && template.collapse}">
                            <button *ngIf="template.isEditable && privilege.editEmailTemplate"
                                class="p-panel-header-icon p-link" (click)="deleteTemplate(template, $event)">
                                <span class="pi pi-trash"></span>
                            </button>
                            <button *ngIf="template.isEditable && privilege.editEmailTemplate"
                                class="p-panel-header-icon p-link" (click)="editTemplate(template)">
                                <img src="../../../assets/icons-svg/edit.svg" alt="">
                            </button>
                            <button class="p-panel-header-icon p-link toggler {{!template.collapse ? 'active' : ''}}"
                                (click)="template.collapse = !template.collapse">
                                <span class="pi pi-eye"></span>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <span [style]="{color: 'var(--clr-seconadary)', paddingRight: '10px'}">Subject Line </span>
                        <span class="template-content">{{template.subjectTitle}}
                        </span>
                        <p-divider></p-divider>
                        <span [innerHTML]="template.templateContent" class="template-content"></span>
                    </ng-template>
                </p-panel>
            </div>
        </div>
    </div>
    <p-toast></p-toast>

    <p-confirmPopup></p-confirmPopup>
</div>