<div class="sidebar">
  <div>
    <img class="sidebar-sourceImg" [src]='sourceImg' alt="" />
  </div>
  <div class="sidebar-icons">
    <button class="icon-fun {{option.icon}} {{option.isActive ? 'active' : ''}}"
      *ngFor="let option of sideNavOptions; let i = index;" [pTooltip]="option.name" [showDelay]="200"
      (click)="iconClick(option)">
      <img *ngIf="option.view" src="../assets/sideNav-svg/{{option.icon}}.svg" alt="{{option.icon}}">
    </button>
  </div>
</div>