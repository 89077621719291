import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgOtpInputModule } from 'ng-otp-input';

// Custom Modules 
import { JobModule } from './job/job.module';
import { SharedModule } from './shared/shared.module';
import { EventsModule } from './events/events.module';
import { RolesModule } from './roles/roles.module';
import { UserModule } from './user/user.module';
import { EmailModule } from './email/email.module';
import { SettingsModule } from './settings/settings.module';
import { AppRoutingModule } from './app-routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';

//custom Componenets
import { AppComponent } from './app.component';
import { JobListComponent } from './job-list/job-list.component';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PasswordModule } from 'primeng/password';
import { TalentpoolManagementComponent } from './talentpool/talentpool-management/talentpool-management.component';
import { CandidatePanelComponent } from './candidate-panel/candidate-panel.component';

@NgModule({
  declarations: [
    AppComponent,
    JobListComponent,
    HeaderComponent,
    SideNavComponent,
    ApplicationFormComponent,
    DashboardComponent,
    LoginComponent,
    TalentpoolManagementComponent,
    CandidatePanelComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JobModule,
    UserModule,
    NgxDropzoneModule,
    EmailModule,
    SharedModule,
    EventsModule,
    RolesModule,
    PasswordModule,
    NgOtpInputModule,
    SettingsModule,
    OAuthModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
