<div class="pageInfo">
    <div style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
        <div style="display: flex;justify-content:space-between; align-items: center;">   
            <em class="pi pi-building"></em>
            <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Company Details</p>
        </div>
        <div (click)="openAddTemplate()" style="display: flex;justify-content:center; align-items: center;">
            <div style="cursor: pointer; padding:10px; background-color:white">
                <em style="display: flex;justify-content:center; align-items: center;" class="pi pi-pencil"></em>
            </div>
        </div>
    </div>
</div>
<div class="info-card">
  <div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
  </div>
    <div *ngIf="!isLoading" [style]="{display: 'block', flexWrap: 'wrap'}">
        <div class="info-element">
            <span class="info-line"></span>
            <div [style]="{display: 'flex', flexDirection: 'column'} ">
                <p [style]="{color: 'var(--fc-light-dark)'}">Company Logo</p>
                <div [style]="{color: 'var(--fc-dark)', width: 'fit-content'}">
                  <img class="company-logo_canvas" [src]="logoSrc" alt="" srcset="">
                </div>
            </div>
        </div>
        <div class="info-element">
            <span class="info-line"></span>
            <div [style]="{display: 'flex', flexDirection: 'column'} ">
                <p [style]="{color: 'var(--fc-light-dark)'}">Login Banner</p>
                <div [style]="{color: 'var(--fc-dark)', width: 'fit-content'}">
                  <img class="company-logo_canvas" [src]="loginBannerSrc" alt="" srcset="">
                </div>
            </div>
        </div>
        <div *ngFor="let info of userInfo; let i = index">
            <div class="info-element">
                <span class="info-line"></span>
                <div [style]="{display: 'flex', flexDirection: 'column'} ">
                    <p [style]="{color: 'var(--fc-light-dark)'}">{{info.field}}</p>
                    <p [style]="{color: 'var(--fc-dark)', width: 'fit-content'}">{{info.value}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar *ngIf="addTemplateSidebar" class="addTemplate-slider" [(visible)]="addTemplateSidebar" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
(onHide)="companyDetails.reset(); showPlaceholder = false; getCompanyDetails()" >
  <div class="addTemplate-header">
  <div class="addTemplate-heading"><em style="margin-right: 12.5px;" class="pi pi-building"></em>Edit Company Details</div>
  <div class="header-btn">
      <button pButton pRipple type="submit" label="Cancel" (click)='cancel()'
          class="p-button-outlined button-align save-btn"></button>
      <button pButton pRipple type="submit" label="Save" (click)="saveTemplate()" [disabled]="saveDisable"
          class="p-button-raised p-button-text button-align" [loading]="saveBtnLoading"></button>
  </div>
  </div>
  <p-divider class="p-divider"></p-divider>
  <div class="templateForm" [formGroup]="companyDetails">
  <span class="p-float-label templateForm-elements">
      <input class="inputtext" type="text" id="template-title" pInputText formControlName="company" [(ngModel)]="companyName" [ngClass]="{'p-filled': companyDetails.value.company}">
      <label for="template-title">Company <span [style]="{color: '#E24A47'}"> *</span></label>
  </span>
  <span class="p-float-label templateForm-elements">
    <input class="inputtext" [readOnly]="true" type="text" pInputText [value]="companyEmail" [ngClass]="{'p-filled': companyEmail, 'disabled-input': true}">
    <label for="template-title">Email <span [style]="{color: '#E24A47'}"> *</span></label>
  </span>
  <div style="display: flex; align-items: center; margin:1vh 0px">
      <div *ngIf="logoSrcEdit && logoSrcEdit.length && logoSrcEdit != defaultLogo" style="display: flex; align-items:center; height: 100px;margin-right: 80px;">
          <img class="uploaded-media" src="{{logoSrcEdit}}" alt="upload media" srcset="">
      </div>
      <div class="profilePhoto form-details">
          <div class="fileUpload-dropzone" ngx-dropzone [accept]="'image/*'" [multiple]="false"
              (change)="onSelectLogo($event, 'edit')">
              <ngx-dropzone-label>
                  <div class="dropzone-label">
                      <em class="pi pi-image"></em>
                      <div>
                          <p class="upload-text">click to <span style="font-weight: bold;">replace</span> or <span style="font-weight: bold;">drag and drop</span></p>
                          <p class="upload-text">SVG, PNG, JPG or GIF (800 x 400px)</p>
                      </div>
                  </div>
              </ngx-dropzone-label>
          </div>
      </div>
      <div *ngIf="logoSrcEdit && logoSrcEdit.length && logoSrcEdit != defaultLogo">
          <p (click)="deleteFile('edit')" style="padding:10px 30px; border: 1px solid var(--clr-seconadary);border-radius: 4px;margin-left: 30px;cursor: pointer;">Delete</p>
      </div>
  </div>
  <div style="display: flex; align-items: center; margin:1vh 0px">
      <div *ngIf="loginBannerEdit && loginBannerEdit.length && loginBannerEdit != defaultLoginBanner" style="display: flex; align-items:center; height: 100px;margin-right: 80px;">
          <img class="uploaded-media" src="{{loginBannerEdit}}" alt="upload media" srcset="">
      </div>
      <div class="profilePhoto form-details">
          <div class="fileUpload-dropzone" ngx-dropzone [accept]="'image/*'" [multiple]="false"
              (change)="onSelectLogo($event, 'edit', 'banner')">
              <ngx-dropzone-label>
                  <div class="dropzone-label">
                      <em class="pi pi-image"></em>
                      <div>
                          <p class="upload-text">click to <span style="font-weight: bold;">replace</span> or <span style="font-weight: bold;">drag and drop</span></p>
                          <p class="upload-text">SVG, PNG, JPG or GIF Formats Only</p>
                      </div>
                  </div>
              </ngx-dropzone-label>
          </div>
      </div>
      <div *ngIf="loginBannerEdit && loginBannerEdit.length && loginBannerEdit != defaultLoginBanner">
          <p (click)="deleteFile('edit', 'banner')" style="padding:10px 30px; border: 1px solid var(--clr-seconadary);border-radius: 4px;margin-left: 30px;cursor: pointer;">Delete</p>
      </div>
  </div>
  <div class="form-salary companyForm-elements">
      <span class="p-input-icon-right p-float-label companyForm-elements">
        <input class="inputtext" [ngClass]="{ 'p-filled': industry }" style="width: 100%" type="text" pInputText
          inputId="dropdown" formControlName="industry" [(ngModel)]="industry"
          (click)="industryOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
        <em class="pi pi-angle-down dropdown"></em>
        <label for="dropdown">Industry<span [style]="{ color: '#E24A47' }"> *</span></label>
        <p-overlayPanel #industryOverlay>
          <ng-template pTemplate>
            <div *ngFor="let field of industryList">
              <p (click)="industry = field.label; industryOverlay.hide()" class="department-options">
                {{ field.label }}
              </p>
            </div>
          </ng-template>
        </p-overlayPanel>
      </span>
    </div>
  <div class="form-salary companyForm-elements">
      <span class="p-input-icon-right p-float-label companyForm-elements">
        <input class="inputtext" [ngClass]="{ 'p-filled': primaryLanguage }" style="width: 100%" type="text" pInputText
          inputId="dropdown" formControlName="primaryLanguage" [(ngModel)]="primaryLanguage"
          (click)="primaryLanguageOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
        <em class="pi pi-angle-down dropdown"></em>
        <label for="dropdown">Primary Language<span [style]="{ color: '#E24A47' }"> *</span></label>
        <p-overlayPanel #primaryLanguageOverlay>
          <ng-template pTemplate>
            <div *ngFor="let field of primaryLanguageList">
              <p (click)="primaryLanguage = field.label; primaryLanguageOverlay.hide()" class="department-options">
                {{ field.label }}
              </p>
            </div>
          </ng-template>
        </p-overlayPanel>
      </span>
    </div>
  <div class="form-salary companyForm-elements">
      <span class="p-input-icon-right p-float-label companyForm-elements">
        <input [ngClass]="{ 'p-filled': companyCategory }" style="width: 100%" type="text" pInputText
          inputId="dropdown" formControlName="companyCategory" [(ngModel)]="companyCategory"
          (click)="companyCategoryOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
        <em class="pi pi-angle-down dropdown"></em>
        <label for="dropdown">Company Category<span [style]="{ color: '#E24A47' }"> *</span></label>
        <p-overlayPanel #companyCategoryOverlay>
          <ng-template pTemplate>
            <div *ngFor="let field of companyCategoryList">
              <p (click)="companyCategory = field; companyCategoryOverlay.hide()" class="department-options">
                {{ field }}
              </p>
            </div>
          </ng-template>
        </p-overlayPanel>
      </span>
    </div>
    <span class="p-float-label templateForm-elements">
      <!-- <input type="text" id="template-title" pInputText formControlName="company" [ngClass]="{'p-filled': companyDetails.value.companay}">
      <label for="template-title">Company <span [style]="{color: '#E24A47'}"> *</span></label> -->
      <input
      formControlName="panNumber"
      class="inputEmail panInput inputtext"
      [ngClass]="{ 'p-filled': companyDetails.value.panNumber }"
      type="text"
      pInputText
      [(ngModel)]="panNumber"
    />
    <label for="inputtext">PAN Number <span [style]="{color: '#E24A47'}"> *</span></label>
    <!-- <span class="inputEmailIcon">
      <em *ngIf="this.panNumberShow" (click)="this.panNumberShow = !this.panNumberShow; showPassword('panInput')" class="pi pi-eye"></em>
      <em *ngIf="!this.panNumberShow" (click)="this.panNumberShow = !this.panNumberShow; showPassword('panInput')" class="pi pi-eye-slash"></em>
  </span> -->
  </span>
    <span class="p-float-label templateForm-elements">
      <!-- <input type="text" id="template-title" pInputText formControlName="company" [ngClass]="{'p-filled': companyDetails.value.companay}">
      <label for="template-title">Company <span [style]="{color: '#E24A47'}"> *</span></label> -->
      <input
      formControlName="gstNumber"
      class="inputEmail gstInput inputtext"
      [ngClass]="{ 'p-filled': companyDetails.value.gstNumber }"
      type="number"
      pInputText
      [(ngModel)]="gstNumber"
    />
    <label for="inputtext">GST Number<span [style]="{color: '#E24A47'}"> *</span></label>
    <!-- <span class="inputEmailIcon">
      <em *ngIf="this.gstNumberShow" (click)="this.gstNumberShow = !this.gstNumberShow; showPassword('gstInput')" class="pi pi-eye"></em>
      <em *ngIf="!this.gstNumberShow" (click)="this.gstNumberShow = !this.gstNumberShow; showPassword('gstInput')" class="pi pi-eye-slash"></em>
  </span> -->
  </span>
  <span class="p-float-label templateForm-elements">
      <!-- <input type="text" id="template-title" pInputText formControlName="company" [ngClass]="{'p-filled': companyDetails.value.companay}">
      <label for="template-title">Company <span [style]="{color: '#E24A47'}"> *</span></label> -->
      <input
      formControlName="tinNumber"
      class="inputEmail gstInput inputtext"
      [ngClass]="{ 'p-filled': companyDetails.value.tinNumber }"
      type="number"
      pInputText
      [(ngModel)]="tinNumber"
    />
    <label for="inputtext">TIN Number<span [style]="{color: '#E24A47'}"> *</span></label>
    <!-- <span class="inputEmailIcon">
      <em *ngIf="this.tinNumberShow" (click)="this.tinNumberShow = !this.tinNumberShow; showPassword('tinInput')" class="pi pi-eye"></em>
      <em *ngIf="!this.tinNumberShow" (click)="this.tinNumberShow = !this.tinNumberShow; showPassword('tinInput')" class="pi pi-eye-slash"></em>
  </span> -->
  </span>
  <!-- <div style="display: flex; align-items: center;">
      <span class="p-float-label templateForm-elements">
          <input type="text" id="template-title" pInputText formControlName="templateTitle"
              [(ngModel)]="_templateTitle" [ngClass]="{'p-filled': companyDetails.value.templateTitle}">
          <label for="template-title">New Email <span [style]="{color: '#E24A47'}">*</span></label>
      </span>
      <div style="background: #326BEE33; color: var(--clr-primary); height: 2.5rem; margin-left: 27px; border-radius: 4px; display: flex; justify-content: center; align-items: center;cursor: pointer;">
          <p style="padding:8px 18px; font-size:var(--fs-medium);">Verify Email</p>
      </div>   
  </div>
  <div>
      <p style="font-size:var(--fs-large); margin: 50px 0px 10px 0px;">Almost there!</p>
      <div style="font-size:var(--fs-medium);">
          <p>An email with a verification code has been sent to</p>
          <p>admin@wiley.com</p>
      </div>
      <div style="margin-top: 20px;">
          <p>Dont't recieve a code? <span style="color: var(--clr-primary); cursor: pointer;">Request again</span></p>
      </div>
  </div> -->
  </div>
</p-sidebar>
<p-toast></p-toast>


<!-- <span style="margin-top:10px; margin-left:16px" *ngIf="info.field === 'Email/Username' || info.field === 'Passworde'">
<span style="padding:5px; background-color:aliceblue; cursor: pointer;">Change</span>
</span> -->