<div class="header stageDetails-header">
    <p>
        {{ stage.name }}
        <span>{{ stage.type ? "(" + stage.type + ")" : "" }}</span>
    </p>
    <div class="stageDetails-header-buttons">
        <!-- <button pButton
            *ngIf="scheduledBy == 'Manually' && stage.type == 'Interview' || savedSchedule?.scheduledBy == 'manually'"
            pRipple type="button" label="Manage Schedule" class="p-button-raised p-button-sm p-button-text"
            (click)="openSlots()"></button> -->
        <button *ngIf="showSummary" pButton pRipple type="button"
            class="p-button-raised p-button-sm p-button-text edit"><em (click)="showSummary = false"
                class="pi pi-cog"></em></button>
    </div>
</div>
<div *ngIf="!showSummary">
    <!-- Manage Schedule for Test Stage -->
    <div class="stage-content testAndSchedule" *ngIf="stage.type == 'Test'">
        <p class="manage-label">Manage Tests and Schedules</p>
        <div class="course-dropdown">
            <div [style]="{display: 'flex', flexDirection: 'row'}">
                <span class="p-float-label">
                    <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" optionLabel="name" [options]="contestList"
                        [(ngModel)]="selectedContest" (onChange)="getContestTestId()">
                    </p-dropdown>
                    <label for="dropdown">Contest</label>
                </span>
            </div>
        </div>

        <div class="testAndSchedule-buttons clearAndSave-buttons">
            <button pButton pRipple type="button" label="Clear" (click)="clearTestAndSchedules()"
                class="clear p-button-outlined p-button-secondary"></button>
            <button pButton pRipple type="button" label="Save" class="save p-button-outlined "
                (click)="saveTestAndSchedules()" [disabled]="!selectedContest"
                [ngClass]="{'invalid': !selectedContest}"></button>
        </div>
    </div>

    <!-- Manage Feedback for Interview Stage -->
    <div class="stage-content testAndSchedule" *ngIf="stage.type == 'Interview' || stage.type == 'OfflineInterview'">
        <p class="manage-label">Manage Feedback Form</p>
        <div class="course-dropdown">
            <div [style]="{display: 'flex', flexDirection: 'row'}">
                <span class="p-float-label">
                    <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" optionLabel="interviewType"
                        [options]="formsList" [(ngModel)]="selectedForm">
                    </p-dropdown>
                    <label for="dropdown">Feedback Form</label>
                </span>
            </div>
        </div>

        <div class="testAndSchedule-buttons clearAndSave-buttons">
            <button pButton pRipple type="button" label="Clear" (click)="clearTestAndSchedules()"
                class="clear p-button-outlined p-button-secondary"></button>
            <button pButton pRipple type="button" label="Save" class="save p-button-outlined "
                (click)="saveFeedbackForm()" [disabled]="!selectedForm" [ngClass]="{'invalid': !selectedForm}"></button>
        </div>
    </div>
</div>

<div *ngIf="showSummary">
    <div class="stage-content summary" *ngIf="stage.type == 'Test'">
        <div class="summary-info">
            <p class="info-label">Test Source</p>
            <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2" *ngIf="!contestName"></p-skeleton>
            <p class="info-value">{{contestName}} </p>
        </div>
        <hr>
        <div class="summary-info">
            <p class="info-label">Scheduled By</p>
            <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2" *ngIf="!contestName"></p-skeleton>
            <p class="info-value">{{contestName && savedSchedule.scheduledBy.charAt(0).toUpperCase() +
                savedSchedule.scheduledBy.slice(1)}} </p>
        </div>
    </div>

    <div class="stage-content summary" *ngIf="stage.type == 'Interview' || stage.type == 'OfflineInterview'">
        <div class="summary-info">
            <p class="info-label">Feedback Form</p>
            <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2" *ngIf="!formName"></p-skeleton>
            <p class="info-value">{{formName}} </p>
        </div>
    </div>
</div>

<div *ngIf="stage.type == 'Test' || stage.type == 'Interview' || stage.type == 'OfflineInterview'">
    <div class="header stageDetails-header">
        <p>Manage Emails</p>
        <div class="stageDetails-header-buttons">
        <!-- <button pButton
            *ngIf="scheduledBy == 'Manually' && stage.type == 'Interview' || savedSchedule?.scheduledBy == 'manually'"
            pRipple type="button" label="Manage Schedule" class="p-button-raised p-button-sm p-button-text"
            (click)="openSlots()"></button> -->
            <button *ngIf="showSummary" pButton pRipple type="button"
            class="p-button-raised p-button-sm p-button-text edit"><em (click)="showMailSummary = false"
            class="pi pi-cog"></em></button>
        </div>
    </div>

    <div class="stage-content mailTemplate" >
        <div style="display: flex; align-items: flex-start; justify-content: flex-start;" *ngIf="!showMailSummary">
            <p *ngIf="stage.type == 'Test'" class="manage-label template">Send Test Link</p>
            <p *ngIf="stage.type == 'Interview'" class="manage-label template">Send Interview Link</p>
            <p *ngIf="stage.type == 'OfflineInterview'" class="manage-label template">Schedule Interview Link</p>
            <div class="template-dropdown">
                <div [style]="{display: 'flex', flexDirection: 'column'}">
                    <!-- <span class="p-float-label"> -->
                        <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" optionLabel="templateTitle" [options]="templateList"
                            [(ngModel)]="selectedMailTemplate" (ngModelChange)="validateTemplate()" placeholder="Select">
                        </p-dropdown>
                        <!-- <label for="dropdown">Contest</label> -->
                    <!-- </span> -->
                    <span class="error-paceholder p-error" *ngIf="errorPlaceholder.length">
                        Please select the template with the following placeholder :
                        <div *ngFor="let data of errorPlaceholder; let i = index">
                            <span>{{i+1 }}. {{data}}</span>
                        </div>
                    </span>
                    <span class="email-preview" *ngIf="selectedMailTemplate?.templateTitle && errorPlaceholder.length === 0" [innerHTML]="selectedMailTemplate?.templateContent">
                    </span>
                </div>
            </div>
        </div>
        
        <div class="testAndSchedule-buttons clearAndSave-buttons" style="padding-top: 20px;" *ngIf="selectedMailTemplate?.templateTitle && !showMailSummary && errorPlaceholder.length == 0" >
            <button pButton pRipple type="button" label="Clear" (click)="clearEmailTemplate()"
                class="clear p-button-outlined p-button-secondary"></button>
            <button pButton pRipple type="button" label="Save" class="save p-button-outlined " [loading]="manageTemplateLoad"
                (click)="saveEmailTemplate()"></button>
        </div>

        <div *ngIf="showMailSummary">
            <div class="summary-info" *ngIf="stage.type == 'Test' || stage.type == 'Interview' || stage.type == 'OfflineInterview'">
                <p *ngIf="stage.type == 'Test'" class="info-label">Send Test Link</p>
                <p *ngIf="stage.type == 'Interview'" class="info-label">Send Interview Link</p>
                <p *ngIf="stage.type == 'OfflineInterview'" class="info-label">Schedule Interview Link</p>
                <p-skeleton width="6rem" height="1.2rem" styleClass="mb-2" *ngIf="!savedTemplateTitle"></p-skeleton>
                <p class="info-value">{{savedTemplateTitle}} </p>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>