import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftAuthserviceService {
    
  api_url = environment.HOST.API_URL;
  azureClientId: string = environment.AZURE.CLIENT_ID;
  azureTenantId: string = environment.AZURE.TENANT_ID;
  encryptKey: string = environment.ENCRYPTION.ENCRYPTION_KEY;

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      schoolid: schoolId,
    });
    return { headers };
  }

  constructor(private http: HttpClient, private oauthService: OAuthService) { }

  configure() {
    const authConfig: AuthConfig = {
      issuer: `https://login.microsoftonline.com/${this.azureTenantId}/v2.0`,
      redirectUri: window.location.origin + '/general-settings',
      clientId: this.azureClientId,
      responseType: 'code',
      scope: 'openid profile User.Read Mail.Read Calendars.Read Calendars.ReadWrite Calendars.ReadWrite.Shared',
      strictDiscoveryDocumentValidation: false,
    };
    
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  revokeSessions(): void {
    const url = 'https://graph.microsoft.com/v1.0/me/revokeSignInSessions';
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.getToken()}`,
    });
  
    this.http.post(url, null, { headers })
      .subscribe(
        () => {
          console.log('Access revoked successfully');
        },
        (error) => {
          console.error('Error revoking access:', error);
        }
      );
  }

  checkTokenValidity():Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.getToken()}`,
    });
  
    return this.http.get('https://graph.microsoft.com/v1.0/me', { headers })
  }

  createEvent(requestBody: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`
    });

    return this.http.post('https://graph.microsoft.com/v1.0/me/events', requestBody, { headers: headers })
  }

  getToken(){
    let encryptedToken = localStorage.getItem('oauthAccessToken') || '';
    return CryptoJS.AES.decrypt(encryptedToken, this.encryptKey).toString(CryptoJS.enc.Utf8);
  }

  getCalendarInvites(eventId:string, status: string):Observable<any>{
    return this.http.get(`${this.api_url}/calendar-invite/${eventId}/${status}`, this.options())
  }

  createCalendarEvent(eventId: string, stageId: string, payload:any):Observable<any>{
    return this.http.post(`${this.api_url}/calendar-invite/${eventId}/${stageId}`, payload, this.options())
  }

  updateMeetingStatus(inviteId:string, status:string){
    return this.http.post(`${this.api_url}/calendar-invite/updateStatus/${inviteId}/${status}`, {}, this.options());
  }

  getMeetingTimes(requestBody: any):Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`
    });

    return this.http.post('https://graph.microsoft.com/v1.0/me/findMeetingTimes', requestBody, { headers: headers })
  }
}
