import { Component, OnInit } from '@angular/core';
import { EventServicesService } from 'src/app/services/event-services.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from 'src/app/services/global-services.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  providers: [MessageService]
})
export class EventDetailsComponent implements OnInit {

  isLoading: boolean = false;
  readMore: boolean = false;
  viewMorelocation: boolean = false;
  addEventSlider: boolean = false;
  showAddRole: boolean = false;

  eventDetails: any = {};
  eventJobDetails: any = {};
  roleDetails: any = {};
  locationDetails: any = {};
  locationItems: Array<any> = [];
  jobRoleList: Array<any> = [];
  dateFormat: any;

  eventId: string = '';
  jobId: string = '';

  locationsLength: number = 0;

  constructor(
    private eventServices: EventServicesService,
    private breadcrumbService: NgDynamicBreadcrumbService,
    private globalService: GlobalServicesService,
    ) {
    this.locationItems = [
      { label: 'City : ', field: 'location' },
      { label: 'Hiring Manager : ', field: 'hiringManager' },
      { label: 'Bussiness Unit : ', field: 'businessUnit' },
      { label: 'Openings : ', field: 'openings' },
      { label: 'Maximum Applicants :', field: 'maximumApplicants' },
      { label: 'Diversity Male :', field: 'maleDiversity' },
      { label: 'Diversity Female :', field: 'femaleDiversity' },
    ]
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    this.eventId = window.localStorage.getItem("selectedEventId") || '';
    this.jobId = window.localStorage.getItem("selectedJobId") || '';
    this.getEventDetails();
    this.getEventJobDetails();
  }

  toggleLocations = () => {
    this.viewMorelocation = !this.viewMorelocation;
    if (!this.viewMorelocation) {
      this.locationDetails = this.roleDetails.locationInformation.slice(0, 1);
    } else {
      this.locationDetails = this.roleDetails.locationInformation;
    }
  }

  getEventDetails = () => {
    this.isLoading = true;
    this.eventServices.getEventById(this.eventId).subscribe(response => {
      this.eventDetails = response.data ? response.data : {};
      let _jobRoles = this.eventDetails?.jobRole;
      this.jobRoleList = _jobRoles.map((role: any) => ({ roleName: role.roleName, roleId: role.roleId, isActive: false }))
      this.jobRoleList[0].isActive = true;
      this.getJobRoles(this.jobRoleList[0].roleId);
        const breadcrumbs = [
            {
              label: 'Manage Events',
              url: 'events'
            },
            {
              label: this.eventDetails.eventTitle,
              url: ''
            },
          ];
        this.breadcrumbService.updateBreadcrumb(breadcrumbs);
        localStorage.setItem('selectedEventName', this.eventDetails.eventTitle);
        });
  }

  getEventJobDetails = () => {
    this.eventServices.getEventJobById(this.eventId, this.jobId).subscribe(response => {
      this.eventJobDetails = response.data ? response.data : [];
    })
  }

  getJobRoles = (eventId: string) => {
    this.isLoading = true;
    this.eventServices.getRoleById(eventId).subscribe(response => {
      this.roleDetails = response.data ? response.data : [];
      this.locationsLength = this.roleDetails.locationInformation?.length;
      this.locationDetails = this.roleDetails.locationInformation?.slice(0, 1);
      this.isLoading = false;
    })
  }

  openRole = (id: string) => {
    this.jobRoleList.forEach((role: any) => {
      if (role.roleId === id) {
        role.isActive = true;
        this.roleDetails = role
      } else {
        role.isActive = false;
      }
    })
    this.getJobRoles(id);
  }
}
