<div class="slot-header">
    <div class="stageName">
        <p>Stage name : <span [style]="{fontWeight: 'var(--fw-semi-bold)'}"> {{stageName}}(Interview)</span></p>
        <button pButton pRipple type="button" label="Workflow" class="p-button-outlined workflow-button"
            (click)="gotoWorkflow()"></button>
    </div>
    <div>
        <p-sidebar class="addSlot-slider" [(visible)]="addSlotSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true"
            position="right" (onHide)="getAllSlots()">

            <div class="addSlot-header">
                <div class="addSlot-heading">Add Slot</div>
                <div class="header-btn">
                    <button pButton pRipple type="submit" label="Save"
                        class="p-button-raised p-button-text button-align" (click)="saveSlot()"
                        [loading]="saveBtnLoading"></button>
                </div>
            </div>
            <p-divider class="p-divider"></p-divider>

            <div class="slotForm" [formGroup]="addSlotForm" (ngSubmit)="saveSlot()">
                <div *ngIf="clicked && addSlotForm.invalid"
                    [style]="{color: 'var(--clr-red-star)', fontSize: '13px', marginBottom: '5px'}">
                    Enter all the Fields *
                </div>

                <span class="p-float-label slotForm-elements">
                    <p-calendar dateFormat="dd M, yy" inputId="dateformat" formControlName="Date" dataType="string"
                        [showIcon]="true">
                    </p-calendar>
                    <label for="dateformat">Date<span class="imp">*</span></label>
                </span>

                <span class="p-float-label slotForm-elements">
                    <p-calendar [timeOnly]="true" [hourFormat]="'12'" inputId="startTime" dataType="string"
                        formControlName="startTime">
                    </p-calendar>
                    <label for="startTime">From <span class="imp">*</span></label>
                </span>

                <span class="p-float-label slotForm-elements">
                    <p-calendar [timeOnly]="true" [hourFormat]="'12'" inputId="endTime" dataType="string"
                        formControlName="endTime">
                    </p-calendar>
                    <label for="endTime">To <span class="imp">*</span></label>
                </span>

                <span class="p-float-label slotForm-elements">
                    <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" formControlName="limit"
                        [options]="slotLimits">
                    </p-dropdown>
                    <label for="endTime">Limit <span class="imp">*</span></label>
                </span>
            </div>

        </p-sidebar>
        <p-splitButton label="New Slot" class="addSlot-button" styleClass="p-button-sm mr-2 mb-2"
            (onClick)="addSlotSlider = true;">
        </p-splitButton>
    </div>
</div>

<div class="table">

    <p-sidebar class="filterSidebar" [transitionOptions]="'0ms'" [(visible)]="showFilterSidebar"
        [style]="{ padding: '0'}" [modal]="false" [baseZIndex]="0" ariaCloseLabel="Filter">
        <p class="sidebar-title">Filter</p>
        <span class="p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText placeholder="Search">
        </span>
        <div class="filterSidebarContent">
            <p-accordion [multiple]="true" [collapseIcon]="'pi pi-minus'">
                <p-accordionTab header='Date'>
                    <p-calendar [inline]="true" [showWeek]="true" [style]="{width: 'auto'}"></p-calendar>
                </p-accordionTab>
            </p-accordion>
        </div>
    </p-sidebar>

    <!-- Download Sidebar -->
    <p-sidebar class="userListDownload downloadSidebar" [modal]="false" [transitionOptions]="'0ms'"
        [style]="{ padding: '0', border: '2px solid white'  }" [(visible)]="showDownloadSidebar">
        <p class="sidebar-title">Download Data</p>

        <span class="columnSearch p-input-icon-left">
            <em class="pi pi-search"></em>
            <input type="text" pInputText [(ngModel)]="downloadSearchTerm" placeholder="Search"
                (ngModelChange)="searchColumns($event)" />
        </span>

        <div *ngFor="let col of colsToDownload" class="field-checkbox">
            <p-checkbox [inputId]="col.field" name="category" [value]="col" [(ngModel)]="checkedColumns"></p-checkbox>
            <label [style]="{paddingLeft: '10px'}" [for]="col.field">{{col.header}}</label>
        </div>

        <div class="download-container">
            <p>Download as</p>
            <div class="radioButton-group">
                <div class="radioButton">
                    <p-radioButton value="excel" name="exportButtons" [(ngModel)]="downloadAs" inputId="excel">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'excel' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="excel"><em class="pi pi-file"></em>Excel</label>
                </div>
                <div class="radioButton">
                    <p-radioButton value="csv" name="exportButtons" [(ngModel)]="downloadAs" inputId="csv">
                    </p-radioButton>
                    <label [style]="{color: downloadAs === 'csv' ? 'var(--fc-primary)' : 'var(--fc-light-dark)'}"
                        for="csv"><em class="pi pi-file-excel"></em>CSV</label>
                </div>
            </div>

            <button pButton pRipple type="button" label="Download" class="p-button-raised"
                (click)="(downloadAs === 'excel') ? exportSlot('excel') : exportSlot('csv')"></button>
        </div>

    </p-sidebar>

    <!-- Table -->
    <p-table #dt [columns]="slotsCol" [value]="slotsList" [resizableColumns]="true" [rows]="pageSize"
        [loading]="loadingSpinner" [scrollable]="true" scrollHeight="75vh" scrollDirection="both"
        responsiveLayout="scroll" dataKey="id" [selection]="selectedUsers" [globalFilterFields]="['userType']"
        [exportFilename]="exportName">
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="table-header-left">
                    <!-- <button pButton class="p-button-text simple-btn{{showFilterSidebar ? '-active' : '' }}"
                        icon="pi pi-filter" (click)="showFilterSidebar = true; showDownloadSidebar = false">
                    </button> -->
                    <button pButton class="p-button-text simple-btn{{showDownloadSidebar ? '-active' : '' }}"
                        icon="pi pi-download" (click)="showDownloadSidebar = true; showFilterSidebar = false">
                    </button>
                    <p-divider layout="vertical"></p-divider>
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="getSlotsByLimit()"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"></p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton icon="pi pi-angle-left" (click)="prev()" [disabled]="isFirstPage()"
                        class="p-button-secondary p-button-text simple-btn"></button>
                    <button pButton icon="pi pi-angle-right" (click)="next()" [disabled]="isLastPage()"
                        class="p-button-secondary p-button-text simple-btn"></button>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr class="table-heading" [ngClass]="{'moveTable': showFilterSidebar || showDownloadSidebar}">
                <th scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th [style]="{width: '15vw'}" scope="col" pSortableColumn="id" pResizableColumn pFrozenColumn>
                    Slot ID
                    <p-sortIcon class="sort-icon" field="id"></p-sortIcon>
                </th>
                <th [style]="{width: '13vw'}" scope="col" pSortableColumn="{{ slot.field }}"
                    *ngFor="let slot of columns" pResizableColumn>
                    {{ slot.header }}
                    <p-sortIcon class="sort-icon" field="{{ slot.field }}"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-slot let-columns="columns">
            <tr class="row-slot-list" [ngClass]="{'moveTable': showFilterSidebar || showDownloadSidebar}"
                [style]="{cursor: 'pointer'}">
                <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="slot"></p-tableCheckbox>
                </td>
                <td [style]="{width: '15vw'}" pFrozenColumn>{{ slot.id }}</td>
                <td *ngFor="let col of columns" [style]="{width: '13vw'}">
                    <span>{{ slot[col.field] }}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-toast></p-toast>
</div>