<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="pageInfo">
    <div style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
        <div style="display: flex;justify-content:space-between; align-items: center;">   
            <em class="pi pi-map-marker"></em>
            <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Branches</p>
        </div>
        <div *ngIf="true">
            <button style="height:38px; font-size:var(--fs-medium)" pButton pRipple type="submit" label="Add New" (click)="openAddTemplate()"
            ></button>
        </div>
    </div>
</div>
<div class="info-card">
    <div style="padding:20px 20px 50px 20px" *ngFor="let branch of branchData; index as i">
        <div class="border-radius_top" style="padding: 20px 20px 10px 20px; display: flex; justify-content: space-between;align-items: center; border:2px solid #DFE3E7">
            <p style="font-size: var(--fs-large);font-weight:var(--fw-semi-bold)">{{ branch.branchName }}</p>
            <div>
                <em style="margin-right: 10px;padding:10px; background-color:  rgba(102, 102, 102, 0.05);border-radius: 4px; cursor: pointer" 
                class="pi pi-trash" (click)="deleteBranch(i)"></em>
                <em style="margin: 10px;padding:10px;background-color:  rgba(102, 102, 102, 0.05);border-radius: 4px; cursor: pointer" 
                class="pi pi-pencil" (click)="editBranch(i)"></em>
            </div>
        </div>
        <div class="border-radius_bottom" style="padding:20px;  
        border-left:   2px solid #DFE3E7;
        border-right:  2px solid #DFE3E7; 
        border-bottom: 2px solid #DFE3E7;
        font-size: var(--fs-medium)">
            <div style="display: flex;margin-bottom: 14px;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-map-marker"></em>
                <p>{{ branch.branchAddress }}</p>
            </div>
            <div style="display: flex;margin-bottom: 10px;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-phone"></em>
                <p>{{ branch.branchContact }}</p>
            </div>
            <div style="display: flex;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-at"></em>
                <p>{{ branch.branchEmail }}</p>
            </div>
        </div>
    </div>
</div>
<p-sidebar *ngIf="addTemplateSidebar" class="addTemplate-slider" [(visible)]="addTemplateSidebar" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
(onHide)="sliderHide()">
<div class="addTemplate-header">
<div class="addTemplate-heading"><em style="margin-right: 12.5px;" class="pi pi-map-marker"></em>{{edit ? "Edit Branch" : "Add New Branch"}}</div>
<div class="header-btn">
    <button pButton pRipple type="submit" label="Cancel" (click)='cancel()'
        class="p-button-outlined button-align save-btn"></button>
    <button pButton pRipple type="submit" label="Save" (click)="saveTemplate()"
        class="p-button-raised p-button-text button-align" [loading]="saveBtnLoading"></button>
</div>
</div>
<p-divider class="p-divider"></p-divider>
<div class="templateForm" [formGroup]="branchDetails">

<div class="form-salary branchForm-elements">
    <span class="p-input-icon-right p-float-label branchForm-elements">
      <input class="inputtext" style="width: 100%" type="text" pInputText
        inputId="text" formControlName="branchName"
        [ngClass]="{ 'p-filled':  branchDetails.value.branchName, 'invalid': clicked && branchDetails.controls['branchName'].errors}"
        [style]="{ cursor: 'pointer' }" />
      <!-- <em class="pi pi-angle-down dropdown"></em> -->
      <label for="dropdown">Branch Name<span [style]="{ color: '#E24A47' }"> *</span></label>
      <!-- <p-overlayPanel #branchOverlay>
        <ng-template pTemplate>
          <div *ngFor="let field of branchData">
            <p (click)="branchName = field.branchName; branchOverlay.hide()" class="department-options">
              {{ field.branchName }}
            </p>
          </div>
        </ng-template>
      </p-overlayPanel> -->
    </span>
  </div>
  <span class="p-float-label">
    <p-inputMask id="inputtext" mask="9999999999" formControlName="branchContact"
    [ngClass]="{'p-filled': branchDetails.value.branchContact, 'invalid': clicked && branchDetails.controls['branchContact'].errors}">
    </p-inputMask>
    <label for="inputtext">Mobile Number<span [style]="{color: '#E24A47'}"> *</span></label>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="branchEmail" 
    [ngClass]="{'p-filled': branchDetails.value.branchEmail, 'invalid': clicked && branchDetails.controls['branchEmail'].errors}">
    <label for="template-title">Official Email ID <span [style]="{color: '#E24A47'}"> *</span></label>
    <div *ngIf="(clicked && branchDetails.get('branchEmail')?.hasError('pattern'))">
        <span class="error-msg"> Enter a valid Email ID </span>
    </div>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="branchAddress" 
    [ngClass]="{'p-filled': branchDetails.value.branchAddress, 'invalid': clicked && branchDetails.controls['branchAddress'].errors}">
    <label for="template-title">Address Line 1 <span [style]="{color: '#E24A47'}"> *</span></label>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="address2" [ngClass]="{'p-filled': branchDetails.value.address2}">
    <label for="template-title">Address Line 2</label>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="branchArea" [ngClass]="{'p-filled': branchDetails.value.branchArea}">
    <label for="template-title">Area</label>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="branchCity" [ngClass]="{'p-filled': branchDetails.value.branchCity}">
    <label for="template-title">City</label>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="branchState" [ngClass]="{'p-filled': branchDetails.value.branchState}">
    <label for="template-title">State</label>
</span>
<span class="p-float-label templateForm-elements">
    <input class="inputtext" type="text" id="template-title" pInputText formControlName="branchZip" [ngClass]="{'p-filled': branchDetails.value.branchZip}">
    <label for="template-title">Postal Code</label>
</span>
</div>
</p-sidebar>
