import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RoleServicesService {
  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '', 
      schoolid: schoolId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  deleteRole(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/roles/' + id, this.options());
  }

  getSchool(): Observable<any> {
    return this.http.get(this.api_url + '/schools', this.options());
  }

  saveColumns(id: string, payload: Array<any>): Observable<any> {
    return this.http.put<any>(
      this.api_url + '/column/roles/' + id,
      payload,
      this.options()
    );
  }

  deleteColumns(schoolId: string, columnId: string): Observable<any> {
    return this.http.delete(
      this.api_url + '/column/roles/' + schoolId + '/' + columnId,
      this.options()
    );
  }

  getAllRoles(pageNumber?: number, pageSize?: number): Observable<any> {
    return this.http.get(
      this.api_url +
      '/roles?pageNumber=' +
      pageNumber +
      '&pageSize=' +
      pageSize,
      this.options()
    );
  }

  getFilterRoles(
    pageNumber?: number,
    pageSize?: number,
    status?: string
  ): Observable<any> {
    return this.http.get(
      this.api_url +
      '/roles/all/' +
      status +
      '/?pageNumber=' +
      pageNumber +
      '&pageSize=' +
      pageSize,
      this.options()
    );
  }
  addRoles(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/roles', payload, this.options());
  }
  updateRoles(roleId: string, payload: any): Observable<any> {
    return this.http.put(
      this.api_url + '/roles/' + roleId,
      payload,
      this.options()
    );
  }
}