import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { EventServicesService } from 'src/app/services/event-services.service';
// import * as codes from '../../../assets/Json/collegeList.json';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { OtpService } from 'src/app/services/otp.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';


export interface FormModel {
  fieldId?: string,
  fieldOrder: number,
  fieldName: string,
  fieldDescription: string,
  isMandatory: boolean,
  formName: string,
  displaySection: string,
  placeHolder: string,
  fieldType: string,
  answer: Array<any>,
  isValid?: boolean,
  schoolId?: string,
  dependant?: Dependancy,
  fieldDefaultValue?: Array<any>,
  fieldOptions?: Array<FieldOptions>,
  fileProperities?: FieldProperties,
  visible?: boolean,
  disabled?: boolean,
}

export interface Dependancy {
  dependantOn: string,
  dependantValues: Array<string>
}

export interface FieldOptions {
  label: string,
  value: any
}

export interface FieldProperties {
  fileFormat: Array<string>,
  fileSize: any
}
declare global {
  interface Window {
    CRISP_WEBSITE_ID: string;
    $crisp: any;
  }
}
@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
  providers: [MessageService]
})
export class RegistrationFormComponent implements OnInit {

  templateModels: Array<FormModel> = [];
  templateCollections: Array<Array<FormModel>> = [];
  invalidField: any;

  pageNumber: number = 1;

  clicked: boolean = false;
  consentCheck: boolean = false;
  isFormValid: boolean = false;
  submitBtnLoading = false;
  submitted: boolean = false;
  submittedSuccessfully: boolean = false;
  isLoading: boolean = true;
  emailExists: boolean = false;
  mobileExists: boolean = false;
  inValidLocation: boolean = false;
  formExpired: boolean = false;
  formExceeded: boolean = false;
  belowAge: boolean = false;
  existingFormSubmit: boolean = false;
  p1Check: boolean = true;
  duplicateData: any = {};

  formName: string = '';
  candidateId: string = '';
  eventId: any;
  eventTitle: any;
  sourceTag: any;
  userOtpData: any;
  inviteId: string = '';
  consentName: any;
  consentAge: any;
  consentParent: any;
  consentPlace: any;
  consentDetails: any = {};
  agePattern: any = "^(?:[1-9][0-9]?|1[01][0-9]|120)$";

  registrationStartDate: string = '';
  registrationEndDate: string = '';
  collegeList: any;
  // collegeList: any = (codes as any).default;
  CGPAFieldList: Array<string> = [];
  yearsFieldList: Array<string> = [];

  schoolCode: string = '';
  companyLogo: string = '';

  inEditMode: boolean = false;
  ansChange: any = null;
  jobUserMappingId: string = '';
  invalidUrl: boolean = false;

  offerFormSuccess: boolean = false;
  offerFormWarning: boolean = false;
  hideForm: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  
  constructor(
      private formBuilderServices: FormBuilderService, 
      private messageServices: MessageService, 
      private activatedRoute: ActivatedRoute, 
      private eventService: EventServicesService, 
      private globalService: GlobalServicesService,
      private otpService: OtpService
    ) {
      this.CGPAFieldList = [
        "10th CGPA",
        "12th CGPA",
        "Diploma CGPA",
        "Bachelor CGPA",
        "Post Graduate CGPA"
      ]
      this.yearsFieldList = [
        "10th Year of Passing",
        "12th Year of Passing",
        "Diploma Year of Passing",
        "UG Year of Passing",
        "PG Year of Passing"
      ]
  }

  ngOnInit(): void {
    if(window.location.pathname == '/registration-form'){
      window.CRISP_WEBSITE_ID = environment.CRISP_CHAT.CRISP_WEBSITE_ID;
  
      const crispScript = document.createElement('script');
      crispScript.type = 'text/javascript';
      crispScript.async = true;
      crispScript.src = 'https://client.crisp.chat/l.js';
      document.head.appendChild(crispScript);
      
      crispScript.onload = () => {
        window.$crisp.push(["do", "chat:open"]);
      };
    }

    window.addEventListener("beforeunload", function (e) {
      window.$crisp.push(["do", "session:reset"]);
    });

    this.eventService.getCollegeList().subscribe((res:any) => {
      this.collegeList = res.data[0].collegeList ? res.data[0].collegeList : [];
    })

    this.activatedRoute.queryParamMap.subscribe((param) => {
      this.formName = param.get('form') || '';
      this.eventId = param.get('eventId') || null;
      this.eventTitle = param.get('eventTitle') || null;
      this.sourceTag = param.get('sourceTag') || null;
      this.jobUserMappingId = param.get('id') || '';
      this.candidateId = param.get('candidateId') || '';
      this.inviteId = param.get('inviteId') || '';
      if(this.formName === 'Offer Acceptance and Joining Confirmation Form')
      {
        this.hideForm = true;
        this.inEditMode = true;
      }
    })
    this.globalService.schoolData.subscribe(() => {
      this.schoolCode = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_code
      : '';
      this.companyLogo = localStorage.getItem('schoolDetails')
      ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0]?.company?.companyLogo
      : '';
      if(this.formName == 'Extended Details and Documentation Form'){
        this.otpRequired = true;
        this.otpVerified = false;
      }
      if(!this.otpRequired)
        this.eventId ? this.validateDate() : this.getTemplates();
    })
  }

  validateDate() {
    this.eventService.getEventById(this.eventId).subscribe((res) => {
      this.registrationStartDate = res.data?.registrationStartDate?.toString();
      this.registrationEndDate = res.data?.registrationEndDate?.toString();
      this.getTemplates();
    })

  }

  validateRegistration(): boolean {
    if (this.registrationStartDate && this.registrationStartDate.length && this.registrationEndDate && this.registrationEndDate.length) {
      return true;
    }
    else {
      return false;
    }
  }

  bindValuesInTemplate(data: any)
  {
    let templateModel: any = [];
    data.forEach((data: any) => {
      if (data.formDetails[0].formName == this.formName) {
        templateModel = data.formDetails;
      }
    });
    return templateModel; 
  }

  getTemplates = () => {
    this.isLoading = true;
    let _templateModels: Array<any> = [];
    this.formBuilderServices.getTemplate().subscribe(res => {
      let currentDate = res.data.date?.toString();
      let data = res.data.formDetails;
      if (this.validateRegistration()) {
        if (currentDate >= this.registrationStartDate && currentDate <= this.registrationEndDate) {
          _templateModels = this.bindValuesInTemplate(data)
        }
        else {
          (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? this.formExpired = true : _templateModels = this.bindValuesInTemplate(data);          
        }
      }
      else {
        _templateModels = this.bindValuesInTemplate(data)
      }

      // To Update the College List
      // _templateModels.forEach((eachField: any)=>{
      //   if(eachField.fieldName === 'Bachelor College' || eachField.fieldName === 'Post Graduate College'){
      //     eachField.fieldOptions = this.collegeList;
      //   }
      // })

      this.templateModels = _templateModels.map((model: FormModel) => ({ ...model, answer: [], isValid: model.isMandatory ? false : true, visible: model.dependant ? false : true }));


      let sections: Array<string> = [];
      let _displaySection = '';
      if(this.formName === 'Offer Acceptance and Joining Confirmation Form')
      {
        this.otpService.getJobUserById(this.jobUserMappingId).subscribe((res:any) => {
          this.eventId = res.data?.jobId ? res.data?.jobId : res.data?.eventJobId; 
        })
        this.eventService.getUserDetailsByJobUserId(this.jobUserMappingId).subscribe((res: any)=>{
          this.hideForm = false;
          if(res.status === 200)
          {
            let userData: any = res?.data[0];
            let userDataObj: any = {
              'Candidate ID' : userData.id,
              'Name' : userData.name,
              'Email' : userData.email,
              'Mobile' : '+'+userData.countryCode+' '+userData.mobile_number
            }
            this.candidateId = userData.id,
            this.otpRequired = true;
            this.otpVerified = false;
            
            this.templateModels.forEach((eachModel: FormModel) => {
              for(const data in userDataObj)
              {
                if (eachModel.fieldName === data) {
                  eachModel.answer = [userDataObj[data]];
                  this.ansChange = !this.ansChange;
                  eachModel.isValid = true;
                }
              }
            })
              _templateModels.forEach((model: FormModel) => {
                if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                  _displaySection = model.displaySection;
                  sections.push(_displaySection);
                }
              })
              sections.forEach((eachSection: string) => {
                this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
                if(this.inEditMode)
                {
                  this.ansChange = !this.ansChange;
                }
              })
              this.isLoading = false;
          }
          else
          {
            this.isLoading = false;
            this.offerFormWarning = true;
            let errorMessage = res?.error?.message
            if(errorMessage)
            {
              if(errorMessage === 'Invalid Url')
              {
                this.invalidUrl = true;
              }
            }
          }
        })
      }
      else if(this.inviteId && (this.formName === 'Registration Form' || this.formName === 'Registration Form Non-Technical')){
        if(this.inviteId){
          this.inEditMode = true;
            this.formBuilderServices.getInviteData(this.inviteId).subscribe((res: any)=>{
    
            let inviteData = res?.data[0];
            let inviteDataObj: any = {
              'Candidate Name' : inviteData.firstName+' '+inviteData.lastName,
              'Primary Email ID' : inviteData.emailId,
            }
    
            this.templateModels.forEach((eachModel: FormModel) => {
              for(const data in inviteDataObj)
              {
                if (eachModel.fieldName === data) {
                  if(data == 'Primary mobile number'){
                    eachModel.answer = ['91', inviteDataObj[data]]
                  } else {
                    eachModel.answer = [inviteDataObj[data]];
                  }
                  this.ansChange = !this.ansChange;
                  eachModel.isValid = true;
                  eachModel.disabled = true;
                }
              }
            })
    
            _templateModels.forEach((model: FormModel) => {
              if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                _displaySection = model.displaySection;
                sections.push(_displaySection);
              }
            })
            sections.forEach((eachSection: string) => {
              this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
              if(this.inEditMode)
              {
                this.ansChange = !this.ansChange;
              }
            })
            this.isLoading = false;
          })
          }
        } 
      else
      {
        if(this.formName === 'Extended Details and Documentation Form')
        {
          this.inEditMode = true;
          this.isLoading = true;
          this.isLoading = true;
          this.formBuilderServices.getCandidateFormDetails(this.candidateId, this.formName).subscribe((res: any)=>{
            let userDataObj = res.data;
            if(userDataObj.length === 0)
            {
              userDataObj.push({
                fieldName : 'Candidate ID',
                answer: [
                  this.candidateId
              ]
              })
            }
            this.templateModels.forEach((eachModel: FormModel) => {
              for(const data of userDataObj)
              {
                if (eachModel.fieldName === data.fieldName) {
                  eachModel.answer = data.answer;
                  this.ansChange = !this.ansChange;
                  eachModel.isValid = true;
                }
              }
            })
            _templateModels.forEach((model: FormModel) => {
              if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
                _displaySection = model.displaySection;
                sections.push(_displaySection);
              }
            })
            sections.forEach((eachSection: string) => {
              this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
              if(this.inEditMode)
              {
                this.ansChange = !this.ansChange;
              }
            })
            this.isLoading = false;
          })
        }
        else
        {
          _templateModels.forEach((model: FormModel) => {
            if (_displaySection !== model.displaySection && !sections.includes(model.displaySection)) {
              _displaySection = model.displaySection;
              sections.push(_displaySection);
            }
          })
          sections.forEach((eachSection: string) => {
            this.templateCollections.push(this.templateModels.filter((model: FormModel) => model.displaySection == eachSection));
          });
          this.isLoading = false;
        }
      }
    })
  }

  bindData(event: any, model: FormModel) {
    model.answer = [];
    switch (model.fieldType) {
      case 'checkbox': model.answer = event; break;
      case 'multiinput': model.answer.push(model.fieldOptions?.map((option: FieldOptions) => option.value).join(' ')); break;
      case 'mobile':{
        model.answer = event;
        if(model.fieldName == 'Alternate mobile number'){
          this.templateModels.forEach((eachModel: FormModel) => {
            if (eachModel.fieldName == 'Primary mobile number') {
              if( eachModel.answer[1] && eachModel.answer[1] == event[1]){
                this.duplicateData.mobileDuplicate = true;
              } else {
                this.duplicateData.mobileDuplicate = false;
              }
            }
          })
        } else if(model.fieldName == 'Primary mobile number') {
          this.templateModels.forEach((eachModel: FormModel) => {
            if (eachModel.fieldName == 'Alternate mobile number') {
              if( eachModel.answer[1] && eachModel.answer[1] == event[1]){
                this.duplicateData.mobileDuplicate = true;
              } else {
                this.duplicateData.mobileDuplicate = false;
              }
            }
          })
        }
      } break;
      case 'dropdown': {
        if (event != null && event != undefined) {
          model.answer.push(event)
        }
      } break;
      case 'input': {
        if (model.fieldName == 'Aadhar Card Number:') {
          model.answer.push(Number(event))
        } else {
          model.answer.push(event)
        }
      } break;
      case 'email': {
        model.answer.push(event);
        if(model.fieldName == 'Alternate Email ID'){
          this.templateModels.forEach((eachModel: FormModel) => {
            if (eachModel.fieldName == 'Primary Email ID') {
              if(eachModel.answer[0] == event){
                model.isValid = false;
                this.duplicateData.emailDuplicate = true;
              } else {
                this.duplicateData.emailDuplicate = false;
              }
            }
          })
        }
      } break;
      default: model.answer.push(event);
    }
  }

  varifySubmit(){
    if (this.validateRegistration()) {
      let currentDate = moment().toISOString();
      let regStartDate = moment(this.registrationStartDate).toISOString();
      let regEndDate = moment(this.registrationEndDate).toISOString();
      if (currentDate >= regStartDate && currentDate <= regEndDate) {
        this.submit();
      }
      else {
        (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? this.formExceeded = true : this.submit();       
      }
    }
    else {
      this.submit();
    }
  }

  submit() {
    this.clicked = true;
    let templateModels = this.templateCollections.flat(1).filter((eachModel: FormModel) => eachModel.visible);
    this.isFormValid = !templateModels.map((model: FormModel) => model.isValid).includes(false);
    if((this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') && !this.consentCheck){
      if(this.consentName && this.consentAge && this.consentParent && this.consentPlace){
        this.consentDetails = {
          answer : [ 'I, the undersigned, Mr/Ms '+this.consentName+ ', S/o, D/o, W/o '+this.consentParent+', aged '+this.consentAge+', residing at '+this.consentPlace+' hereby give my consent to Societe Generale Global Solution Centre Pvt Ltd to collect and process my personal data, i.e.,  Aadhaar, PAN, Email ID  for the purpose of verification.'],
          displaySection: "Documents",
          fieldDescription: "",
          fieldName: "Consent Data",
          fieldOrder: 3,
          fieldType: "fillUps",
          formName: "Registration Form",
          isMandatory: true,
          visible: true,
        }
        this.consentCheck = true;
        this.p1Check = true;
      }
      else{
        this.consentCheck = false;
        this.p1Check = false;
        this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Please fill the consent field!' });
      }
    }
    if(this.p1Check){
      if (this.isFormValid) {
        this.submitBtnLoading = true;
        let payload = templateModels;
        this.duplicateData = {};
        let conditionOption: any = {};
        this.templateModels.forEach((each)=>{
          if(each.fieldName === 'Alternate Email Address')
          {
            conditionOption['email'] = each.answer[0];
          }
          else if(each.fieldName === 'Alternate Mobile Number')
          {
            conditionOption['countryCode'] = each.answer[0];
            conditionOption['mobile_number'] = each.answer[1]
          }
        })
        this.sourceTag = this.sourceTag ?? ( this.eventTitle ?? '-');
        if(this.formName === "Offer Acceptance and Joining Confirmation Form")
        {
          let payload = templateModels;
          this.formBuilderServices.saveOffer(payload, this.jobUserMappingId).subscribe((res: any)=>{
            this.submitBtnLoading = false;
            if (res.status == 200) {
              this.offerFormSuccess = true;
              this.clicked = false;
            }
            else if(res.status == 500) {
              this.offerFormWarning= true;
              let errorMessage = res?.error?.message
              if(errorMessage)
              {
                if(errorMessage === 'Invalid Url')
                {
                  this.invalidUrl = true;
                }
              }
            }
          })
        }
        else
        {
          if((this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') && !this.inEditMode && this.consentCheck){
            payload.push(this.consentDetails);
          }

            this.formBuilderServices.saveUser(payload, (this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical') ? 'P1' : this.formName == 'Extended Details and Documentation Form' ? 'P2' : 'offerForm', this.eventId, this.eventTitle, conditionOption, this.sourceTag).subscribe(res => {
              this.submitBtnLoading = false;
              if (res.status == 200) {
                this.submittedSuccessfully = true;
                this.clicked = false;
              } else if (res.status == 500) {
                let errorData: any = {}
                if(res?.error?.includes('noUserFound'))
                {
                  errorData['userNotFound'] = true
                }
                if(res?.error?.includes('duplicateMobileNumber'))
                {
                  errorData['mobileDuplicate'] = true
                }
                if(res?.error?.includes('duplicateEmail'))
                {
                  errorData['emailDuplicate'] = true
                }
                if(res?.error == 'You Have Already Submitted the Form')
                {
                  this.existingFormSubmit = true;
                }
                if(res?.error == 'P2 form already submitted for user')
                {
                  this.existingFormSubmit = true;
                }
                this.duplicateData = {...errorData};
                if(Object.keys(this.duplicateData).length === 0)
                {
                  if(res?.error === "Mobile Number Already Exists")
                  {
                    this.mobileExists = true;
                  }
                  if(res?.error === "Email Already Exists")
                  {
                    this.emailExists = true;
                  }
                  if(res?.error === "Email and Mobile Number Already Exists")
                  {
                    this.mobileExists = true;
                    this.emailExists = true;
                  }
                }
                else{
                  this.invalidField = {...this.templateModels[0]};
                }
              }
              else {
                this.messageServices.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!' });
              }
            })
        }
      } else {
        let invalidFields = templateModels.filter((model: FormModel) => !model.isValid);
        this.invalidField = invalidFields[0];
      }
    }

  }

  checkValid(event: any, model: FormModel) {
    model.isValid = event;

    // if (model.fieldName == "Preferred Location 2") {
    //   let templateModels = this.templateCollections.flat(1);
    //   let matchingField: any;
    //   templateModels.forEach((each: FormModel) => { if (each.fieldName == 'Preferred Location 1') matchingField = each })

    //   if (matchingField.answer[0] == model.answer) {
    //     model.isValid = false;
    //     this.inValidLocation = true;
    //   } else {
    //     model.isValid = true
    //     this.inValidLocation = false;
    //   };
    // }

    if(model.fieldName == "Primary mobile number" || model.fieldName == "Alternate mobile number"){
      this.templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName == 'Alternate mobile number') {
          if(this.duplicateData.mobileDuplicate){
            eachModel.isValid = false;
          } else {
            eachModel.isValid = true;
          }
        }
      })
    }

    if(model.fieldName == "Primary Email ID" || model.fieldName == "Alternate Email ID"){
      this.templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName == 'Alternate Email ID') {
          if(this.duplicateData.emailDuplicate){
            eachModel.isValid = false;
          } else {
            eachModel.isValid = true;
          }
        }
      })
    }


    if (model.fieldName == "Date Of Birth") {
      let year = new Date().getFullYear();
      let dif = year - Number(model.answer[0].split('-')[2]);

      if (dif < 18) {
        model.isValid = false;
        this.belowAge = true;
      } else {
        model.isValid = true;
        this.belowAge = false;
      }
    }
  }

  closeWindow(action: string) {
    if (action === 'success') {
      window.close();
    }
    else {
      this.emailExists = false;
      this.mobileExists = false;
    }
  }

  checkForDependancy = (model: FormModel): boolean => {
    if (model.dependant) {
      let templateModels = this.templateCollections.flat(1);
      let dependantField: any;

      templateModels.forEach((eachModel: FormModel) => {
        if (eachModel.fieldName === model.dependant?.dependantOn) dependantField = eachModel;
      })

      if (model.dependant.dependantValues.includes(dependantField.answer[0])) {
        model.visible = true;
        this.invalidField = {};
        return true;
      }
      else {
        model.visible = false;
        model.answer = [];
        model.isValid = model.isMandatory ? false : true;
        return false;
      }
    } else return true;
  }

  prefillForm()
  {
    if(this.otpVerified && this.formName === 'Extended Details and Documentation Form')
    {
      this.getTemplates();
    }

  }
}
