<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="pageInfo">
    <div class="settings-header">
        <div class="setting-content_center">
            <em class="pi pi-user"></em>
            <p class="settings-header_name">Status</p>
        </div>
        <em class="pi pi-pencil" (click)="openStatusSettings()"></em>
    </div>
</div>
<div class="info-card">
    <div [style]="{display: 'block', flexWrap: 'wrap'}">
        <div *ngFor="let data of stageTypes; let i = index">
            <div class="info-element">
                <span class="info-line"></span>
                <div [style]="{display: 'flex', flexDirection: 'column'} ">
                    <p [style]="{color: 'var(--fc-light-dark)'}">Stage {{i+1}}</p>
                    <p [style]="{color: 'var(--fc-dark)', width: 'fit-content'}">{{data}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="settingsSlider">
    <p-sidebar [(visible)]="settingsSlider" styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right"
        (onHide)="resetForm();">
        <div class="status-header">
            <div class="status-heading">Edit Status</div>
            <div class="header-btn">
                <button pButton pRipple type="submit" label="Save" class="p-button-raised p-button-text button-align"
                    (click)="saveStatus()" [loading]="saveBtnLoading"></button>
            </div>
        </div>
        <p-divider class="p-divider"></p-divider>
    
        <div style="margin: 2.25rem 10px 10px 5vw;">
            <span class="p-float-label editStatus-elements">
                <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" [(ngModel)]="selectedStage" [options]="stageTypes"
                    (onChange)="stageChange($event.value)" #stageTypeField="ngModel" [required]="true">
                </p-dropdown>
                <label for="dropdown">Select Stage <span class="imp">*</span></label>
                <span class="error-msg" *ngIf="clicked && !stageTypeField.valid">This field is required.</span>
            </span>
    
            <span class="p-float-label editStatus-elements">
                <p-dropdown Id="dropdown" [autoDisplayFirst]="false" [(ngModel)]="selectedStatus" [options]="defaultStatusOptons" optionValue="value"
                    emptyMessage="Select a Stage" #statusField="ngModel" [required]="true" (ngModelChange)="statusChange($event)">
                </p-dropdown>
                <label for="inputtext">Select Status <span [style]="{color: '#E24A47'}">*</span></label>
                <span class="error-msg" *ngIf="clicked && !statusField.valid">This field is required.</span>
            </span>
    
            <div class="rename-status">
                <span class="p-float-label editStatus-elements">
                    <input type="text" id="inputtext" pInputText [(ngModel)]="selectedStatusLabel" [readOnly]="true">
                    <label for="inputtext">Selected </label>
                </span>
                <i class="pi pi-arrow-right" style="width: 3vw; display: inline-flex; justify-content: center;"></i>
                <span class="p-float-label editStatus-elements">
                    <input type="text" id="inputtext" pInputText [(ngModel)]="renamedStatus" #renameField="ngModel"
                        [required]="true" autocomplete="false" [pattern]="alphaNumeric">
                    <label for="inputtext">Rename <span [style]="{color: '#E24A47'}">*</span></label>
                    <span class="error-msg" *ngIf="clicked && !renameField.valid && !renamedStatus.length">This field is required.</span>
                    <span class="error-msg" *ngIf="clicked && !renameField.valid && renamedStatus.length">Special characters not allowed</span>
                </span>
            </div>
        </div>
        <div class="userActivity-content">
            <div class="statusActivity">
                <div class="activities" *ngFor="let date of timelyActivity">
                    <p-divider align="left">
                        <div class="activityDate">{{date[0].actionDate | date:'d MMMM, y'}}</div>
                    </p-divider>
                    <div *ngFor="let activity of date">
    
                        <div class="activity-item">
                            <div class="user-profile">
                                {{activity?.badge}}
                            </div>
                            <div class="activity-content">
                                <h3 class="user-name">
                                    {{ activity?.userName }}
                                    <span class="time">{{activity.actionDate | date:'dd/MM/YYYY, hh:mm:ss a'}}</span>
                                </h3>
                                <div class="activity-details">
                                    <div class="action-details">
                                        <div class="action-from-to">
                                            <span class="fromStage">{{activity?.previousLabel}}</span>
                                            <img src="../../../assets/icons-svg/arrow-right-alt.svg" alt="Arrow-right">
                                            <span class="toStage">{{activity?.renamedLabel}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-sidebar>
</div>
<p-toast></p-toast>