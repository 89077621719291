import { Component, OnInit, Input } from '@angular/core';
// import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-handle-error',
  templateUrl: './handle-error.component.html',
  styleUrls: ['./handle-error.component.css']
})
export class HandleErrorComponent implements OnInit {

  @Input() control: any;
  @Input() name: string = '';
  @Input() clicked: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
