import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class OtpService {
    api_url = environment.HOST.API_URL;
    host_api_url = environment.HOST.HOST_API_URL;
    formbuilder_api_url = environment.HOST.FORMBUILDER_API_URL;

    options() {
        const schoolId = localStorage.getItem('schoolData')
            ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
            : '';
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Origin: window.location.origin,
            schoolid: schoolId,
        });
        return { headers };
    }

    constructor(private http: HttpClient) { }

    sendOtp(candidateId:string, eventId:string,payload?:any):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/' + candidateId + '/' + eventId, payload ?? {}, this.options());
    }
    
    verifyOtp(email: string, payload:any):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/' + email, payload, this.options());  
    }

    getUserById(id:string):Observable<any>{
        return this.http.get(this.formbuilder_api_url + '/formBuilder/getUserById/' + id, this.options());
    }

    getUserByEmail(email:string):Observable<any>{
        return this.http.get(this.api_url + '/users/candidate/email/' + email, this.options());
    }

    getJobUserById(id:string):Observable<any>{
        return this.http.get(this.formbuilder_api_url + '/formBuilder/getJobUserById/' + id, this.options());
    }

    ssoTestlink(payload:any):Observable<any>{
        return this.http.post(this.api_url + '/test/ssoLogin', payload, this.options());
    }

    sendOtpByEmail(email:string, eventId: any):Observable<any>{
        return this.http.post(this.api_url + '/verify-otp/otp/' + email + '/' + eventId, {}, this.options());
    }
}