import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from '../services/login.service';
import { GlobalServicesService } from '../services/global-services.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private route: Router, private globalService: GlobalServicesService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // let userDetails:any = JSON.parse(localStorage.getItem('userDetails') ?? '')
    const userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    if (!(userDetails.email)) {
      this.removeKeys();
      this.route.navigateByUrl('/login');
      return false;
    }
    this.loginService.isLoggedIn(userDetails.email).subscribe(
      (res: any) => {
        if (res.status === 200) {
          return true;
        }
        else {
          this.loginService.validToken.next(
            {
              status: res.status,
              message: {
                severity: 'error',
                summary: 'Session Expired!',
              }
            }
          )
          this.removeKeys();
          setTimeout(() => {
            this.route.navigateByUrl('/login');
          }, 4000)
          return false;
        }
      }
    );
    return true;
  }
  removeKeys() {
    for (var attr in localStorage) {
      if (attr != 'schoolData' && attr != 'schoolDetails')
        localStorage.removeItem(attr);
    }
  }
}
