<div *ngIf="loadingSpinner" class="loadingOverlayRole">
  <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>
<div [ngStyle]="{'visibility':showEditor ? 'visible' : 'hidden'}">
  <div class="roles-add-header">
    <div class="roles-heading">{{ roleHeader }}</div>
    <div class="header-btn">
      <button pButton pRipple type="submit" (click)="submitItem()" [loading]="saveBtnLoading" label="{{ saveStatus }}"
        class="p-button-raised p-button-text button-align"></button>
    </div>
  </div>
  <p-divider class="p-divider"></p-divider>
  <form class="jobForm" [formGroup]="orderForm" (ngSubmit)="onSave()">
    <span class="p-float-label jobForm-elements">
      <input style="width: 100%" type="text" id="inputtext"
        pInputText formControlName="roleName"
        [ngClass]="{'invalid': clicked && orderForm.controls['roleName'].errors}" />
      <label for="inputtext">Role Name <span [style]="{ color: '#E24A47' }">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="orderForm.get('roleName')" name="Role name"></app-handle-error>
    </span>
    <span class="p-float-label jobForm-elements">
      <input [ngClass]="{ 'p-filled': orderForm.value.requirement }" style="width: 100%" type="text" id="inputtext"
        pInputText formControlName="requirement" />
      <label for="inputtext">Requirement</label>
    </span>
    <div class="form-salary jobForm-elements">
      <span class="p-input-icon-right p-float-label jobForm-elements">
        <input #roleTypeDiv readonly style="width: 100%" type="text" pInputText
          inputId="dropdown" formControlName="roleType" [(ngModel)]="selectedRoleType"
          (click)="roleTypeOverlay.toggle($event)" [style]="{ cursor: 'pointer' }"
          [ngClass]="{'invalid': clicked && orderForm.controls['roleType'].errors}" />
        <em class="pi pi-angle-down dropdown" (click)="viewRole()"></em>
        <label for="dropdown">Role Type <span [style]="{ color: '#E24A47' }">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="orderForm.get('roleType')" name="Role type"></app-handle-error>
        <p-overlayPanel #roleTypeOverlay>
          <ng-template pTemplate>
            <div *ngFor="let field of roleTypeList">
              <p (click)="selectedRoleType = field.label; roleTypeOverlay.hide()" class="department-options">
                {{ field.label }}
              </p>
            </div>
          </ng-template>
        </p-overlayPanel>
      </span>
      <div class="field-checkbox">
        <p-checkbox [binary]="true" inputId="binary" label="Mark as Remote Job Role" formControlName="remoteJob">
        </p-checkbox>
      </div>
    </div>
    <textarea class="p-editor-container jobForm-jobDescription" [style]="{ height: '240px' }" id="editor"
      formControlName="roleDescription" placeholder="Role Description"></textarea>
    <div class="profilePhoto form-details">
      <p class="heading">Role Description / Resource Attach</p>
      <div class="fileUpload-dropzone" ngx-dropzone [accept]="'image/JPEG, application/pdf'" [multiple]="false"
        (change)="onSelectMedia($event)">
        <ngx-dropzone-label *ngIf="!uploadingFile">
          <div class="dropzone-label">
            <em class="pi pi-cloud-upload"></em>
            <p class="upload-text">
              Click to
              <span [style]="{ color: 'var(--fc-primary)' }">upload</span> or Drag
              & Drop
            </p>
            <p class="format-text">PDF, JPEG Formats only</p>
          </div>
        </ngx-dropzone-label>
        <div *ngIf="uploadingFile">
          <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
          </p-progressSpinner>
        </div>
        <div *ngIf="mediaBase64?.length">
          <ngx-dropzone-preview class="resume-preview" ngProjectAs="ngx-dropzone-preview" *ngFor="let f of mediaFile"
            [file]="f" [removable]="true" (removed)="onRemoveMedia(f)">
            <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
        </div>
      </div>

    </div>
    <div>
      <div formArrayName="locationInformation" *ngFor="let item of formData.controls; let i = index">
        <p-divider *ngIf="i" class="p-divider"></p-divider>
        <div [formGroupName]="i">
          <div style="display: flex; justify-content: space-between; width: 25vw">
            <p class="heading">Location {{ i + 1 }}</p>
            <em *ngIf="formData.controls.length > 1" class="pi pi-trash cursor-pointer" (click)="removeItem(i)"></em>
          </div>
          <div class="form-salary jobForm-elements">
            <span class="p-input-icon-right p-float-label jobForm-elements">
              <input #locationDiv readonly [ngClass]="{
                  'p-filled': orderForm.value.locationInformation.at(i).location,
                  'invalid': clicked && locationInformation.controls[i].get('location')?.errors
                }" style="width: 100%" type="text" id="drop-input" inputId="dropdown" formControlName="location"
                (click)="locationOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
              <em (click)="viewLocation()" class="pi pi-angle-down dropdown"></em>
              <label for="dropdown">Location <span [style]="{ color: '#E24A47' }">*</span></label>
              <app-handle-error [clicked]="clicked" [control]="locationInformation.controls[i].get('location')"
                name="Location"></app-handle-error>
              <p-overlayPanel #locationOverlay>
                <ng-template pTemplate>
                  <div class="overlay-container" style="overflow-y: scroll; height: 200px;">
                    <div *ngFor="let field of locationList">
                      <p (click)="
                          locationOverlay.hide();
                          updateLocationDetails('location', i, field)
                        " class="department-options">
                        {{ field }}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
          </div>
          <div class="form-salary jobForm-elements">
            <span class="p-input-icon-right p-float-label jobForm-elements">
              <input #hiringManagerDiv readonly [ngClass]="{
                  'p-filled':
                    orderForm.value.locationInformation.at(i).hiringManager,
                    'invalid': clicked && locationInformation.controls[i].get('hiringManager')?.errors
                }" style="width: 100%;" type="text" inputId="dropdown" id="drop-input" formControlName="hiringManager"
                (click)="hiringManagerOverlay.toggle($event)" [style]="{ cursor: 'pointer' }" />
              <em (click)='viewManager()' class="pi pi-angle-down dropdown"></em>
              <label for="dropdown">Hiring Manager
                <span [style]="{ color: '#E24A47' }">*</span></label>
              <app-handle-error [clicked]="clicked" [control]="locationInformation.controls[i].get('hiringManager')"
                name="Hiring manager"></app-handle-error>
              <p-overlayPanel #hiringManagerOverlay>
                <ng-template pTemplate>
                  <div class="overlay-container" style="overflow-y: scroll; height: 200px;">
                    <div *ngFor="let field of hiringManager">
                      <p (click)="
                          hiringManagerOverlay.hide();
                          updateHiringManagerDetails(['hiringManager', 'hiringManagerId'], i, field)
                        " class="department-options">
                        {{ field.name }}
                      </p>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </span>
          </div>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled': orderForm.value.locationInformation.at(i).businessUnit
              }" style="width: 100%" type="text" id="inputtext" pInputText formControlName="businessUnit" />
            <label for="inputtext">Business Unit</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled': orderForm.value.locationInformation.at(i).openings
              }" style="width: 100%" type="text" id="inputtext" pInputText formControlName="openings" />
            <label for="inputtext">Openings</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).maximumApplicants
              }" style="width: 100%" type="text" id="inputtext" pInputText formControlName="maximumApplicants" />
            <label for="inputtext">Maximum Applicants</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).maleDiversity
              }" style="width: 100%" type="text" id="inputtext" pInputText formControlName="maleDiversity" />
            <label for="inputtext">Diversity (Male)</label>
          </span>
          <span class="p-float-label jobForm-elements">
            <input [ngClass]="{
                'p-filled':
                  orderForm.value.locationInformation.at(i).femaleDiversity
              }" maleDiversity style="width: 100%" type="text" id="inputtext" pInputText
              formControlName="femaleDiversity" />
            <label for="inputtext">Diversity (Female)</label>
          </span>
        </div>
      </div>
      <p (click)="addItem()" class="add-heading cursor-pointer">
        <em class="pi pi-plus"></em>Add
        <span *ngIf="formData.controls.length">another</span> Location
      </p>
    </div>
  </form>
  <p-toast></p-toast>
</div>