<div class="job-add-header">
  <div class="job-heading">Create New Job</div>
  <div class="header-btn">
    <!-- <button pButton pRipple type="submit" label="Save" [loading]="saveBtnLoading"
      class=" {{jobDetails ? 'p-button-raised' : 'p-button-outlined'}} button-align save-btn"
      (click)="jobDetails ? updateJob(addJobForm) : saveJob(addJobForm)"></button> -->
    <button form="ngform" *ngIf="!jobDetails" pButton pRipple type="submit" label="Publish"
      [loading]="publishBtnLoading" class="p-button-raised p-button-text button-align" (click)="publishJob()" cLoadingButton></button>
  </div>
</div>
<p-divider class="p-divider"></p-divider>
<form class="jobForm" id="ngform" [formGroup]="addJobForm" #form="ngForm">
  <!-- <div *ngIf="clicked && addJobForm.invalid" class="default-error">
    Enter all the Fields *
  </div> -->
  <span class="p-float-label jobForm-elements">
    <input type="text" id="inputtext" pInputText formControlName="jobName"
      [ngClass]="{'invalid': clicked && addJobForm.controls['jobName'].errors}">
    <label for="inputtext" id='jt'>Job Title <span [style]="{color: '#E24A47'}">*</span></label>
    <app-handle-error [clicked]="clicked" [control]="addJobForm.get('jobName')" name="Job Title"></app-handle-error>
  </span>

  <span class="p-input-icon-right p-float-label jobForm-elements">
    <input type="text" pInputText inputId="dropdown" [(ngModel)]="selectedJobType" formControlName="jobType"
      (click)="jobTypeOverlay.toggle($event)" [style]="{cursor: 'pointer'} "
      [ngClass]="{'invalid': clicked && addJobForm.controls['jobType'].errors}" />
    <em class="pi pi-angle-down dropdown"></em>
    <label id="job-type" class="jobtype" [ngClass]="{'errorLabel': addJobForm.controls['jobType'].dirty}"
      for="dropdown">Job Type <span [style]="{color: '#E24A47'}">*</span></label>
    <app-handle-error [clicked]="clicked" [control]="addJobForm.get('jobType')" name="Job Type"></app-handle-error>
    <p-overlayPanel #jobTypeOverlay>
      <ng-template pTemplate>
        <div *ngFor="let field of jobType">
          <p (click)="selectedJobType = field.name; jobTypeOverlay.hide()" class="department-options">
            {{field.name}}</p>
        </div>
      </ng-template>
    </p-overlayPanel>
  </span>

  <span class="p-input-icon-right p-float-label jobForm-elements">
    <input type="text" pInputText inputId="dropdown" [(ngModel)]="selectedDepartment" formControlName="department"
      (click)="departmentOverlay.toggle($event)" [style]="{cursor: 'pointer'} "
      [ngClass]="{'invalid': clicked && addJobForm.controls['department'].errors}" />
    <em class="pi pi-angle-down dropdown"></em>
    <label for="dropdown">Department</label>
    <p-overlayPanel #departmentOverlay>
      <ng-template pTemplate>
        <div *ngFor="let field of department">
          <p (click)="selectedDepartment = field.code; departmentOverlay.hide()" class="department-options">
            {{field.name}}</p>
        </div>
        <p *ngIf="!showAddDepartment" class="department-options" [style]="{color: '#F58020'}"
          (click)="showAddDepartment = true">Add New</p>
        <p *ngIf="showAddDepartment">
          <span class="p-inputgroup addDepartment">
            <input type="text" pInputText placeholder="Department Name" [(ngModel)]="newDepartmentName" id="deptName">
            <button type="button" pButton pRipple icon="pi pi-check" styleClass="p-button-warn"
              (click)="addDepartment('deptName')" [loading]="deptBtnLoading"></button>
          </span>
        </p>
      </ng-template>
    </p-overlayPanel>
  </span>

  <span class="p-float-label jobForm-elements">
    <input type="text" id="inputtext" pInputText formControlName="location"
      [ngClass]="{'invalid': clicked && addJobForm.controls['location'].errors}">
    <label for="inputtext">Location <span [style]="{color: '#E24A47'}">*</span></label>
    <app-handle-error [clicked]="clicked" [control]="addJobForm.get('location')" name="Location"></app-handle-error>
  </span>

  <div class="p-inputgroup jobForm-elements">
    <span class="p-float-label">
      <p-inputNumber id="inputgroup" class="addon-input" formControlName="workExperience"></p-inputNumber>
      <label for="inputgroup">Work Experience</label>
    </span>
    <span class="p-inputgroup-addon addon-span">Years</span>
  </div>

  <div class="p-inputgroup jobForm-elements" *ngIf="addJobForm.value.salaryRange">
    <span class="p-inputgroup-addon addon-span rsSign"
      [ngClass]="{'invalid': clicked && addJobForm.controls['salary'].errors}">₹</span>
    <span class="p-float-label">
      <p-inputNumber id="minsalary" class="addon-input salary" formControlName="minSalary" 
      [ngClass]="{'ng-dirty ng-touched ng-invalid': addJobForm.errors?.['minSalary'] && addJobForm.get('minSalary')?.dirty && addJobForm.get('minSalary')?.touched,
      'invalid': clicked && addJobForm.controls['minSalary'].errors}" (onInput)="changed($event)"></p-inputNumber>
      <label for="inputgroup">Min Salary <span [style]="{color: '#E24A47'}">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="addJobForm.get('minSalary')" name="Minimum salary">
      </app-handle-error>
    </span>
  </div>


  <div class="form-salary jobForm-elements">
    <div class="p-inputgroup" *ngIf="!addJobForm.value.salaryRange">
      <span class="p-inputgroup-addon addon-span rsSign"
        [ngClass]="{'invalid': clicked && addJobForm.controls['salary'].errors}">₹</span>
      <span class="p-float-label">
        <p-inputNumber class="addon-input salary" formControlName="salary" [ngClass]="{'ng-dirty ng-touched ng-invalid': addJobForm.errors?.['salaryRequired'], 
        'invalid': clicked && addJobForm.controls['salary'].errors}"></p-inputNumber>
        <label for="inputgroup">Salary <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addJobForm.get('salary')" name="Salary"></app-handle-error>
      </span>
    </div>
    <div class="p-inputgroup jobForm-elements" *ngIf="addJobForm.value.salaryRange">
      <span class="p-inputgroup-addon addon-span rsSign"
        [ngClass]="{'invalid': clicked && addJobForm.controls['salary'].errors}">₹</span>
      <span class="p-float-label">
        <p-inputNumber class="addon-input salary"
          formControlName="maxSalary" [ngClass]="{'ng-dirty ng-touched ng-invalid': addJobForm.errors?.['maxSalary'] && addJobForm.get('maxSalary')?.dirty && addJobForm.get('maxSalary')?.touched,
        'invalid': clicked && addJobForm.controls['maxSalary'].errors}"></p-inputNumber>
        <label for="inputgroup">Max Salary <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error [clicked]="clicked" [control]="addJobForm.get('minSalary')" name="Maximum salary">
        </app-handle-error>
      </span>
    </div>
    <div class="field-checkbox">
      <p-checkbox [binary]="true" inputId="binary" formControlName="salaryRange" (onChange)="handleChange()"
        label="Range"></p-checkbox>
    </div>
  </div>



  <!-- <div *ngIf="loadingSpinner" class="loadingOverlayRole">
    <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
  </div> -->
  <textarea class="p-editor-container jobForm-jobDescription" [style]="{ height: '240px' }" id="editor"
    formControlName="jobDescription" placeholder="Role Description"></textarea>
</form>
<p-toast></p-toast>