<div *ngIf="pageLoading" class="{{'loadingOverlay'}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div *ngIf="!showSummary">
    <div class="header ruleSet-header">
        <p>Rule {{index + 1}}</p>
        <em class="pi pi-trash" *ngIf="savedWorkflowIds.length > 0 || stage.workflowIds.length > 1"
            (click)="removeRule(workflowId, $event)"></em>
    </div>
    <div class="stage-content ruleSet-edit">
        <label class="ruleSet-label triggers-label" for="stage-trigger">When</label>
        <p-dropdown inputId="dropdown" optionLabel="name" [autoDisplayFirst]="false" [(ngModel)]="selectedTrigger"
            [options]="stageTriggerOptions" class="ruleSet-trigger" (onChange)="getTriggers(selectedTrigger.triggerId)">
        </p-dropdown>
        <label class="ruleSet-label conditions-label" for="stage-condition">Condition</label>
        <div class="stage-conditions" name="stage-condition">
            <p>Which one would you like to apply the rule?</p>
            <div class="radioButtons">
                <p-radioButton name="conditions" value="for_all" [(ngModel)]="stageCondition" inputId="for-all">
                </p-radioButton>
                <label for="for-all" [ngClass]="{'selected-radio': stageCondition === 'for_all'}">For
                    All</label>
                <p-radioButton name="conditions" value="matching_condition" [(ngModel)]="stageCondition"
                    inputId="matching-condition">
                </p-radioButton>
                <label for="matching-condition"
                    [ngClass]="{'selected-radio': stageCondition === 'matching_condition'}">Matching
                    Conditions</label>
            </div>
            <div class="toggleLogic" *ngIf="stageCondition === 'matching_condition' && conditionsList.length > 1">
                <div class="radioButtons">
                    <p-radioButton name="conditions" value="AND" [(ngModel)]="logicCondition" inputId="meet-all">
                    </p-radioButton>
                    <label for="meet-all" [ngClass]="{'selected-radio': logicCondition === 'AND'}">All</label>

                    <p-radioButton name="conditions" value="OR" [(ngModel)]="logicCondition" inputId="meet-any">
                    </p-radioButton>
                    <label for="meet-any" [ngClass]="{'selected-radio': logicCondition === 'OR'}">Any</label>
                </div>
            </div>
            <div class="consitionSet" *ngIf="stageCondition === 'matching_condition'">
                <div class="conditionSet-logic">
                    <span *ngFor="let operator of logicOperator">{{logicCondition}}<div class="flow-lines"></div>
                    </span>
                </div>
                <div [style]="{display: 'flex', flexDirection: 'column'}">
                    <div class="conditionSet-individual" *ngFor="let condition of conditionsList">
                        <span class="consitionSet-index">{{conditionsList.indexOf(condition)+1}}</span>
                        <span class="p-input-icon-right">
                            <em [ngClass]="{'pi pi-spin pi-spinner': loadConditionOptions}"></em>
                            <p-dropdown class="consitionSet-specific" [emptyMessage]="'Please select triggers'"
                                optionLabel="name" [options]="condition.specific" [autoDisplayFirst]="false"
                                [(ngModel)]="condition.values.conditionSource"
                                (onChange)="selectOperatorOptions($event, condition)">
                            </p-dropdown>
                        </span>
                        <p-dropdown class="consitionSet-specific" optionLabel="sign" [options]="condition.operator"
                            [autoDisplayFirst]="false" [(ngModel)]="condition.values.conditionClause">
                        </p-dropdown>

                        <!-- <input *ngIf="condition.values.conditionSource?.options?.length == 0" class="conditionSet-value"
                            [type]="condition.values.conditionSource?.inputType" pInputText
                            [(ngModel)]="condition.values.conditionValue.value"> -->

                        <p-dropdown [ngClass]="{'dropdown-input_condition' : condition.values.conditionSource?.options?.length == 0}" [editable]="condition.values.conditionSource?.options?.length == 0"
                            class="consitionSet-specific" [options]="condition.values.conditionSource?.options"
                            [autoDisplayFirst]="false" [(ngModel)]="condition.values.conditionValue.value">
                        </p-dropdown>

                        <div class="consitionSet-icons">
                            <em class="pi pi-plus addIcon-plus" (click)="addCondition(condition)"></em>
                            <em class="pi pi-minus removeIcon-minus"
                                [ngClass]="{'disabled' : conditionsList.length <= 1}"
                                (click)="removeCondition(condition)"></em>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="header-tabs" *ngIf="stageCondition == 'matching_condition'">
            <button class="tabs positive" [ngClass]="{'selected': displayAction}"
                (click)=" displayAction = true; displayElseAction = false">Positive</button>
            <button class="tabs negative" [ngClass]="{'selected': displayElseAction}"
                (click)=" displayAction = false; displayElseAction = true">Negative</button>
        </div>

        <label for="stage-action" class="actions-label ruleSet-label"
            *ngIf="displayAction || stageCondition == 'for_all' ">Action</label>
        <div class="stage-actions" *ngIf="displayAction || stageCondition == 'for_all' ">
            <div class="stage-actions-dropdown" *ngFor="let action of actionsList">
                <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.actions"
                    optionLabel="name" [(ngModel)]="action.values.actionName"
                    (onChange)="selectTemplateOptions($event.value.actionEventId, action)">
                </p-dropdown>
                <span class="p-input-icon-right">
                    <em [ngClass]="{'pi pi-spin pi-spinner': loadActionOptions}"></em>
                    <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.template"
                        (onChange)="setActionValues($event.value, action.values)" optionLabel="name" [filter]="true"
                        filterBy="name" [(ngModel)]="action.values.actionTemplate" *ngIf="action.template.length">
                    </p-dropdown>
                </span>
                <span *ngIf="stageError && action.values.actionName.actionEventId == 'moveStage'"
                    [style]="{color: 'red', fontSize: '12px', marginLeft: '1vw'}">No stages to
                    move</span>
                <em class="pi pi-minus deleteActionIcon removeIcon-minus" *ngIf="actionsList.length > 1"
                    (click)="removeAction(action)"></em>
                <span class="email-preview"
                    *ngIf="action.values.actionName.actionEventId === 'sendMail' || action.values.actionName.actionEventId === 'sendTestLink' || action.values.actionName.actionEventId === 'sendDocumentForm'"
                    [innerHTML]="action.values.actionTemplateContent">
                </span>
            </div>
        </div>
        <em class="pi pi-plus addActionIcon addIcon-plus" (click)="addNewAction()"
            *ngIf="displayAction || stageCondition == 'for_all' "></em>

        <label for="stage-action" class="actions-label ruleSet-label"
            *ngIf="displayElseAction && stageCondition == 'matching_condition' ">Action</label>
        <div class="stage-actions" *ngIf="displayElseAction && stageCondition == 'matching_condition' ">
            <div class="stage-actions-dropdown" *ngFor="let action of elseActionsList">
                <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.actions"
                    optionLabel="name" [(ngModel)]="action.values.actionName"
                    (onChange)="selectTemplateOptions($event.value.actionEventId, action)">
                </p-dropdown>
                <span class="p-input-icon-right">
                    <em [ngClass]="{'pi pi-spin pi-spinner': loadActionOptions}"></em>
                    <p-dropdown class="ruleSet-dropdown" [autoDisplayFirst]="false" [options]="action.template"
                        (onChange)="setActionValues($event.value, action.values)" optionLabel="name" [filter]="true"
                        filterBy="name" [(ngModel)]="action.values.actionTemplate" *ngIf="action.template.length">
                    </p-dropdown>
                </span>
                <span *ngIf="stageError && action.values.actionName.actionEventId == 'moveStage'"
                    [style]="{color: 'red', fontSize: '12px', marginLeft: '1vw'}">No stages to
                    move</span>
                <em class="pi pi-minus deleteActionIcon removeIcon-minus" *ngIf="elseActionsList.length > 1"
                    (click)="removeElseAction(action)"></em>
                <span class="email-preview"
                    *ngIf="action.values.actionName.actionEventId === 'sendMail' || action.values.actionName.actionEventId === 'sendTestLink' || action.values.actionName.actionEventId === 'sendDocumentForm'"
                    [innerHTML]="action.values.actionTemplateContent">
                </span>
            </div>
        </div>
        <em class="pi pi-plus addActionIcon addIcon-plus"
            *ngIf="displayElseAction && stageCondition == 'matching_condition' " (click)="addNewElseAction()"></em>

        <div class="stageRuleSet-buttons clearAndSave-buttons">
            <button pButton pRipple type="button" label="Clear" class="clear p-button-outlined p-button-secondary"
                (click)="clearRuleSet()"></button>
            <button pButton pRipple type="button" label="Save" class="save p-button-outlined" [loading]="loadingButton"
                (click)="saveWorkflow()"
                [ngClass]="{'invalid': checkValidation(stage)}"
                [disabled]="checkValidation(stage)"
                ></button>
        </div>
    </div>
</div>

<div class="ruleSet-summary" *ngIf="showSummary">
    <div class="header ruleSet-header">
        <p>Rule {{index + 1}}</p>
        <div class="ruleSet-header">
            <em class="pi pi-pencil" (click)="showSummary = false"></em>
            <em class="pi pi-trash" [style]="{marginLeft: '10px'}" (click)="removeRule(workflowId, $event)"></em>
        </div>
    </div>
    <div class="stage-content ruleSet-summary" *ngFor="let summary of workflowSummary">
        <div class="summary-info">
            <p class="info-label">When</p>
            <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!summary.trigger"></p-skeleton>
            <p class="info-value" *ngIf="summary.trigger">{{summary.trigger}}</p>
        </div>
        <hr>
        <div class="summary-info">
            <p class="info-label">Condition</p>
            <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!summary.filter.conditionApply">
            </p-skeleton>
            <div class="info-value" *ngIf="summary.filter.conditionApply">
                <p>{{summary.filter.conditionApply}}</p>
                <div *ngIf="summary.filter.conditionApply === 'Matching Condition'" class="consitionSet">
                    <div class="conditionSet-logic">
                        <span *ngFor="let operator of logicOperator">{{logicCondition}}<div class="flow-lines">
                            </div>
                        </span>
                    </div>
                    <div [style]="{display: 'flex', flexDirection: 'column'}">
                        <div *ngFor="let condition of summary.filter.conditionSet" class="conditionSet-summary">
                            <span class="consitionSet-index">{{summary.filter.conditionSet.indexOf(condition)+1}}</span>
                            <p>{{condition.property.charAt(0).toUpperCase() + condition.property.slice(1)}}</p>
                            <p>{{condition.clause.sign}}</p>
                            <p [ngSwitch]="true">
                                <!-- <span *ngSwitchCase="condition.value.type == 'string'">
                                    {{condition.value.value.charAt(0).toUpperCase() +
                                condition.value.value.slice(1)}}
                                </span> -->
                                <span *ngSwitchCase="condition.value.type == 'boolean'">
                                    {{condition.value.value === 'yes' ? 'Yes' : 'No'}}
                                </span>
                                <span *ngSwitchDefault>
                                    {{condition.value.value}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="summary-info">
            <p class="info-label">Positive Action</p>
            <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!summary.actions"></p-skeleton>
            <div [style]="{width: '100%'}" *ngIf="summary.actions">
                <div class="info-value" *ngFor="let action of summary.actions; index as index">
                    <div [style]="{display: 'flex'}">
                        <p [style]="{width: '15vw'}"> {{action.name }}</p>
                        <p>{{action?.template?.name}}</p>
                    </div>
                    <p-divider *ngIf="summary.actions.length > 1 && index !== summary.actions.length - 1"
                        [type]="'dashed'">
                    </p-divider>
                </div>
            </div>
        </div>
        <hr *ngIf="stageCondition == 'matching_condition' && summary.elseActions.length">
        <div class="summary-info" *ngIf="stageCondition == 'matching_condition' && summary.elseActions.length">
            <p class="info-label">Negative Action</p>
            <p-skeleton width="6rem" height="1rem" styleClass="mb-2" *ngIf="!summary.elseActions"></p-skeleton>
            <div [style]="{width: '100%'}" *ngIf="summary.elseActions">
                <div class="info-value" *ngFor="let action of summary.elseActions; index as index">
                    <div [style]="{display: 'flex'}">
                        <p [style]="{width: '15vw'}"> {{action.name }}</p>
                        <p>{{action?.template?.name}}</p>
                    </div>
                    <p-divider *ngIf="summary.elseActions.length > 1 && index !== summary.elseActions.length - 1"
                        [type]="'dashed'">
                    </p-divider>
                </div>
            </div>
        </div>
    </div>
</div>

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>