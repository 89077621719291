import { Component, OnInit, ViewChild } from '@angular/core'
import { SettingsService } from 'src/app/services/settings.service'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  @ViewChild('renameField') renameField: any;

  isLoading: boolean = false;
  saveBtnLoading: boolean = false;
  settingsSlider: boolean = false;
  clicked: boolean = false;
  stageTypes: any = [];
  userDetails: any = {};
  alphaNumeric: RegExp = /^[a-zA-Z0-9_ ]*$/;
  defaultStatusOptons: any = [];
  defaultStatusSettings: any = {};
  savedStatusSettings: any = {};
  statusOptons: any = [];
  historyStatus: any;
  
  renameHistory: any = [];
  renamedStatus: string = '';
  timelyActivity: any = [];
  selectedStage: any;
  selectedStatus: any;
  selectedStatusLabel: string = '';
  
  constructor(private messageService: MessageService, private settingService: SettingsService) { }

  ngOnInit(): void {
    this.stageTypes = ['Eligibility', 'Test', 'Interview', 'Screening', 'OnBoarding', 'Others', 'Offer']
    this.userDetails = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails') || '')
    : ''
    this.getStageStatusData();
    this.getDefaultStatus();
  }

  getStageStatusData(){
    console.log("userDetails ->", this.userDetails)
    this.isLoading = true;
    this.settingService.getStageStatusData().subscribe(res => {
      let savedSettings = res?.data ? res.data.stageStatus : {};
      this.historyStatus = res?.data ? res.data.historyStatus : [];
      this.savedStatusSettings = this.processObject(savedSettings);
      if(this.selectedStage && this.selectedStatus){
        this.statusOptons = this.savedStatusSettings[this.selectedStage];
        this.statusChange(this.selectedStatus);
        this.renamedStatus = '';
        this.renameHistory = this.historyStatus[this.selectedStage][this.selectedStatus];
        this.getHistory();
      }
      this.isLoading = false;
    }) 
  }

  getDefaultStatus(){
    this.isLoading = true;
    this.settingService.getStageStatusData(true).subscribe(res => {
      let defaultSettings = res?.data ? res.data?.stageStatus : [];
      this.defaultStatusSettings = this.processObject(defaultSettings);
      this.isLoading = false;
    })
  }

  openStatusSettings() {
    this.settingsSlider = true
  }

  saveStatus(){
    this.clicked = true;
    if(this.selectedStage && this.selectedStatus && this.renameField.valid){
      this.saveBtnLoading = true;
      let payload = {
          stageType: this.selectedStage,
          statusValue: this.selectedStatus,
          renamedLabel: this.renamedStatus,
          userName: this.userDetails.name,
          userEmail: this.userDetails.email,
      }
      this.settingService.saveStageStatus(payload).subscribe(res => {
        if(res.status == 200){
          this.clicked = false
          this.saveBtnLoading = false;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Changes Saved Succcessfully' });
          this.getHistory();
          this.getStageStatusData();
        } else {
          this.saveBtnLoading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' })
        }
      })
    }
  }

  stageChange(event: string){
    this.defaultStatusOptons = this.defaultStatusSettings[event];
    this.statusOptons = this.savedStatusSettings[event];
    this.renameHistory = [];
    this.timelyActivity = [];
    this.selectedStatus = '';
    this.selectedStatusLabel = '';
  }

  statusChange(event: any){
    this.statusOptons.forEach((each: any) => {
      if(each.value == event){
        this.selectedStatusLabel = each.label;
      }
    })
    this.renameHistory = this.historyStatus[this.selectedStage][this.selectedStatus];
    this.getHistory();
  }

  processObject(statusSettings: any){
    let newStatusSettings:any = {}
    for (let stageType in statusSettings) {
        if (statusSettings.hasOwnProperty(stageType)) {
            let stageStatusArray = [];
            let stageStatus:any = statusSettings[stageType as keyof typeof statusSettings]
            for (let status in stageStatus) {
                if (stageStatus.hasOwnProperty(status))
                    stageStatusArray.push({ label: stageStatus[status as keyof typeof stageStatus], value: status })
            }
            newStatusSettings[stageType] = stageStatusArray;
        }
    }
    return newStatusSettings;
  }

  getHistory(){
    let allDates: any = [];
    let movedDate = '';
    this.timelyActivity = [];

    this.renameHistory.forEach((each:any) => {
      each.badge = each.userName.split(" ").map((n: string, index: number) => { if (index == 0 || index == each.userName.split(" ").length - 1) return n[0]; else return '' }).join("");
      each._actionDate = each.actionDate.split('T')[0];
    })

    this.renameHistory.forEach((activity: any) => {
      if (movedDate !== activity._actionDate && !allDates.includes(activity._actionDate)) {
        movedDate = activity._actionDate;
        allDates.push(movedDate);
      }
    })

    allDates.forEach((eachDate: string) => {
      this.timelyActivity.push(this.renameHistory.filter((activity: any) => activity._actionDate == eachDate));
    })

    this.timelyActivity.sort((a: any, b: any) => +new Date(b[0].actionDate) - +new Date(a[0].actionDate))
    this.timelyActivity.forEach((array: any) => {
      array.sort((a: any, b: any) => +new Date(b.actionDate) - +new Date(a.actionDate));
    });

  }

  resetForm = () => {
    this.renameHistory  = [];
    this.renamedStatus  = '';
    this.timelyActivity  = [];
    this.selectedStage = null;
    this.selectedStatus  = null;
    this.selectedStatusLabel  = '';
    this.getStageStatusData(); 
  }
}
