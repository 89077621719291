<div class="sendEmail-header">  
    <div class="email-heading">Registartion Successful</div>
    <div class="header-btn">
        <button pButton pRipple [loading]="loader" label="Save Template" type="submit" class="p-button-raised p-button-text button-align" (click)="submit()"></button>
    </div>
</div>
<p-divider class="p-divider"></p-divider>

<div *ngIf="!isLoading">

  <form class="emailForm" [formGroup]="sendEmailForm">
      <span class="p-float-label emailForm-elements">
          <p-dropdown  class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true" filterBy="templateTitle" optionLabel="templateTitle" optionValue="id" formControlName="templateId"
          [options]="templatesList" (onChange)="emailWrite()">
          </p-dropdown>
          <label for="inputtext">Select Template <span [style]="{color: '#E24A47'}">*</span></label>
          <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('templateId')" name="Template">
          </app-handle-error>
      </span>

      <div *ngIf="sendEmailForm.value.templateContent">
        <span class="p-float-label emailForm-elements">
          <input [readOnly]="true" class="disable-input_field" type="text" name="Subject :" id="inputtext" pInputText formControlName="subjectTitle"
          [ngClass]="{'invalid': clicked && sendEmailForm.controls['subjectTitle'].errors}">
          <label for="inputtext">Subject Line</label>
          <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('subjectTitle')" name="Subject">
          </app-handle-error>
      </span>
  
      <!-- <form>
          <textarea placeholder="Body" id="content"></textarea>
      </form> -->
      <div class="email-preview">
        <div [innerHTML]="sendEmailForm.value.templateContent">
        </div>   
      </div>
      </div>
  
  </form>
</div>
<p-toast></p-toast>
<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>