import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import * as codes from '../../../assets/Json/countryTeleCodes.json';
import {nanoid} from 'nanoid'
import { EventServicesService } from 'src/app/services/event-services.service';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css']
})
export class FieldsComponent implements OnInit, OnChanges {

  @ViewChild('checkboxField') checkboxField: any;
  @ViewChild('radioField') radioField: any;
  @ViewChild('booleanRadioField') booleanRadioField: any;
  @ViewChild('multiEmailField_2') multiEmailField_2: any;
  @ViewChild('multiEmailField_1') multiEmailField_1: any;

  @Input() model: any;
  @Input() ansChange: any;
  @Input() clicked: boolean = false;
  @Input() invalidField: any;
  @Input() inEditMode: boolean = false;
  @Input() inValidLocation: boolean = false;
  @Input() belowAge: boolean = false;
  @Input() duplicateData: any = {};
  @Input() CGPAFieldList: any = [];
  @Input() yearsFieldList: any = [];

  @Output() fieldDataEmitter = new EventEmitter<any>();
  @Output() checkValidator = new EventEmitter<any>();

  fieldData: any;

  resume: File[] = [];
  file: File[] = []; 
  fileBase64: any = '';
  tempAnswer: any = null;
  countryCodeList: any = (codes as any).default;
  _countryCodeList: Array<any> = []
  selectedCode: any = { country: "India", countryCodes: ["91"], isoCode2: "IN", isoCode3: "IND", phLength: 10 };

  fileType: string = '';
  schoolId: string = '';
  codeSearchTerm: string = '';
  currentDate: any;
  
  validFields: boolean = false;
  uploadingFile: boolean = false;
  invalidLength: boolean = false;
  s3Domain: string = 'https://s3.amazonaws.com'; 
  bucketName: string = this.globalService.bucketName;
  assetBucketName: string = this.globalService.assetBucketName;
  emailPattern: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  cgpaPattern: RegExp = /^\d{1,2}(\.\d{1,2})?$/;
  positiveNumber: RegExp = /^[1-9]\d*$/;

  constructor(private fileUploadService: FileUploadService, private globalService: GlobalServicesService, private eventService: EventServicesService) { }
  
  
  ngOnInit(): void {

    if (this.model.fieldType === 'file') {

      if (this.model.fieldType == 'file') {
        this.fileType = this.model.fileProperities?.fileFormat.map((each: string) =>  '.'+each.toLowerCase() ).join(',');
      }

      this.schoolId = localStorage.getItem('schoolData')
        ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
        : '';
    }

    this._countryCodeList = this.countryCodeList;
    if(this.model.fieldName == 'If No - When can you start the internship/training?') this.currentDate = new Date();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['invalidField']){
      let invalidField = document.getElementById(this.invalidField?.fieldId);
      invalidField?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (this.inEditMode && changes['ansChange']) {
      if (this.model.fieldType == 'dropdown') { 
        let label = this.model.answer[0];
        let value = this.model.answer[0];
        switch(true){
          case label === true : label = "Yes"; break;
          case label === false : label = "No"; break;
          case label === 'male' : label = 'Male'; break;
          case label === 'female' : label = 'Female'; break;
          case label === 'preferNotToSay' : label = 'Prefer Not to Say'; break;
        }
        this.tempAnswer = { label: label, value: value };
      } 
      else if (this.model.fieldType == 'checkbox') {
        this.tempAnswer = this.model.answer;
      } 
      else if (this.model.fieldType == 'multiinput') {
        let splitName = this.model.answer[0].split(" ");
        let lastName = splitName.pop();
        let firstName = splitName.join(" ");
        this.model.fieldOptions[0].value = firstName;
        this.model.fieldOptions[1].value = lastName;
      } 
      else if (this.model.fieldType == 'multiInputEmail') {
        this.model.fieldOptions[0].value = this.model.answer[0];
        this.model.fieldOptions[1].value = this.model.answer[0];
        this.model.readOnly = true;
      } 
      else if (this.model.fieldType == 'file') {
        this.fileBase64 = this.model.answer[0];
        if(this.fileBase64)
        {
          this.fileType = '.doc';
          let fileName = this.fileBase64?.split('/').pop();
          let blob: any = new Blob([""], { type: 'application/pdf' });
          blob["lastModifiedDate"] = "";
          blob["name"] = fileName;
          this.file = [blob];
        }
      } 
      else if (this.model.fieldType == 'mobile') {
        this.tempAnswer = this.model.answer[1];
        let countryCode = this.model.answer[0].replace('+','')
        this.countryCodeList.forEach((each: any)=>{
          if(each.countryCodes[0] === countryCode)
          {
            this.selectedCode = each;
          }
        })
        if(this.model.fieldName === "Primary Mobile Number")
        {
          this.model.readOnly = true;
        }
      } 
      else if(this.model.fieldName === "Candidate ID")
      {
        this.tempAnswer = this.model.answer[0]
        this.model.readOnly = true;
      }
      // To Update the College List
      // else if(this.model.fieldName === "Bachelor College" || this.model.fieldName === "Post Graduate College"){
      //   this.model.options = this.collegeList;
      // }
      else {
        this.tempAnswer = this.model.answer[0]
      };
    }
  }

  onSelectfile(event: any, acl?: boolean) {
    if (this.file.length) this.file.shift();
    this.file.push(...event.addedFiles);
    this.handleUpload(event, acl)
  }

  onRemovefile() {
    this.file = [];
    this.checkValidator.emit(false);
    this.fileBase64 = '';
  }

  handleUpload(event: any, acl?: boolean) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, acl)
    };
  }

  searchCode = (event: any) => {
    if (this.codeSearchTerm.length) {
      this._countryCodeList = this.countryCodeList.filter((val: any) => val.country.toLowerCase().includes(event.toLowerCase()));
    } else this._countryCodeList = this.countryCodeList;
  }

  fileUpload(file: any, acl?: boolean) {
    this.uploadingFile = true;
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay: any = {
      Bucket_name: this.assetBucketName,
      file_name: `custom-form/${nanoid(10)}/${new Date().getTime()}/${fileName}`,
      type: file.type,
    };
    acl ? pay['ACL'] = 'public-read' : null;
    const blobData: any = file;

    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                this.fileBase64 = `${this.s3Domain}/${this.assetBucketName}/${pay.file_name}`;
                this.fieldDataEmitter.emit(this.fileBase64);
                this.checkValidator.emit(r.ok);
                this.uploadingFile = false;
              }
            }
          );
        }
      }
    );
  }

  emitValue = (event: any, inputField: any, validLength?: number) => {
    switch (this.model.fieldType) {
      case 'multiInput': {
        let mergedValue = this.model.fieldOptions[0].value + ' ' + this.model.fieldOptions[1].value;
        this.fieldDataEmitter.emit(mergedValue);
        this.checkValidator.emit(inputField.valid);
      } break;
      case 'multiInputEmail': {
        if (this.model.fieldOptions[0].value === this.model.fieldOptions[1].value && this.multiEmailField_1.valid && this.multiEmailField_2) {
          this.fieldDataEmitter.emit(this.model.fieldOptions[1].value)
          this.checkValidator.emit(inputField.valid);
        } else {
          this.checkValidator.emit(false);
        }
      } break;
      case 'dropdown': {
        if(event?.value == undefined){
          this.fieldDataEmitter.emit(null);
        } else {
          this.fieldDataEmitter.emit(event?.value)
          this.checkValidator.emit(inputField.valid);
        }
      } break;
      case 'mobile': {
        this.fieldDataEmitter.emit([this.selectedCode.countryCodes[0], event]);
        if (this.tempAnswer && this.tempAnswer?.toString().length != validLength) { 
          this.checkValidator.emit(!inputField.valid); 
          this.invalidLength = true; 
        }
        else { 
          this.checkValidator.emit(inputField.valid);
          this.invalidLength = false; 
        }
      } break;
      case 'input': {
        if (this.model.fieldName === 'Aadhar Card Number') {
          if (this.tempAnswer.toString().length != 12) {
            this.checkValidator.emit(false); this.invalidLength = true;
          } else {
            this.checkValidator.emit(inputField.valid); this.invalidLength = false;
            this.fieldDataEmitter.emit(event)
          }
        }
        else if(this.model.fieldName === 'Pin Code')
        {
          if (this.tempAnswer.toString().length != 6) {
            this.checkValidator.emit(false); this.invalidLength = true;
          } else {
            this.checkValidator.emit(inputField.valid); this.invalidLength = false;
            this.fieldDataEmitter.emit(event)
          }
        } else {
          this.fieldDataEmitter.emit(event)
          this.checkValidator.emit(inputField.valid);
        }
      } break;
      case 'percentage': {
        if(event?.value > 10){
          if(event?.value > 100){
            this.fieldDataEmitter.emit(100)
          } else{
            this.fieldDataEmitter.emit(event?.value)
          }
          this.checkValidator.emit(true);
        } else {
          this.checkValidator.emit(false);
        }
      } break;
      case 'number': {
        if(this.CGPAFieldList.includes(this.model.fieldName)){
          if(event > 10 || event <= 0){
            this.checkValidator.emit(false);
            inputField.control.errors = true;
          } else {
            this.fieldDataEmitter.emit(event)
            this.checkValidator.emit(true);
            inputField.control.errors = false;
          }
        } else if(this.yearsFieldList.includes(this.model.fieldName)){
          if(event.toString().length != 4 || event > new Date().getFullYear() + 4 || event < 1900){
            this.checkValidator.emit(false);
            inputField.control.errors = true;
          } else {
            this.fieldDataEmitter.emit(event)
            this.checkValidator.emit(true);
            inputField.control.errors = false;
          }
        }
        else {
          this.fieldDataEmitter.emit(event)
          this.checkValidator.emit(inputField.valid);
        }
      } break;
      default: {
        this.fieldDataEmitter.emit(event)
        this.checkValidator.emit(inputField.valid);
      };
    }
  }

  convertByte(sizeFormat: string, size: number) {
    let formattedSize = 0;

    if (sizeFormat === 'mb') {
      formattedSize = size * 1024 * 1024;
    }
    else if (sizeFormat === 'kb') {
      formattedSize = size * 1024;
    }

    return formattedSize;
  }

  restrictNumber(event: any, len: number) {
    let e: any = event || window.event; 
    let key = e.keyCode || e.which;

    if (key < 48 || key > 57 || event.target.value.length >= len) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
  }
}
