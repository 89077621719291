<div *ngIf="!applicationPage && !registrationForm && !verification">
  <div *ngIf="route !=='/login' && !candidatePanel" class="container">
    <div class="menu">
      <app-side-nav></app-side-nav>
    </div>
    <div class="main-container">
      <div class="header">
        <app-header [searchItem]="searchItem"></app-header>
      </div>
      <div class="main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div *ngIf="route ==='/login'">
    <app-login [companyLogo]="companyLogo"></app-login>
  </div>

  <div *ngIf="candidatePanel">
    <app-candidate-panel></app-candidate-panel>
  </div>
</div>

<div *ngIf="applicationPage || registrationForm || verification">
  <router-outlet></router-outlet>
</div>

<p-toast></p-toast>