import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { JobServicesService } from '../services/job-services.service';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedServicesService } from '../services/shared-services.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css'],
  providers: [MessageService]
})

export class JobListComponent implements OnInit {

  // Array Var
  jobList: Array<any> = [];
  pageLimits: Array<any> = [];
  status: MenuItem[] = [];
  selectedJobs: Array<any> = [];
  jobsCol: Array<any> = [];
  _jobsCol: Array<any> = [];
  _selectedColumns: any[] = [];
  colsToDownload: Array<any> = [];
  jobOptions: Array<any> = [];
  eventJob: object = {};
  filteredJobList: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];

  //Filtering Array
  checkedColumns: Array<any> = [];
  selectedStatusElements: Array<any> = [];
  selectedJobTypeElements: Array<any> = [];
  selectedDepartmentElements: Array<any> = [];

  // Boolean Var
  addJobsSlider: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;

  // String var
  statusSelected: string = 'All';
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  columnFieldId: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'jobList';

  constructor(
    private jobService: JobServicesService,
    private messageService: MessageService,
    private _router: Router,
    private _sharedService: SharedServicesService,
  ) {
    this.pageLimits = [10, 25, 50, 100];
    this.status = [
      {
        label: 'All',
        command: () => {
          this.getJobsByFilter('All');
        },
      },
      {
        label: 'Active',
        command: () => {
          this.getJobsByFilter('Active');
        },
      },
      {
        label: 'Saved',
        command: () => {
          this.getJobsByFilter('Saved');
        },
      },
      {
        label: 'Onhold',
        command: () => {
          this.getJobsByFilter('Onhold');
        },
      },
      {
        label: 'Completed',
        command: () => {
          this.getJobsByFilter('Completed');
        },
      },
    ];

    this.jobsCol = [
      { index: 1, field: 'department', header: 'Department', options: ["Designer", "Developer", "Human Resource"] },
      { index: 2, field: 'jobType', header: 'Job Type', options: ["Full-Time", "Part-Time", "Internship", "Contract"] },
      { index: 3, field: 'applied', header: 'Applied' },
      { index: 4, field: 'inProgress', header: 'Inprogress' },
      { index: 5, field: 'selected', header: 'Selected' },
      { index: 6, field: 'salary', header: 'Salary' },
      { index: 7, field: 'minSalary', header: 'Min Salary' },
      { index: 8, field: 'maxSalary', header: 'Max Salary' },
      { index: 9, field: 'status', header: 'Status', options: ["Active", "Onhold", "Completed", "Saved"] },
      { index: 10, field: 'location', header: 'Location' },
      { index: 11, field: 'workExperience', header: 'Experience' }
    ]

    this._selectedColumns = [
      { index: 1, field: 'department', header: 'Department', options: ["Designer", "Developer", "Human Resource"] },
      { index: 2, field: 'jobType', header: 'Job Type', options: ["Full-Time", "Part-Time", "Internship", "Contract"] },
      { index: 3, field: 'applied', header: 'Applied' },
      { index: 4, field: 'inProgress', header: 'Inprogress' },
      { index: 5, field: 'selected', header: 'Selected' },
      { index: 6, field: 'salary', header: 'Salary' },
      { index: 9, field: 'status', header: 'Status', options: ["Active", "Onhold", "Completed", "Saved"] },
      { index: 10, field: 'location', header: 'Location' },
    ]

    this.jobOptions = [
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.deleteJob(this.eventJob)
        }
      }
    ]
  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.getAllJobs();
    this.getColumnId();
    this.getSavedColumns();
    this._jobsCol = this.jobsCol;
    this.colsToDownload = this.jobsCol;
    this.checkedColumns = this._selectedColumns;
    this._sharedService.emitChange('')
  }

  // API functions
  // get all the jobs
  getAllJobs = () => {
    this.loadingSpinner = true;
    this.jobList = [];
    this.jobService
      .getAllJobs(this.pageNumber, this.pageSize)
      .subscribe((response: any) => {
        this.jobList = response.data ? response.data.items : [];
        this.totalLength = response.data ? response.data.totalItems : 0;
        this.loadingSpinner = false;
      });
  };

  getFilterJobs = (status?: string) => {
    this.loadingSpinner = true;
    this.jobList = [];
    this.jobService
      .getFilterJobs(this.pageNumber, this.pageSize, status)
      .subscribe((response: any) => {
        this.jobList = response.data ? response.data.items : [];
        this.totalLength = response.data ? response.data.totalItems : 0;
        this.loadingSpinner = false;
      });
  };

  deleteJob = (job: any) => {
    this.loadingSpinner = true;
    this.jobService.deleteJob(job.id).subscribe(res => {
      if (res) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Job deleted...' });
        this.getAllJobs();
      }
      else
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to delete the job...' });
    })
    this.loadingSpinner = false;
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    this.jobService.saveColumns(this.columnFieldId, [this.columnSettings.value]).subscribe((result: any) => {
      this.savedColumnSettings = result.data.jobSelectedColumns;
      this.loadingSpinner = false;
    });
    this.displaySaveColumnSettings = false;
  }

  getColumnId = () => {
    this.jobService.getSchool().subscribe(res => {
      this.columnFieldId = res.data[0].id;
    })
  }


  getSavedColumns = () => {
    this.jobService.getSchool().subscribe(res => {
      this.savedColumnSettings = res.data[0].jobSelectedColumns ? res.data[0].jobSelectedColumns : []
    });
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService.deleteColumns(this.columnFieldId, columnId).subscribe(res => {
      this.savedColumnSettings = res.data.jobSelectedColumns;
      this.loadingSpinner = false;
    })
  }

  // Normal Functions
  setStatus = (status: string) => {
    this.statusSelected = status;
  }

  getEventVal = (event: Event) => {
    return (event.target as HTMLInputElement).value;
  }

  onSelectedJobChange = (value: []) => {
    this.selectedJobs = value;
  }

  exportJob = () => {

    let checkedColumnFields: any = ['jobName'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.jobList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  }

  applySelectedFilter = () => {
    if (this.selectedStatusElements.length || this.selectedJobTypeElements.length || this.selectedDepartmentElements.length) {
      this.filteredJobList = [];

      let statusFilter = this.jobList.filter(element => this.selectedStatusElements.includes(element.status));
      let jobTypeFilter = this.jobList.filter(element => this.selectedJobTypeElements.includes(element.jobType));
      let departmentFilter = this.jobList.filter(element => this.selectedDepartmentElements.includes(element.department));

      this.filteredJobList = [...new Set([...statusFilter, ...jobTypeFilter, ...departmentFilter])]
      this.showFilterSidebar = false;

    } else {
      this.filteredJobList = [];
    }
  };

  clearSelectedFilters = () => {
    this.selectedStatusElements = [];
    this.selectedJobTypeElements = [];
    this.selectedDepartmentElements = [];
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.jobsCol.filter(e => savedColumnValue.includes(e.field));
  }

  openCandidates = (jobName: string, jobId: string) => {
    window.localStorage.setItem('selectedJobId', jobId);
    window.localStorage.setItem('selectedJobName', jobName);
    this._router.navigate(['jobs/candidates/', jobName], { queryParams: { view: 'candidates' } });
  }

  // get jobs by limit
  getJobsByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.statusSelected === 'All' ? this.getAllJobs() : this.getFilterJobs(this.statusSelected);
  };

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._jobsCol = this.jobsCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.jobsCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  // get jobs by filter
  getJobsByFilter = (status: string) => {
    this.statusSelected = status;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    status === 'All' ? this.getAllJobs() : this.getFilterJobs(status);
  };

  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  // For custom pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getAllJobs();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize >= this.pageSize ? this.totalLength - this.last : this.pageSize;
    this.pageNumber -= 1;
    this.getAllJobs();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.jobsCol.filter(col => val.includes(col));
  }

  getHeaderVal(col: string) {
    let result = col.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  setEventJobId(job: any) {
    this.eventJob = job;
  }

}
