<div class="sidebar-header">
    <div class="sendLink-header">
        <div class="sendLink-heading">Send Calendar Invite</div>
        <div class="header-btn">
            <button pButton pRipple type="submit" [label]="'Send Invite'" class="p-button-raised p-button-text button-align"
                [loading]="uploadBtnLoading" (click)="teamsSchedule()" [disabled]="!tokenValid"></button>
        </div>
    </div>
</div>
<p-divider class="p-divider"></p-divider>

<div class="tokenValidity" *ngIf="!tokenLoading">
    <div *ngIf="tokenValid" class="verified">
        <span>
            <!-- <img src="../../../assets/icons-svg/pngwing.com.png" alt="" style="width: 16px; margin-left: 8px; height: 16px;"> -->
            Calendar Integrated</span>
        <img src="../../../assets/verifed-symbol.png" alt="Verified" style="width: 16px; margin-left: 8px; height: 16px;">
    </div>
    <div *ngIf="!tokenValid">
        <span class="token-error">Token Expired! <a (click)="redirectToSettings()" class="hyperlink">Log in to Microsoft.</a></span>
    </div>
</div>

<div *ngIf="tokenLoading" style="width: 1rem; margin-left: 5vw;">
    <p-progressSpinner [style]="{height: '20px', width: '20px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div [style]="{margin: '1rem 5vw 0'}">
    <p style="font-weight: 500;">Candidate: </p>
    <div class="email-chips">
        <p-chip *ngFor="let item of candidatesList" [label]="item.email" [style]="{marginRight: '4px'}" [pTooltip]="item.name" tooltipPosition="bottom" [showDelay]="300"></p-chip>
    </div>
</div>
<div class="sendLink">

    <span class="p-float-label sendLinkForm-elements" >
        <p-multiSelect id="recruiters" [options]="recruitersList" [selectionLimit]="5" [(ngModel)]="selectedRecruiters" optionLabel="name" display="chip"></p-multiSelect>
        <label for="recruiters">Recruiters </label>
        <span style="display: block; font-size: 12px; color: #999999;">Maximum 5 Recruiters.</span>
    </span>

    <span class="p-float-label sendLinkForm-elements">
        <p-dropdown [style]="{width: '25vw'}" id="duration" [required]="true" [options]="slotDurations" [(ngModel)]="selectedSlotDuration"
            optionLabel="label" optionValue="value" (ngModelChange)="getCalendarSlots(calendarDate)"
            [autoDisplayFirst]="false">
        </p-dropdown>
        <label for="duration">Slot Duration <span class="imp">*</span></label>
        <span *ngIf="clicked && !selectedSlotDuration" class="errorMessage">This field is required!.</span>
    </span>

    <span class="p-float-label sendLinkForm-elements">
        <p-calendar inputId="dateformat" [showIcon]="true" [minDate]="currentDate" [required]="true" dateFormat="yy-mm-dd" [(ngModel)]="calendarDate" (ngModelChange)="getCalendarSlots(calendarDate)">
        </p-calendar>
        <label for="dateformat">Date <span class="imp">*</span></label>
        <span *ngIf="clicked && !calendarDate" class="errorMessage">This field is required!.</span>
    </span>

    <span class="p-float-label sendLinkForm-elements">
        <p-dropdown [style]="{width: '25vw'}" id="slotSelection" emptyMessage="No dates selected!" [required]="true" [options]="slotsList" [(ngModel)]="selectedSlot"
            optionLabel="label" optionValue="value"
            [autoDisplayFirst]="false" >
        </p-dropdown>
        <label for="slotSelection">Select Slot <span class="imp">*</span></label>
        <span *ngIf="clicked && !selectedSlot" class="errorMessage">This field is required!.</span>
    </span>

    <span class="p-float-label sendLinkForm-elements" >
        <input type="text" pInputText [style]="{width: '25vw'}" [required]="true" inputId="venue" [(ngModel)]="calendarSubject">
        <label for="venue">Subject <span class="imp">*</span></label>
        <span *ngIf="clicked && !calendarSubject" class="errorMessage">This field is required!.</span>
    </span>
</div>