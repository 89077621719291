import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundProgressModule } from 'angular-svg-round-progressbar';

import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";

// COMPONENTS
import { JobAddComponent } from '../job-add/job-add.component';
import { CandidateInfoComponent } from '../job/candidate-info/candidate-info.component';
import { WorkflowComponent } from '../job/workflow/workflow.component';
import { WorkflowManageScheduleComponent } from '../job/workflow-manage-schedule/workflow-manage-schedule.component';
import { WorkflowRulesetComponent } from '../job/workflow-ruleset/workflow-ruleset.component';
import { EventDetailsComponent } from '../events/event-details/event-details.component';
import { RoleAddComponent } from '../roles/role-add/role-add.component';
import { EventsAddComponent } from '../events/events-add/events-add.component';
import { HandleErrorComponent } from './handle-error/handle-error.component';
import { AccessManagementComponent } from '../job/access-management/access-management.component';
import { ManageSlotComponent } from '../job/manage-slot/manage-slot.component';
import { StageCandidatesComponent } from '../job/stage-candidates/stage-candidates.component';
import { TalentpoolAddComponent } from '../talentpool/talentpool-add/talentpool-add.component';
import { InterviewPanelComponent, UtcToIstPipe } from '../job/interview-panel/interview-panel.component';
import { JitsiContaineComponent } from '../job/jitsi-container/jitsi-container';
import { RegistrationFormComponent } from '../forms/registration-form/registration-form.component';
import { FieldsComponent } from '../forms/fields/fields.component';
import { FeedbackFormComponent } from '../forms/feedback-form/feedback-form.component';
import { SendEmailComponent } from '../talentpool/send-email/send-email.component';
import { OtpVarificationComponent } from '../job/otp-verification/otp-verification.component';
import { RegistrationSuccessfulComponent } from '../talentpool/registration-successful/registration-successful.component';
import { InterviewOfflineComponent } from '../job/interview-offline/interview-offline.component';
import { LoginOtpVerificationComponent } from '../job/login-otp-verification/login-otp-verification.component';
import { CalendarInviteComponent } from '../job/calendar-invite/calendar-invite.component';

// PRIME-NG MODULES
import { SplitButtonModule } from 'primeng/splitbutton';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from 'primeng/editor';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { TimelineModule } from 'primeng/timeline';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { BadgeModule } from 'primeng/badge';
import { InplaceModule } from 'primeng/inplace';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { MenuModule } from 'primeng/menu';
import { ChipsModule } from 'primeng/chips';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';
import { PasswordModule } from "primeng/password";
import { InputNumberModule } from 'primeng/inputnumber';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    JobAddComponent,
    CandidateInfoComponent,
    WorkflowComponent,
    WorkflowManageScheduleComponent,
    WorkflowRulesetComponent,
    EventDetailsComponent,
    EventsAddComponent,
    RoleAddComponent,
    HandleErrorComponent,
    AccessManagementComponent,
    ManageSlotComponent,
    StageCandidatesComponent,
    TalentpoolAddComponent,
    InterviewPanelComponent,
    JitsiContaineComponent,
    FeedbackFormComponent,
    RegistrationFormComponent,
    FieldsComponent,
    SendEmailComponent,
    OtpVarificationComponent,
    RegistrationSuccessfulComponent,
    InterviewOfflineComponent,
    LoginOtpVerificationComponent,
    CalendarInviteComponent,
    UtcToIstPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DividerModule,
    OverlayPanelModule,
    InputTextModule,
    SplitButtonModule,
    DividerModule,
    CheckboxModule,
    EditorModule,
    ProgressSpinnerModule,
    ToastModule,
    DialogModule,
    InputTextModule,
    InputMaskModule,
    RoundProgressModule,
    TimelineModule,
    RadioButtonModule,
    DropdownModule,
    SkeletonModule,
    InputTextareaModule,
    PanelModule,
    SidebarModule,
    MultiSelectModule,
    CalendarModule,
    NgxDropzoneModule,
    AccordionModule,
    TableModule,
    BadgeModule,
    InplaceModule,
    BreadcrumbModule,
    ConfirmPopupModule,
    ButtonModule,
    ChipModule,
    MenuModule,
    ChipsModule,
    AvatarModule,
    MenubarModule,
    TooltipModule,
    PasswordModule,
    InputNumberModule,
    NgDynamicBreadcrumbModule,
    PdfViewerModule,
    ConfirmDialogModule
  ],
  exports: [
    JobAddComponent,
    CandidateInfoComponent,
    WorkflowComponent,
    InterviewPanelComponent,
    WorkflowManageScheduleComponent,
    WorkflowRulesetComponent,
    EventDetailsComponent,
    EventsAddComponent,
    RoleAddComponent,
    HandleErrorComponent,
    AccessManagementComponent,
    StageCandidatesComponent,
    TalentpoolAddComponent,
    JitsiContaineComponent,
    FeedbackFormComponent,
    RegistrationFormComponent,
    SendEmailComponent,
    FieldsComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DividerModule,
    OverlayPanelModule,
    InputTextModule,
    SplitButtonModule,
    DividerModule,
    CheckboxModule,
    EditorModule,
    ProgressSpinnerModule,
    ToastModule,
    DialogModule,
    InputTextModule,
    InputMaskModule,
    RoundProgressModule,
    TimelineModule,
    RadioButtonModule,
    DropdownModule,
    SkeletonModule,
    InputTextareaModule,
    PanelModule,
    SidebarModule,
    MultiSelectModule,
    CalendarModule,
    NgxDropzoneModule,
    AccordionModule,
    TableModule,
    BadgeModule,
    InplaceModule,
    BreadcrumbModule,
    ConfirmPopupModule,
    ButtonModule,
    ChipModule,
    MenuModule,
    ChipsModule,
    AvatarModule,
    MenubarModule,
    TooltipModule,
    PasswordModule,
    InputNumberModule,
    NgDynamicBreadcrumbModule,
    PdfViewerModule,
    ConfirmDialogModule,
    OtpVarificationComponent,
    RegistrationSuccessfulComponent,
    InterviewOfflineComponent,
    LoginOtpVerificationComponent,
    CalendarInviteComponent
  ],
  providers: []
})
export class SharedModule { }
