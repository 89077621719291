import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { JobServicesService } from 'src/app/services/job-services.service';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { ConfirmationService, MessageService } from 'primeng/api';

interface AllStages {
  name: string,
  value: number
}

@Component({
  selector: 'app-workflow-ruleset',
  templateUrl: './workflow-ruleset.component.html',
  styleUrls: ['./workflow-ruleset.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class WorkflowRulesetComponent implements OnInit, OnChanges {

  @Input() stage: any;
  @Input() index: any;
  @Input() workflowId: any;
  @Input() isTestSelected: any;
  @Input() statusSettings: any;

  @Output() ruleToRemove = new EventEmitter<any>();
  @Output() ruleToUpdate = new EventEmitter<any>();

  stageTriggerOptions: any = [];
  stageActionOptions: any = [];
  workflowRulesetList: Array<string> = [];
  savedWorkflowIds: Array<string> = [];

  sendMailTemplate: any = [];
  specificConditions: any = [];
  specificOperators: any = [];
  actionsList: Array<any> = [];
  elseActionsList: Array<any> = [];
  conditionsList: Array<any> = [];
  logicOperator: Array<any> = [];
  stringOperators: Array<any> = [];
  numberOperators: Array<any> = [];
  booleanOperators: Array<any> = [];
  arrayOperators: Array<any> = [];
  allStages: Array<AllStages> = []
  stageDetails: any = [];
  triggerFields: Array<any> = [];

  workflow: any = [];
  workflowSummary: any = [];
  dataProps: any = [];

  selectedTrigger: any = null;
  selectedAction: any;
  selectedActionTemplate: string = '';
  stageCondition: string = 'for_all';
  logicCondition: string = 'AND';
  jobId: string = '';

  showSummary: boolean = false;
  loadingButton: boolean = false;
  loadActionOptions: boolean = false;
  stageError: boolean = false;
  pageLoading: boolean = false;
  summaryLoading: boolean = false;
  loadConditionOptions: boolean = false;
  loadingSummary: boolean = false;
  displayAction: boolean = true
  displayElseAction: boolean = false;
  companyEmail: any;

  objectKeys = Object.keys;

  constructor(
    private jobServices: JobServicesService,
    private emailServices: EmailServicesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.numberOperators = [
      { sign: '==', clause: 'IS_EQUAL_TO' },
      { sign: '!=', clause: 'IS_NOT_EQUAL_TO' },
      { sign: '>=', clause: 'IS_GREATER_THAN_OR_EQUAL_TO' },
      { sign: '<=', clause: 'IS_LESS_THAN_OR_EQUAL_TO' },
      { sign: '>', clause: 'IS_GREATER_THAN' },
      { sign: '<', clause: 'IS_LESS_THAN' },
    ]

    this.stringOperators = [
      { sign: '==', clause: 'IS_EQUAL_TO' },
      { sign: '!=', clause: 'IS_NOT_EQUAL_TO' },
      { sign: 'Starts with', clause: 'IS_STARTS_WITH' },
      { sign: 'Ends with', clause: 'IS_ENDS_WITH' },
      { sign: 'Contains', clause: 'IS_CONTAINS' },
      { sign: 'Not Contains', clause: 'DOES_NOT_CONTAIN' },
      { sign: 'Of Length', clause: 'IS_OF_LENGTH' },
    ]

    this.booleanOperators = [
      { sign: '==', clause: 'IS_EQUAL_TO' },
      { sign: '!=', clause: 'IS_NOT_EQUAL_TO' },
    ]

    this.arrayOperators = [
      { sign: '>', clause: 'IS_GREATER_THAN' },
      { sign: '<', clause: 'IS_LESS_THAN' },
      { sign: 'In', clause: 'IS_IN' },
      { sign: 'Not In', clause: 'IS_NOT_IN' },
      { sign: 'Empty', clause: 'IS_EMPTY' },
      { sign: 'Not Empty', clause: 'IS_NOT_EMPTY' },
    ]

    this.dataProps = {
      "fieldMappings": []
    }

  }

  ngOnInit() {
    this.companyEmail = localStorage.getItem('schoolDetails')
    ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].senderEmailId ?? null
    : null;
    this.jobId = window.localStorage.getItem('selectedJobId') || '';
    this.stageActionOptions = this.stage.actions;
    this.workflowRulesetList.length = 1;

    this.getAllStages();

    // Set Trigger Options
    this.stageTriggerOptions = this.setTriggerOptions(this.stage);

    // Get Email Templates
    this.emailServices.getTemplates('').subscribe((response: any) => {
      let templates = response.data ? response.data.filter((val: any) => val.category === 'Candidates') : [];
      this.sendMailTemplate = templates.map((item: any) => ({ name: item.templateTitle, value: item.templateContent, isEditable: item.isEditable }))
    })

    // Checking for existing workflow
    this.jobServices.getStage(this.jobId, this.stage.id).subscribe(res => {
      this.savedWorkflowIds = res?.data?.workflowId ? res.data.workflowId : [];

      if (this.savedWorkflowIds.includes(this.workflowId)) {
        this.getWorkflowSummary();
      }
      else {
        this.actionsList = [{
          actions: this.stageActionOptions, template: [], values: {
            actionName: '',
            actionTemplate: '',
            actionTemplateContent: '',
            dataProps: this.dataProps
          }
        }]

        this.elseActionsList = [{
          actions: this.stageActionOptions, template: [], values: {
            actionName: '',
            actionTemplate: '',
            actionTemplateContent: '',
            dataProps: this.dataProps
          }
        }]

        this.conditionsList = [{
          specific: this.specificConditions, operator: [],
          values: {
            conditionSource: { name: '', inputType: '', options: [] },
            conditionClause: '',
            conditionValue: { value: '', type: '' },
          }
        }]
      }
    })
    this.logicOperator.length = Math.max(0, this.conditionsList.length - 1);
  }

  ngOnChanges(): void {
    if (this.jobId) {
      this.getAllStages();
    }
  }

  getAllStages = (): any => {
    this.jobServices.getAllStages(this.jobId).subscribe(res => {
      this.stageDetails = res.data;
      let stages = this.stageDetails.map((val: any) => { return { name: val.stageName, value: val.stageId } });
      this.allStages = stages.filter((stage: any) => stage.value !== this.stage.stageId);
    })
  }

  getTriggers = (trigger: string) => {
    this.specificConditions = [];
    this.loadConditionOptions = true;
    this.jobServices.getTrigger(trigger).subscribe(res => {
      this.triggerFields = res.data.triggerFields;
      this.triggerFields.forEach((field: any) => {
        this.specificConditions.push({ name: field.fieldName, inputType: field.fieldType, options: field?.fieldOptions ? field.fieldOptions : [] });
      })
      this.conditionsList.forEach(condition => {
        condition.specific = this.specificConditions;
      });
      this.loadConditionOptions = false;
    })
  }

  getWorkflowSummary = () => {
    this.loadingSummary = true;
    this.showSummary = true;
    this.jobServices.getWorkflow(this.workflowId).subscribe(res => {
      this.workflow = res?.data ? res.data : [];
      this.setWorkflowSummary();
      this.populateOnEdit();

    })
    this.loadingSummary = false;
  }

  setWorkflowSummary = () => {
    let _actions: any = [];
    let _elseActions: any = [];
    let _conditionSet: any = [];

    // Action For Pass Condition
    this.workflow.targetActions.pass.map((action: any) => {
      let _template: any = { name: '', value: '' };
      switch (action.actionEventId) {
        case "sendMail":
        case "sendTestLink":
        case "sendDocumentForm": {
          action.dataProps.fieldMappings.map((field: any) => {
            field.fieldName === 'subject' && (_template.name = field.value);
            field.fieldName === 'data' && (_template.value = field.value);
          })
          break;
        }
        case "moveStage": {
          let stageName = this.allStages.filter((stage: any) => stage.value === action.dataProps.fieldMappings[0].value);
          _template = { name: stageName[0]?.name, value: action.dataProps.fieldMappings[0].value };
        }
          break;
      }
      let _actionItem:any = { name: action.name, actionEventId: action.actionEventId, template: _template, dataProps: action.dataProps };
      if(action?.statusName){
        _actionItem.statusValue = action.statusName;
        _actionItem.name = `${this.statusSettings[this.stage?.type?.length ? this.stage.type : 'Eligibility'][action.statusName]} Candidate`;
        if(this.stage.stageId != 1 && this.stage?.type?.length == 0){
          _actionItem.name = action.name;
        }
      }
      _actions.push(_actionItem);
    })

    // Action For Fail Condition
    if (this.workflow.targetActions.fail.length) {
      this.workflow.targetActions.fail.map((action: any) => {
        let _template: any = { name: '', value: '' };
        switch (action.actionEventId) {
          case "sendMail":
          case "sendTestLink":
          case "sendDocumentForm": {
            action.dataProps.fieldMappings.map((field: any) => {
              field.fieldName === 'subject' && (_template.name = field.value);
              field.fieldName === 'data' && (_template.value = field.value);
            })
            break;
          }
          case "moveStage": {
            let stageName = this.allStages.filter((stage: any) => stage.value === action.dataProps.fieldMappings[0].value);
            _template = { name: stageName[0]?.name, value: action.dataProps.fieldMappings[0].value };
          }
            break;
        }
        let _elseActionItem: any = { name: action.name, actionEventId: action.actionEventId, template: _template, dataProps: action.dataProps };
        if(action?.statusName){
          _elseActionItem.statusValue = action.statusName;
          _elseActionItem.name = `${this.statusSettings[this.stage?.type?.length ? this.stage.type : 'Eligibility'][action.statusName]} Candidate`;
          if(this.stage.stageId != 1 && this.stage?.type?.length == 0){
            _elseActionItem.name = action.name;
          }
        }
        _elseActions.push(_elseActionItem)
      })
    }

    // Matching Conditions
    this.workflow.filter.conditionSets.map((val: any) => {
      val.conditions.map((condition: any) => {
        let _clause = {}
        this.chooseOperatorList(condition.matchValue.type).forEach((opr: any) => { if (opr.clause === condition.clause) _clause = { clause: opr.clause, sign: opr.sign } })
        _conditionSet.push({ property: condition.sourceProperty, clause: _clause, value: condition.matchValue })
      })
    })

    this.workflowSummary = [{
      trigger: this.workflow.trigger.name,
      filter: {
        conditionApply: this.workflow.filter.conditionApply === 'for_all' ? 'For All' : 'Matching Condition',
        conditionSet: _conditionSet,
      },
      actions: _actions,
      elseActions: _elseActions,
    }]
    if(this.workflow.trigger?.statusName){
      this.workflowSummary[0].trigger = `Candidate ${this.statusSettings[this.stage?.type?.length ? this.stage.type : 'Eligibility'][this.workflow.trigger.statusName]}`;

      if(this.stage?.type?.length == 0 && this.stage.stageId != 1){
        this.workflowSummary[0].trigger = this.workflow.trigger.name
      }
    }
  }

  populateOnEdit = () => {
    this.stageCondition = this.workflow.filter.conditionApply;
    this.logicCondition = this.workflow.filter.conditionSets[0].meets === 'all' ? 'AND' : 'OR';
    this.selectedTrigger = { name: this.workflowSummary[0].trigger, triggerId: this.workflow.trigger.triggerId };
    if(this.workflow?.trigger?.statusName){
      this.selectedTrigger.statusValue = this.workflow.trigger?.statusName;
    }
    this.getTriggers(this.workflow.trigger.triggerId);

    this.conditionsList = [];
    this.workflowSummary[0].filter.conditionSet.map(async (condition: any, index: number) => {
      this.logicOperator.length = index;
      let optionsData = await this.getConditionOptions(condition.property);
      this.conditionsList.push({
        specific: this.specificConditions, operator: this.chooseOperatorList(condition.value.type), values: {
          conditionSource: { name: condition.property, inputType: condition.value.type, options: optionsData ? optionsData : [] },
          conditionClause: condition.clause,
          conditionValue: condition.value,
        }
      })
    })

    this.actionsList = [];
    this.workflowSummary[0].actions.map((action: any) => { 
      let _actionTemplate = this.chooseTemplateOptions(action.actionEventId).filter((template: any) => template.name === action.template.name);
      let actionListItem:any = {
        actions: this.stageActionOptions, template: this.chooseTemplateOptions(action.actionEventId), values: {
          actionName: { name: action.name, actionEventId: action.actionEventId },
          actionTemplate: _actionTemplate[0],
          actionTemplateContent: action.template.value,
          dataProps: action.dataProps
        }
      }
      if(action?.statusValue){
        actionListItem.values.actionName.statusValue = action.statusValue;
      }
      this.actionsList.push(actionListItem)
    })

    if (this.stageCondition == 'matching_condition' && this.workflowSummary[0].elseActions.length) {
      this.elseActionsList = [];
      this.workflowSummary[0].elseActions.map((action: any) => {
        let _actionTemplate = this.chooseTemplateOptions(action.actionEventId).filter((template: any) => template.name === action.template.name);
        let elseActionListItem: any = {
          actions: this.stageActionOptions, template: this.chooseTemplateOptions(action.actionEventId), values: {
            actionName: { name: action.name, actionEventId: action.actionEventId },
            actionTemplate: _actionTemplate[0],
            actionTemplateContent: action.template.value,
            dataProps: action.dataProps
          }
        }
        if(action?.statusValue){
          elseActionListItem.values.actionName.statusValue = action.statusValue;
        }
        this.elseActionsList.push(elseActionListItem);
      })
    } else {
      this.elseActionsList = [{
        actions: this.stageActionOptions, template: [], values: {
          actionName: '',
          actionTemplate: '',
          actionTemplateContent: '',
          dataProps: this.dataProps
        }
      }]
    }
  }

  setTriggerOptions = (stage: any) => {
    switch (true) {
      case stage.stageId === 1: {
        let statusSettings = this.statusSettings['Eligibility'];
        return [
          { name: 'Candidate Form Submit', triggerId: 'onCandidateFormSubmit' },
          { name: 'Candidate Move To Event', triggerId: 'onCandidateMovedToEvent' },
          { name: `Candidate ${statusSettings['waitlisted']}`, triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted' },
        ];
      }
      case stage.type === 'Test': {
        let statusSettings = this.statusSettings[stage.type];
        return [
          { name: 'Candidate Move Stage', triggerId: 'onCandidateMoveStageToJob' },
          { name: `Candidate ${statusSettings['noShow']}`, triggerId: 'onCandidateNoShow', statusValue: 'noShow' },
          { name: 'Candidate Test Submit', triggerId: 'onCandidateTestSubmit' },
          { name: `Candidate ${statusSettings['shortlisted']}`, triggerId: 'onCandidateShortlist', statusValue: 'shortlisted' },
          { name: `Candidate ${statusSettings['rejected']}`, triggerId: 'onCandidateReject', statusValue: 'rejected' },
          { name: `Candidate ${statusSettings['waitlisted']}`, triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted' },
        ]
      }
      case stage.type === 'OfflineInterview': 
      case stage.type === 'Interview': {
        let statusSettings = this.statusSettings[stage.type];
        return [
          { name: 'Candidate Feedback Submit', triggerId: 'onCandidateFeedbackSubmit' },
          { name: `Candidate ${statusSettings['noShow']}`, triggerId: 'onCandidateNoShow', statusValue: 'noShow' },
          { name: 'Candidate Move Stage', triggerId: 'onCandidateMoveStageToJob' },
          { name: `Candidate ${statusSettings['shortlisted']}`, triggerId: 'onCandidateShortlist', statusValue: 'shortlisted' },
          { name: `Candidate ${statusSettings['rejected']}`, triggerId: 'onCandidateReject', statusValue: 'rejected' },
          { name: `Candidate ${statusSettings['waitlisted']}`, triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted' },
        ]
      }
      case stage.type === 'OnBoarding': {
        let statusSettings = this.statusSettings[stage.type];
        return [
          { name: `Candidate ${statusSettings['joiningConfirmed']}`, triggerId: 'onCandidateJoiningConfirmed', statusValue: 'joiningConfirmed' },
          { name: `Candidate ${statusSettings['joined']}`, triggerId: 'onCandidateJoined', statusValue: 'joined' },
          { name: `Candidate ${statusSettings['notJoining']}`, triggerId: 'onCandidateNotJoining', statusValue: 'notJoining' },
          { name: `Candidate ${statusSettings['notJoined']}`, triggerId: 'onCandidateNotJoined', statusValue: 'notJoined' },
        ]
      }
      case stage.type === 'Screening': {
        let statusSettings = this.statusSettings[stage.type];
        return [
          // { name: 'Candidate Document Submit', triggerId: 'onCandidateDocumentFormSubmit' },
          // { name: `Candidate ${statusSettings['submittedPartially']}`, triggerId: 'onCandidatePartialSubmit', statusValue: 'submittedPartially' },
          // { name: `Candidate ${statusSettings['partiallyApproved']}`, triggerId: 'onCandidatePartialApproved', statusValue: 'partiallyApproved' },
          { name: 'Candidate Move Stage', triggerId: 'onCandidateMoveStageToJob' },
          { name: `Candidate ${statusSettings['shortlisted']}`, triggerId: 'onCandidateShortlist', statusValue: 'shortlisted' },
          { name: `Candidate ${statusSettings['rejected']}`, triggerId: 'onCandidateReject', statusValue: 'rejected' },
          { name: `Candidate ${statusSettings['waitlisted']}`, triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted' },
        ]
      }
      case stage.type === 'Offer': {
        let statusSettings = this.statusSettings[stage.type];
        return [
          { name: 'Candidate Move Stage', triggerId: 'onCandidateMoveStageToJob' },
          { name: `Candidate ${statusSettings['offerAccepted']}` , triggerId: 'onCandidateOfferAccepted', statusValue: 'offerAccepted' },
          { name: `Candidate ${statusSettings['offerDeclined']}` , triggerId: 'onCandidateOfferDeclined', statusValue: 'offerDeclined' },
          { name: `Candidate ${statusSettings['waitlisted']}` , triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted'  },
        ]
      }
      case stage.type === 'Others': {
        let statusSettings = this.statusSettings[stage.type];
        return [
          { name: 'Candidate Move Stage', triggerId: 'onCandidateMoveStageToJob' },
          { name: `Candidate ${statusSettings['shortlisted']}`, triggerId: 'onCandidateShortlist', statusValue: 'shortlisted' },
          { name: `Candidate ${statusSettings['rejected']}`, triggerId: 'onCandidateReject', statusValue: 'rejected' },
          { name: `Candidate ${statusSettings['waitlisted']}`, triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted' },
        ]
      }
      default: {
        let statusSettings = {
            "rejected": "Rejected",
            "pending": "Pending",
            "shortlisted": "Shortlisted",
            "waitlisted": "Waitlisted"
        }
        return [
          { name: 'Candidate Move Stage', triggerId: 'onCandidateMoveStageToJob' },
          { name: `Candidate ${statusSettings['shortlisted']}`, triggerId: 'onCandidateShortlist', statusValue: 'shortlisted' },
          { name: `Candidate ${statusSettings['rejected']}`, triggerId: 'onCandidateReject', statusValue: 'rejected' },
          { name: `Candidate ${statusSettings['waitlisted']}`, triggerId: 'onCandidateWaitlist', statusValue: 'waitlisted' },
        ]
      }
    }
  }

  addNewAction = () => {
    this.actionsList.push({
      actions: this.stageActionOptions, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
        dataProps: this.dataProps
      }
    })
  }

  addNewElseAction = () => {
    this.elseActionsList.push({
      actions: this.stageActionOptions, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
        dataProps: this.dataProps
      }
    })
  }

  removeAction = (action: any) => {
    this.actionsList = this.actionsList.filter((val: any) => val !== action)
  }

  removeElseAction = (action: any) => {
    this.elseActionsList = this.elseActionsList.filter((val: any) => val !== action)
  }

  addCondition = (condition: any) => {
    this.logicOperator.length += 1;
    this.conditionsList.splice(this.conditionsList.indexOf(condition) + 1, 0, {
      specific: this.specificConditions, operator: [], values: {
        conditionSource: { name: '', inputType: '', options: [] },
        conditionClause: '',
        conditionValue: { value: '', type: '' },
      }
    })
  }

  removeCondition = (condition: any) => {
    this.logicOperator.length -= 1;
    this.conditionsList = this.conditionsList.filter((val: any) => val !== condition)
  }

  clearRuleSet = () => {
    this.selectedTrigger = '';
    this.stageCondition = 'for_all';
    this.selectedAction = '';
    this.actionsList = [{
      actions: this.stageActionOptions, template: [], values: {
        actionName: '',
        actionTemplate: '',
        actionTemplateContent: '',
      }
    }]
  }

  selectOperatorOptions = (event: any, condition: any) => {
    if (event.value.inputType === "number" || event.value.inputType === "date") { condition.operator = this.numberOperators }
    if (event.value.inputType === "string") { condition.operator = this.stringOperators }
    if (event.value.inputType === "boolean") { condition.operator = this.booleanOperators }
    if (event.value.inputType === "array") { condition.operator = this.arrayOperators }
  }

  getConditionOptions = (condition: string): any => {
    return new Promise((resolve, reject) => {
      this.jobServices.getTrigger(this.workflow.trigger.triggerId).subscribe(res => {
        this.triggerFields = res.data.triggerFields;
        let fieldOptions: any = [];
        this.triggerFields.forEach((trigger: any) => {
          if (trigger.fieldName == condition) fieldOptions = trigger.fieldOptions;
        })
        return resolve(fieldOptions);
      })
    })
  }

  chooseOperatorList = (fieldType: string): Array<any> => {
    switch (fieldType) {
      case "string": return this.stringOperators;
      case "number": return this.numberOperators;
      case "boolean": return this.booleanOperators;
      case "array": return this.arrayOperators;
      case "date": return this.numberOperators;
      default: return []
    }
  }

  selectTemplateOptions = (actionEventId: any, action: any) => {
    action.values.actionTemplateContent = '';
    action.values.actionTemplate = '';
    switch (actionEventId) {
      case "moveStage": {
        this.loadActionOptions = true;
        this.jobServices.getAllStages(this.jobId).subscribe(res => {
          let stages = res.data.map((val: any) => { return { name: val.stageName, value: val.stageId } });
          this.allStages = stages.filter((stage: any) => stage.value > this.stage.stageId);
          action.template = this.allStages;
          this.loadActionOptions = false;
          this.stageError = this.allStages.length ? false : true;
        })
        break;
      }
      case "sendMail": action.template = this.sendMailTemplate; break;
      case "sendTestLink": action.template = this.sendMailTemplate; break;
      case "sendDocumentForm": action.template = this.sendMailTemplate; break;
      default: action.template = [];
    }
  }

  chooseTemplateOptions = (actionEventId: string): Array<any> => {
    switch (actionEventId) {
      case "sendMail": return this.sendMailTemplate;
      case "sendTestLink": return this.sendMailTemplate;
      case "moveStage": return this.allStages.filter((stage: any) => stage.value > this.stage.stageId);
      default: return [];
    }
  }

  setActionValues = (event: any, action: any) => {
    let sendMailDataProps = {
      fieldMappings: [
        {
          fieldName: "sender",
          value: this.companyEmail,
          fieldType: "string"
        },
        {
          fieldName: "receiver_email",
          fieldType: "array",
          value: 'email'
        },
        {
          fieldType: "string",
          fieldName: "subject",
          value: ""
        },
        {
          value: "",
          fieldName: "data",
          fieldType: "string"
        }
      ]
    }

    let destinationStatus = 'pending';
    if(action.actionName.actionEventId == 'moveStage'){
      let destinationStage: any = this.stageDetails.filter((stage: any) => stage.stageId == event.value)[0];
      switch(destinationStage.stageType){
        case 'Offer' : destinationStatus = 'offerPending'; break;
        case 'Screening' : destinationStatus = 'mailPending'; break;
        default: destinationStatus = 'pending';
      }
    }
    
    let moveStageDataProps = {
      fieldMappings: [
        {
          fieldName: "stageId",
          value: event.value,
          fieldType: "number"
        },
        {
          fieldName: "jobStatus",
          value: destinationStatus,
          fieldType: "string"
        }
      ]
    }

    switch (action.actionName.actionEventId) {
      case 'moveStage': {
        // moveStageDataProps = {
        //   fieldMappings: [
        //     {
        //       fieldName: "stageId",
        //       value: event.value,
        //       fieldType: "number"
        //     },
        //     {
        //       fieldName: "jobStatus",
        //       value: 'pending',
        //       fieldType: "string"
        //     }
        //   ]
        // }
        action.dataProps = moveStageDataProps;
      } break;
      case 'sendMail':
      case 'sendTestLink':
      case 'sendDocumentForm': {
        sendMailDataProps.fieldMappings.forEach((item: any) => {
          if (item.fieldName === 'subject') { item.value = event.name }
          if (item.fieldName === 'data') { item.value = event.value }
        })
        action.dataProps = sendMailDataProps;
        action.actionTemplateContent = action.actionTemplate.value;
      } break;
      default: action.dataProps = this.dataProps;
    }
  }

  removeRule = (workflowId: string, event: any) => {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.savedWorkflowIds.includes(workflowId)) {
          this.pageLoading = true;
          this.jobServices.deleteWorkflow(workflowId).subscribe(res => {
            this.pageLoading = false;
            if (res.data) this.messageService.add({ severity: 'info', summary: 'Deleted', detail: 'Workflow deleted' });
            if (!Object.keys(res.data).length) this.messageService.add({ severity: 'error', summary: 'Unable to delete', detail: 'Something went wrong!' });
            this.ruleToRemove.emit(workflowId);
          })
        } else
          this.ruleToRemove.emit(workflowId);
      },
      reject: () => { }
    });
  }

  saveWorkflow = () => {
    this.loadingButton = true;
    let _targetActions: any = [];
    let _actionEventIds: any = [];
    this.actionsList.forEach((action: any) => {
      let actionItem:any = { 
        name: action.values.actionName.name, 
        actionEventId: action.values.actionName.actionEventId, 
        dataProps: action.values.dataProps,
      }
      if(action.values?.actionName?.statusValue){
        actionItem.statusName = action.values?.actionName?.statusValue;
      }
      _targetActions.push(actionItem);
      _actionEventIds.push(action.values.actionName.actionEventId);
    })

    let _targetElseActions: any = [];
    let _elsActionEventIds: any = [];
    if (this.stageCondition != 'for_all' && this.elseActionsList[0].values.actionName?.name) {
      this.elseActionsList.forEach((action: any) => {
        let actionItem:any = { 
          name: action.values.actionName.name, 
          actionEventId: action.values.actionName.actionEventId, 
          dataProps: action.values.dataProps,
        }
        if(action.values?.actionName?.statusValue){
          actionItem.statusName = action.values?.actionName?.statusValue;
        }
        _targetElseActions.push(actionItem);
        _elsActionEventIds.push(action.values.actionName.actionEventId);
      })
    }

    let _conditions: any = [];
    this.conditionsList.forEach((condition: any) => {
      _conditions.push({
        sourceProperty: condition.values.conditionSource?.name,
        clause: condition.values.conditionClause.clause,
        // matchValue: condition.values.conditionSource.inputType == "number" ? { value: Number(condition.values.conditionValue.value), type: condition.values.conditionSource.inputType } : { value: condition.values.conditionValue.value, type: condition.values.conditionSource.inputType },
        matchValue: { value: this.correctValues(condition.values.conditionSource.inputType, condition.values.conditionSource?.name, condition.values.conditionValue.value), type: condition.values.conditionSource.inputType },
        sourceId: this.selectedTrigger.triggerId,
        rank: 1
      })
    })


    let payload = {
      "jobId": this.jobId,
      "eventId": this.selectedTrigger.triggerId,
      "trigger": {
        "name": this.selectedTrigger.name,
        "triggerId": this.selectedTrigger.triggerId,
        "statusName": this.selectedTrigger.statusValue,
        "eventData": {}
      },
      "targetActions": {
        "pass": _targetActions,
        "fail": _targetElseActions,
      },
      "filter": {
        "meets": 'any',
        "conditionApply": this.stageCondition,
        "outcome": {
          "pass": {
            "targetActionIds": _actionEventIds
          },
          "fail": {
            "targetActionIds": _elsActionEventIds
          }
        },
        "conditionSets": [
          {
            "meets": this.logicCondition === "AND" ? 'all' : 'any',
            "conditions": _conditions
          }
        ]
      }
    }
    if (this.savedWorkflowIds.includes(this.workflowId)) {
      this.jobServices.updateWorkflow(this.workflowId, payload).subscribe(() => {
        this.loadingButton = false;
        this.getWorkflowSummary();
      })
    } else {
      this.jobServices.saveWorkflow(this.stage.id, payload).subscribe(res => {
        this.workflowId = res?.data.id;
        this.loadingButton = false;
        this.ruleToUpdate.emit(this.workflowId);
        this.stage.workflowIds.forEach((id: string) => { if (id === '-') this.ruleToRemove.emit(id) });
        this.getWorkflowSummary();
      })
    }
  }

  correctValues = (type: string, sourceValue: string, value: any) => {
    switch (true) {
      case type == 'number': return Number(value);
      case sourceValue == 'maritalStatus': return value.toLowerCase();
      case type == 'boolean': {
        if (value == 'Yes' || value == 'yes') return true;
        else if (value == 'No' || value == 'no') return false;
        else return undefined;
      }
      default: return value;
    }
  }

  checkValidation = (stage: any) => {
    let inValid: boolean = false;
    if(stage.type == 'Test' && !this.isTestSelected){
      inValid = true;
    }
    else {
      if(this.stageCondition == 'matching_condition'){
        this.conditionsList.forEach((condition:any) => {
          if(!condition.values.conditionSource || !condition.values.conditionClause || !condition.values.conditionValue.value){
            inValid = true;
          }
        })
      }

      if(!this.selectedTrigger || !this.actionsList[0].values.actionName?.name){
        inValid = true;
      } 
      else {
        if(this.actionsList[0].template.length || this.elseActionsList[0].template.length){
          this.actionsList.forEach((action:any) => {
            if(action.template.length && !action.values.actionTemplate) inValid = true;
          })

          this.elseActionsList.forEach((action:any) => {
            if(action.template.length && !action.values.actionTemplate) inValid = true;
          })
        } 
      }
    }
    return inValid;
  }
}
