import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { MessageService } from 'primeng/api';
import { EventServicesService } from 'src/app/services/event-services.service';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-interview-offline',
  templateUrl: './interview-offline.component.html',
  styleUrls: ['./interview-offline.component.css']
})
export class InterviewOfflineComponent implements OnInit {

  isOfflineInterview: boolean = false;
  isLoading: boolean = false;
  userId: string = '';
  eventId: string = '';
  c_userName: string = '';
  schoolId: string = '';
  candidateName: string = '';
  candidateEmail: string = '';
  stageId: string = '';
  venue: string = '';
  paramItems: any = '';
  formName: string = '';
  calendarInvite: string = '';

  constructor(
      private eventServices: EventServicesService, 
      private activatedRoute: ActivatedRoute, 
      private globalService: GlobalServicesService, 
      private messageService: MessageService,
    ) { }

  ngOnInit(): void {
    if(window.location.pathname === '/feedback-submission'){
      this.isOfflineInterview = true;
    }

    this.activatedRoute.queryParamMap.subscribe((param: any) => {
      this.calendarInvite = param.get('calendarInvite')
      this.userId = param.get('userID')
      this.eventId = param.get('eventId')
      this.c_userName = param.get('c_userName')
      this.schoolId = param.get('schoolId')
      this.candidateName = param.get('candidateName')
      this.candidateEmail = param.get('candidateEmail')
      this.stageId = param.get('stageId')
      this.venue = param.get('venue')
      this.paramItems = param?.params;
    })
    this.getFeedbackFormName();
  }

  getFeedbackFormName() {
    this.isLoading = true;
    this.eventServices.getParticularStage(this.eventId, this.stageId).subscribe(res => {
      console.log(res);
      if (res.data?.scheduleInterview) {
        this.isLoading = false;
        this.formName = res.data.scheduleInterview.interviewType;
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'No Feedback Form Found' });
      }
    })
}

}
