import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { EmailServicesService } from 'src/app/services/email-services.service';
import { MessageService } from 'primeng/api';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { nanoid } from 'nanoid'
import { EventServicesService } from 'src/app/services/event-services.service';

@Component({
  selector: 'app-registration-successful',
  templateUrl: './registration-successful.component.html',
  styleUrls: ['./registration-successful.component.css'],
  providers: [MessageService]
})
export class RegistrationSuccessfulComponent implements OnInit {

  @Input() sendMailHeader: string = '';

  emails: any = [];
  schlDetails: any = localStorage.getItem('schoolDetails');
  bucketName: string = this.globalService.assetBucketName;
  s3Domain: string = 'https://s3.amazonaws.com';
  schoolId: string = '';
  eventId: string = '';
  cohortNameTerm: string = '';
  schoolData: any;
  cohortStatuses: any = {};
  selectedCohortStatus: any = {};
  jobLocationsList: Array<any> = []
  jobRolesList: Array<string> = []

  loader: boolean = false;
  clicked: boolean = false;
  uploadingFile: boolean = false;
  templatesList: any = [];
  eventUsers: any = [];
  selectedLocation: string[] = []; 
  // attachmentFile: File[] = [];
  attachmentFile: any;

  sendEmailSlider: boolean = false;
  sendEmailForm: FormGroup = new FormGroup({
    templateId: new FormControl(null, [Validators.required]),
    subjectTitle: new FormControl('', [Validators.required]),
    templateContent: new FormControl('', [Validators.required]),
  })

  registrationSuccessTemplateId: any = null;
  isLoading: boolean = false;

  constructor(
    public globalService: GlobalServicesService,
    public emailService: EmailServicesService,
    public messageService: MessageService,
  ) {
    this.jobLocationsList = [
      { name : 'Ahmedabad', value : 'Ahmedabad'},
      { name : 'Bangalore', value : 'Bangalore'},
      { name : 'Chennai', value : 'Chennai'},
      { name : 'Delhi NCR', value : 'Delhi NCR'},
      { name : 'Gurugram', value : 'Gurugram'},
      { name : 'Hyderabad', value : 'Hyderabad'},
      { name : 'Mumbai', value : 'Mumbai'},
      { name : 'Noida', value : 'Noida'},
      { name : 'Pune', value : 'Pune'}
    ]
    this.jobRolesList = ['Software', 'Production']
  }

  ngOnInit() {
    this.isLoading = true;
    this.registrationSuccessTemplateId = null;
    this.globalService.getSchoolDetails().subscribe((schoolDetails: any) => {
      if (schoolDetails && schoolDetails.data) {
        if(Array.isArray(schoolDetails.data)){
          let response = schoolDetails.data[0];
          this.registrationSuccessTemplateId = response?.emailTemplateIdConfig?.registrationSuccessful ?? null;
          this.fetchCandidateTemplate();
          console.log("this.registrationSuccessTemplateId ->", this.registrationSuccessTemplateId);
          if(this.registrationSuccessTemplateId)
          {
            this.sendEmailForm.controls['templateId'].setValue(this.registrationSuccessTemplateId);
          }
        }
      }
    })
    this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';
    this.schoolId = this.schoolData.school_id;
    this.eventId = window.localStorage.getItem('selectedEventId') || '';
  }

  emailWrite() {
    this.clicked = false;
    this.templatesList.forEach((each: any)=>{
      if(each.id === this.sendEmailForm.value.templateId)
      {
        this.sendEmailForm.controls['subjectTitle'].setValue(each.subjectTitle);
        this.sendEmailForm.controls['templateContent'].setValue(each.templateContent);
      }
    })
  }

  fetchCandidateTemplate()
  {
    this.emailService.getTemplates('').subscribe((out: any) => {
      this.isLoading = false;
      this.templatesList = [];
      let responseData = out.data;
      this.templatesList = responseData.filter((each: any)=> each.category === 'Candidates');
      console.log("this.templatesList ->",this.templatesList)
      this.templatesList.forEach((each: any)=>{
        if(each.id === this.registrationSuccessTemplateId)
        {
          this.sendEmailForm.controls['subjectTitle'].setValue(each.subjectTitle);
          this.sendEmailForm.controls['templateContent'].setValue(each.templateContent);
        }
      })
    })
  }

  submit()
  {
    this.clicked = true;
    console.log('this.sendEmailForm ->', this.sendEmailForm.value);
    if(this.sendEmailForm.valid)
    {
      this.clicked = false;
      this.loader = true;
      const payload = {
        registrationSuccessful : this.sendEmailForm.value.templateId
      }
      this.globalService.updateEmailConfig(payload).subscribe((res)=>{
        this.loader = false;
        if(res.status === 200)
        {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Registration Successful Mail Saved' });
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something went wrong!'});
        }
        console.log("response ->", res.data)
      })
    }
  }

}