<div [ngStyle]="{'visibility':showEditor ? 'visible' : 'hidden'}">
  <div class="job-add-header">
    <div class="job-heading">Invite Candidate</div>
  </div>
  <p-divider class="p-divider"></p-divider>
  <div class="candidateForm">
    <div>
      <span class="radioButtons">
        <p-radioButton value="single-invite" [(ngModel)]="_selectedCategory">
        </p-radioButton><label class="radioBtn" for="single-invite">Single-Invite</label>
        <p-radioButton value="bulk-invite" [(ngModel)]="_selectedCategory">
        </p-radioButton><label class="radioBtn" for="bulk-invite">Bulk-Invites</label>
      </span>
      <form *ngIf="_selectedCategory == 'single-invite'" class="departmentForm" [formGroup]="inviteForm">
        <div>
          <span class="p-float-label templateForm-elements input-field" style="margin-top: 40px;">
            <input type="text" id="first-name" pInputText formControlName="firstName"
              [ngClass]="{'p-filled': inviteForm.value.firstName}">
            <label for="first-name">First Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="last-name" pInputText formControlName="lastName"
              [ngClass]="{'p-filled': inviteForm.value.lastName}">
            <label for="last-name">Last Name <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="emailID" pInputText formControlName="emailID"
              [ngClass]="{'p-filled': inviteForm.value.emailId}">
            <label for="emailID">Email ID <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <span class="p-float-label templateForm-elements input-field">
            <input type="text" id="first-name" pInputText formControlName="source"
              [ngClass]="{'p-filled': inviteForm.value.source}">
            <label for="emailID">Source Tag <span [style]="{color: '#E24A47'}">*</span></label>
          </span>
          <!-- <span class="field-checkbox templateForm-elements input-field" style="margin-top: 30px;">
            <p-checkbox formControlName="nonTechnical" [binary]="true" inputId="binary"></p-checkbox>
            <label for="binary">Non-Technical</label>
          </span> -->
          <div class="email-template_container">
            <div>
              <span class="p-float-label emailForm-elements email-template">
                <p-dropdown [ngClass]="templatesList.length === 0 ? 'disable-input_field' : ''" [readonly]="templatesList.length === 0" class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true" filterBy="templateTitle" optionLabel="templateTitle" optionValue="id"
                formControlName="templateId" [options]="templatesList" (onChange)="emailWriteSingle()">
                </p-dropdown>
                <label for="inputtext">Email Template <span class="required-input">*</span></label>
              </span>
              <span class="error-placeholder p-error" *ngIf="errorPlaceholderSingle.length">
                Please select the template with the following placeholder :
                <div *ngFor="let data of errorPlaceholderSingle; let i = index">
                    <span>{{i+1 }}. {{data}}</span>
                </div>
            </span>
            </div>
          <div *ngIf="inviteForm.value.subjectTitle?.length">
            <!-- <span class="p-float-label templateForm-elements input-field">
              <input type="text" id="first-name" pInputText formControlName="source"
                [ngClass]="{'p-filled': inviteForm.value.source}">
              <label for="emailID">Source Tag <span [style]="{color: '#E24A47'}">*</span></label>
            </span> -->
            <span class="p-float-label emailForm-elements">
              <input [readOnly]="true" class="disable-input_field" type="text" name="Subject :" id="inputtext" pInputText  formControlName="subjectTitle"
              [ngClass]="{'invalid': clicked && inviteForm.controls['subjectTitle'].errors}">
              <label for="inputtext">Subject</label>
              <!-- <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('subjectTitle')" name="Subject">
              </app-handle-error> -->
            </span>
            <!-- <form>
              <div style="display: flex; justify-content:center; align-items:center" *ngIf="loadingEditor">
                <p-progressSpinner id="progress" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                  animationDuration="0.5s">
                </p-progressSpinner>
              </div>
              <textarea placeholder="Body" id="content"></textarea>
            </form> -->
            <div class="email-preview">
              <div [innerHTML]="inviteForm.value.emailContent">
              </div>   
            </div>
          </div>
          </div>
          <span class="radioButtons templateForm-elements input-field">
            <label [style]="{fontSize: '14px', fontWeight: 'var(--fw-semi-bold)'}">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="technical">Technical</label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form%20Non-Technical" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="non-technical">Non-Technical</label>
          </span>
          <div class="send-invite_btn">
            <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite"
              class="p-button-outlined button-align invite-btn" [disabled]="inviteForm.invalid"
              [ngClass]="{'valid': inviteForm.valid, 'invalid' : !inviteForm.valid}"
              [loading]="sendingSingleInvite"></button>
          </div>
        </div>
      </form>

      <form *ngIf="_selectedCategory == 'bulk-invite'" [formGroup]="bulkInviteForm">
        <div>
          <p class="template-download-label">Download Template to Invite Candidates</p>
          <div>
            <div class="template-download">
              <div class="template-file_info">
                <p class="pi pi-file-excel template-file-icon"></p>
                <p class="file-name">Sample_Bulk_Invite.xlsx</p>
              </div>
              <p (click)="sampleData()" class="template-download-icon"><em class="pi pi-arrow-down"></em></p>
            </div>
          </div>
          <p class="template-upload-label">Upload File to Invite Bulk Candidates</p>
          <div class="profilePhoto form-details">
            <div *ngIf="!cohortUploaded">
              <div class="bulkUpload-dropzone" ngx-dropzone
              [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
              [multiple]="false" (change)="cohortUpload($event)">
                  <ngx-dropzone-label *ngIf="!uploadingFile">
                      <div class="dropzone-label">
                          <em class="pi pi-cloud-upload"></em>
                          <p class="upload-text">Click to <span [style]="{color: 'var(--fc-primary)'}">upload</span>
                              or
                              Drag & Drop</p>
                          <p class="format-text">.XLS, .XLSX Formate only</p>
                      </div>
                  </ngx-dropzone-label>
                  <div *ngIf="uploadingFile">
                      <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
                      </p-progressSpinner>
                  </div>
              </div>
          </div>
          <div *ngIf="cohortUploaded">
            <div class="flex-dev uploaded-container" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
                <div class="bulk-main" [style]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
                    <div class="file-icon" [style]="{display: 'flex', justifyContent: 'center', alignItems: 'center',
                        background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', width: '40px', height: '40px',marginRight: '15px'}">
                            <p class="pi pi-file template-file-icon" [style]="{fontSize : '24px',display: 'flex',alignItems: 'center', color: 'blue'}"></p>
                    </div>
                    <div class="cohort-file_info" [style]="{marginRight: '15px'}">
                        <p class="file-name">{{cohortFile[0].file[0].name}}</p>
                        <p class="file-name" [style]="{color: '#AAAAAA'}">{{cohortFileSize}}</p>
                    </div>
                    <div class="cancel" (click)="onRemoveExcel()">
                        <p class="cohort-cancel-icon">
                        <em class=" report-download pi pi-times" [style]="{fontSize: '14px', fontWeight: 'bold', color: '#d5d5d5', 
                        display: 'flex', alignItems: 'center', justifyContent: 'center'}"></em></p>
                    </div>
                </div>
            </div>
          </div>
          <div *ngIf="countDiv" class="records-container" [style]="{background: 'rgba(52, 86, 255, 0.03)',borderRadius: '4px', 
                    width: '303px', height: '40px', marginTop : '25px', marginBottom : '25px', display: 'flex', alignItems: 'center'}">
                    <div class="records" [style]="{width: '100%', display: 'flex',justifyContent: 'space-evenly', alignItems : 'center'}">
                        <div class="total" [style]="{color: '#000000', fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                            <label class="file-name">Total records :
                              <span class="total-mark"> {{counts?.total}} </span>
                            </label>
                        </div>
                        <div class="correct" [style]="{fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                            <label class="file-name" [style]="{color: '#008000'}">Correct :
                              <span class="total-mark" [style]="{color: '#000000'}"> {{counts?.validated}} </span>
                            </label>
                        </div>
                        <div class="totalErrors" [style]="{fontWeight: 500, fontSize: '12px', marginRight: '15px'}">
                            <label class="file-name" [style]="{color: '#FF5E5B'}">Error :
                              <span class="total-mark" [style]="{color: '#000000'}"> {{counts?.errored}} </span>
                            </label>
                        </div>
                    </div>
            </div>
            <div *ngIf="messageEnable" class="notes">
              <label class="heading" [style]="{color: '#000000',fontWeight: 'bold', fontSize: '14px'}">Note : 
                <span class="total-mark" [style]="{fontWeight: 'lighter'}" *ngIf="counts?.errored > 0">
                  Kindly resolve or remove all the error in the main file to proceed.
                  <span [style]="{color: '#3456FF', cursor: 'pointer'}" (click)="errorReportDownload()">Download Error Report </span>
                </span>
              </label>
            </div>  
          <span class="p-float-label jobForm-elements" style="margin-top: 20px; margin-bottom: 0px;">
            <input formControlName="sourceTag" [ngClass]="{ 'p-filled': bulkInviteForm.value.sourceTag}" style="width: 100%"
              type="text" id="inputtext" pInputText />
            <label for="inputtext">Source Tag <span class="required-input">*</span></label>
          </span>
          <div class="email-template_container">
            <div>
              <span class="p-float-label emailForm-elements email-template">
                <p-dropdown [ngClass]="templatesList.length === 0 ? 'disable-input_field' : ''" [readonly]="templatesList.length === 0" class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true" filterBy="templateTitle" optionLabel="templateTitle" optionValue="id"
                formControlName="templateId" [options]="templatesList" (onChange)="emailWriteBulk()">
                </p-dropdown>
                <label for="inputtext">Email Template <span class="required-input">*</span></label>
              </span>
              <span class="error-placeholder p-error" *ngIf="errorPlaceholderBulk.length">
                Please select the template with the following placeholder :
                <div *ngFor="let data of errorPlaceholderBulk; let i = index">
                    <span>{{i+1 }}. {{data}}</span>
                </div>
            </span>
            </div>
          <div *ngIf="bulkInviteForm.value.subjectTitle?.length">
            <!-- <span class="p-float-label templateForm-elements input-field">
              <input type="text" id="first-name" pInputText formControlName="source"
                [ngClass]="{'p-filled': inviteForm.value.source}">
              <label for="emailID">Source Tag <span [style]="{color: '#E24A47'}">*</span></label>
            </span> -->
            <span class="p-float-label emailForm-elements">
              <input [readOnly]="true" class="disable-input_field" type="text" name="Subject :" id="inputtext" pInputText  formControlName="subjectTitle"
              [ngClass]="{'invalid': clicked && bulkInviteForm.controls['subjectTitle'].errors}">
              <label for="inputtext">Subject</label>
              <!-- <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('subjectTitle')" name="Subject">
              </app-handle-error> -->
            </span>
            <!-- <form>
              <div style="display: flex; justify-content:center; align-items:center" *ngIf="loadingEditor">
                <p-progressSpinner id="progress" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                  animationDuration="0.5s">
                </p-progressSpinner>
              </div>
              <textarea placeholder="Body" id="content"></textarea>
            </form> -->
            <div class="email-preview">
              <div [innerHTML]="bulkInviteForm.value.emailContent">
              </div>   
            </div>
          </div>
          </div>
          <span class="radioButtons templateForm-elements input-field" style="margin-top: 25px;">
            <label [style]="{fontSize: '14px', fontWeight: 'var(--fw-semi-bold)'}">Event Category <span [style]="{color: '#E24A47'}">*</span></label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="technical">Technical</label>
            <p-radioButton (onClick)="setFormLink()" value="registration-form?form=Registration%20Form%20Non-Technical" formControlName="eventCategory">
            </p-radioButton><label class="radioBtn" for="non-technical">Non-Technical</label>
          </span>
          <div class="send-invite_btn" style="margin-top: 20px;">
            <button pButton pRipple type="submit" (click)="sendInvite()" label="Send Invite"
              class="p-button-outlined button-align invite-btn" [disabled]="bulkInviteForm.invalid && !cohortUploaded"
              [ngClass]="{'valid': bulkInviteForm.valid && cohortUploaded, 'invalid' : !bulkInviteForm.valid}"
              [ngStyle]="{'pointer-events' :!bulkInviteForm.valid && !cohortUploaded && invalidFile ? 'none' : 'unset'}"
              [loading]="sendingBulkInvite"></button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
  <p-toast></p-toast>
</div>