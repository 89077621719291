import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;


  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      Authorization: localStorage.getItem('token') ?? '', 
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  //Company API
  getCompanyData(): Observable<any> {
    return this.http.get(this.api_url + '/company-details/', this.options());
  }
  updateCompanyData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-details/' + id ,payload, this.options());
  }

  //Primary API
  updateAccount(payload: any): Observable<any>{
    return this.http.put(this.api_url + '/primaryAccount/update/details',payload, this.options());
  }

  //Branch API
  getBranchData(): Observable<any> {
    return this.http.get(this.api_url + '/company-branches/', this.options());
  }

  addBranchData(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/company-branches/addCompanyBranches',payload, this.options());
  }

  updateBranchData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-branches/updateCompanyBranches/' + id ,payload, this.options());
  }

  deleteBranchData(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/company-branches/deleteCompanyBranches/' + id , this.options());
  }

  //Departments API
  getAllDepartmentData(): Observable<any> {
    return this.http.get(this.api_url + '/company-departments/', this.options());
  }

  getDepartmentData(id: string): Observable<any> {
    return this.http.get(this.api_url + '/company-departments/' + id, this.options());
  }

  updateDepartmentData(id: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-departments/' + id ,payload, this.options());
  }

  deleteDepartmentData(id: string): Observable<any> {
    return this.http.delete(this.api_url + '/company-departments/' + id, this.options());
  }

  addDepartmentData(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/company-departments/', payload, this.options());
  }

  dateTimeUpdate(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/schools/date-settings' , payload, this.options());
  }

  //Email
  currentEmail(userId: any, oldEmailId: any): Observable<any> {
    return this.http.post(this.api_url + '/change-email/' + userId, oldEmailId, this.options());
  }

  currentEmailVerify(payload: any):Observable<any> {
    return this.http.put(this.api_url + '/change-email/verify/', payload, this.options());
  }

  newEmail(userId: any, newEmailId: any): Observable<any> {
    return this.http.put(this.api_url + '/change-email/newmail/' + userId, newEmailId, this.options());
  }

  newEmailVerify(payload: any, userId: any): Observable<any> {
    return this.http.put(this.api_url + '/change-email/newOTP/' + userId, payload, this.options());
  }

  getStageStatusData(defaultLabel:boolean = false): Observable<any> {
    return this.http.get(this.api_url + `/stageStatus/getStageAllStatus?defaultLabel=${defaultLabel ? 'true' : ''}` , this.options());
  }

  saveStageStatus(payload:any): Observable<any> {
    return this.http.post(this.api_url + '/stageStatus/editStageStatus', payload, this.options());
  }

  oAuthLogin():Observable<any> {
    return this.http.get('https://login.microsoftonline.com/0f56a52d-fbe0-408f-8102-d7e194df2fa7/oauth2/v2.0/authorize?client_id=1c864b19-c828-401f-92db-be47d346fe2b&response_type=code&redirect_uri=http://localhost:4200&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2F.default&state=12345')
  }
}
