import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  formBuilder_api_url = environment.HOST.FORMBUILDER_API_URL;
  bulk_api_url = environment.HOST.BULK_INVITE;

  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      schoolid: schoolId,
    });
    return { headers }
  }

  constructor(private http: HttpClient) { }

  getTemplate(): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/formBuilder/getFormTemplate', this.options());
  }

  saveUser(payload: any, formType: string, eventId: any, eventTitle: any, conditionOption: any, sourceTag: any): Observable<any> {
    if(eventId && eventTitle)
    {
      if(formType === 'P2' && conditionOption.email && conditionOption.mobile_number && conditionOption.countryCode)
      {
        return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}&countryCode=${conditionOption.countryCode}&mobile_number=${conditionOption.mobile_number}&email=${conditionOption.email}`, payload, this.options());
      }
      else
      {
        if(sourceTag)
        {
          return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}&sourceTag=${sourceTag}`, payload, this.options());
        }
        else
        {
          return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}?eventId=${eventId}&eventTitle=${eventTitle}`, payload, this.options());
        }
      }
    }
    else
    {
      if(sourceTag)
      {
        return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}/?sourceTag=${sourceTag}`, payload, this.options());
      }
      else
      {
        return this.http.post(this.formBuilder_api_url + `/formBuilder/addFormDetails/${formType}`, payload, this.options());
      }
    }
    
  }

  saveOffer(payload: any, jobUserMappingId: string): Observable<any> {
    return this.http.post(this.formBuilder_api_url + '/offerForm/addFormDetails/?jobUserMappingId=' + jobUserMappingId, payload, this.options());
  }

  saveFeedback(payload: any, jobId: string, stageId: number, userId: string): Observable<any> {
    return this.http.post(this.formBuilder_api_url + '/userFeedback/?eventJobId=' + jobId + '&stageId=' + stageId + '&userId=' + userId, payload, this.options());
  }

  getFeedbackform(jobId: string, stageId: number, userId: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/userFeedback/getData/?eventJobId=' + jobId + '&stageId=' + stageId + '&userId=' + userId , this.options());
  }

  updateFeedback(payload: any, jobId: string, stageId: number, userId: string): Observable<any>{
    return this.http.put(this.formBuilder_api_url + '/userFeedback/updateData?eventJobId=' + jobId + '&stageId=' + stageId + '&userId=' + userId , payload, this.options());
  }

  getAllFeedbackform(jobId: string, userId: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/userFeedback/getData/?eventJobId=' + jobId + '&userId=' + userId , this.options());
  }

  getCandidateFormDetails(userId: string, formName?: string): Observable<any> {
    return this.http.get(this.formBuilder_api_url + '/formBuilder/getCandidateFormDetails/' + userId + `${formName ? '?formName='+formName : ''}`, this.options());
  }

  editFormDetails(payload: any, id: any): Observable<any>{
    return this.http.put(this.formBuilder_api_url + '/formBuilder/editFormDetails/' + id , payload, this.options());
  }

  getInviteData(id: any): Observable<any>{
    return this.http.get(this.bulk_api_url + '/invite/candidates/' + id, this.options());
  }
}
