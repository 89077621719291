import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ManageSlotService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;


  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Origin: window.location.origin,
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  // SLOTS API
  getAllSlots(jobId: string, pageNumber?: number, pageSize?: number): Observable<any> {
    return this.http.get(this.api_url + '/slots/getAll/' + jobId + '?pageSize=' + pageSize + '&pageNumber=' + pageNumber, this.options());
  }

  createSlot(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/slots/', payload, this.options());
  }

  getSlotById(slotId: string): Observable<any> {
    return this.http.get(this.api_url + '/slots/' + slotId, this.options());
  }

  editSlot(slotId: string, payload: any): Observable<any> {
    return this.http.put(this.api_url + '/slots/update/' + slotId, payload, this.options());
  }

  deleteSlot(slotId: string): Observable<any> {
    return this.http.delete(this.api_url + '/slots/' + slotId, this.options());
  }
}
