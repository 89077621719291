<!-- <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,500&display=swap"
    rel="stylesheet"> -->
<div class="registration-form" *ngIf="otpRequired ? otpVerified : true">
    <div class="submittedMessage-overlay" *ngIf="submittedSuccessfully">
        <div class="submittedMessage">
            <div class="close-cont" (click)="closeWindow('success')">
                <div class="close-icon">&#x2715;</div>
            </div>
            <img src="../../../assets/icons-svg/success.svg" alt="Success">
            <p class="success">Success !</p>
            <p class="message">Your application has been submitted successfully.</p>
            <br>
            <strong class="message message-italic">Kindly check your email for further notifications.</strong>
        </div>
    </div>
    <div class="submittedMessage-overlay" *ngIf="emailExists || mobileExists">
        <div class="submittedMessage">
            <div class="close-cont" (click)="closeWindow('warning')">
                <div class="close-icon">&#x2715;</div>
            </div>
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">NOTE !</p>
            <p class="message">We found existing records based on your {{(emailExists && mobileExists) ? "email and mobile number" : emailExists ? "email" : mobileExists ? "mobile number" : null}}</p>
            <br>
            <strong class="message message-italic">Kindly contact HR for further assistance.</strong>
        </div>
    </div>
    <div class="submittedMessage-page" *ngIf="offerFormSuccess">
        <div class="submittedMessage-cover_success">
            <img class="submittedMessage-cover-img" src="../../../assets/form-icons/success-banner.svg" alt="">
        </div>
        <div class="submittedMessage-logo">
            <div class="logo-magin">
                <img src="../../../assets/form-icons/success.svg" alt="">
            </div>
        </div>
        <div class="submittedMessage-info">
            <div>
                <p class="submittedMessage-info_header">Success</p>
                <p class="submittedMessage-info_note">Thank you for accepting the Offer.<br>We will get back to you soon</p>
            </div>
        </div>
    </div>
    <div class="submittedMessage-page" *ngIf="offerFormWarning">
        <div class="submittedMessage-cover_warning">
            <img class="submittedMessage-cover-img" src="../../../assets/form-icons/warning-banner.svg" alt="">
        </div>
        <div class="submittedMessage-logo">
            <div class="logo-magin">
                <img src="../../../assets/form-icons/warning.svg" alt="">
            </div>
        </div>
        <div class="submittedMessage-info">
            <div *ngIf="!invalidUrl">
                <p class="submittedMessage-info_header">Note</p>
                <p class="submittedMessage-info_note">You have already submitted the offer.<br>So you cannot submit again.</p>
            </div>
            <div *ngIf="invalidUrl">
                <p class="submittedMessage-info_header">Invalid Url</p>
                <p class="submittedMessage-info_note">Kindly verify the link</p>
            </div>
        </div>
    </div>
    <div class="submittedMessage-overlay" *ngIf="formExpired">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">NOTE !</p>
            <p class="message">Registration no longer accepted</p>
            <br>
        </div>
    </div>
    <div class="submittedMessage-overlay" *ngIf="formExceeded">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">NOTE !</p>
            <p class="message">Sorry, you can't register anymore because the deadline, which was {{registrationEndDate | date: 'short'}}, has passed.</p>
            <br>
        </div>
    </div>
    <!-- <div class="submittedMessage-overlay" *ngIf="invalidUrl">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">Invalid URL</p>
            <p class="message">Kindly verify the link</p>
            <br>
        </div>
    </div> -->
    <div class="submittedMessage-overlay" *ngIf="existingFormSubmit">
        <div class="submittedMessage">
            <img src="../../../assets/icons-svg/Warning.svg" alt="Warning">
            <p class="warning">Form Already Submitted !</p>
            <p class="message">Kindly contact HR for further assistance</p>
            <br>
        </div>
    </div>
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div *ngIf="!hideForm && !isLoading">
        <div class="logo">
            <img class="logo-img" src={{companyLogo}} alt="" srcset="">
        </div>
        <div *ngIf="(schoolCode === 'wileyedge399' || schoolCode === 'wileyedge537' || schoolCode == 'nstech196')"
            class="banner"
            [style]="{backgroundColor: this.formName == 'Registration Form' ? '#18C76F' : this.formName == 'Extended Details and Documentation Form' ? '#115C36' : this.formName == 'Registration Form Non-Technical' ? '#18C76F' : '#FFFFFF'}">
            
            <span [ngSwitch]=this.formName>
                <span *ngSwitchCase="'Registration Form'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'registration-banner.jpg'}}"
                        alt="" srcset="">
                </span>
            </span>

            <span [ngSwitch]="this.formName">
                <span *ngSwitchCase="'Extended Details and Documentation Form'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'completion-banner.png'}}"
                        alt="" srcset="">
                </span>
            </span>

            <span [ngSwitch]=this.formName>
                <span *ngSwitchCase="'Offer Acceptance and Joining Confirmation Form'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'offer-acceptance-banner.jpg'}}"
                        alt="" srcset="">
                </span>
            </span>

            <span [ngSwitch]=this.formName>
                <span *ngSwitchCase="'Registration Form Non-Technical'">
                    <img class="banner-image"
                        src="https://neo-hire.s3.amazonaws.com/logo/781936e1-7cba-4666-a975-0a7ec447cace/{{'registration-non-tech-banner.png'}}"
                        alt="" srcset="">
                </span>
            </span>     
        </div>
        <div style="display:flex; justify-content:center">
            <div style="max-width: 55vw; margin-bottom: 30px;">
                <div class="form-data">
                    <h2 class="form-title" #title>{{formName}}</h2>
                </div>

                <div *ngFor="let section of templateCollections; index as i">
                    <div class="personal-info">
                        <div>
                            <h3 class="personal-details_label">{{section[0].displaySection}}</h3>
                            <hr class="title-line">
                        </div>
                        <div *ngFor="let model of section">
                            <app-fields 
                                *ngIf="checkForDependancy(model)" 
                                (checkValidator)="checkValid($event, model)"
                                (fieldDataEmitter)="bindData($event, model)" 
                                [model]="model" 
                                [clicked]="clicked" 
                                [invalidField]="invalidField"
                                [inValidLocation]="inValidLocation"
                                [belowAge]="belowAge"
                                [duplicateData]="duplicateData"
                                [inEditMode]="inEditMode"
                                [ansChange]="ansChange"
                                [CGPAFieldList]="CGPAFieldList"
                                [yearsFieldList]="yearsFieldList">
                            </app-fields>
                        </div>
                    </div>
                </div>
                <div class="fieldDescription" *ngIf="(this.formName == 'Registration Form' || this.formName == 'Registration Form Non-Technical')">
                    <p>I, the undersigned, Mr/Ms <input class="consent-input" [(ngModel)]="consentName" type="text" id="name">, S/o, D/o, W/o <input class="consent-input" [(ngModel)]="consentParent" type="text" id="parentName">,
                        aged <input class="consent-input numeric-input" [(ngModel)]="consentAge" style="width: 35px !important;" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==2) return false;" id="age">, residing at <input class="consent-input" [(ngModel)]="consentPlace" type="text" id="address"> hereby give my consent to
                        Societe Generale Global Solution Centre Pvt Ltd to collect and process my personal data, i.e., Aadhaar, PAN, Email
                        ID for the purpose of verification.</p>
                    <p style="margin-top: 15px;">I confirm that I have been informed of the personal data collected and that the personal data will be processed for the above said purpose.</p>
                    <p style="margin-top: 15px;">I have been provided with the contact details of Hemanth Kumar(kalki@iamneo.ai) in case I want any clarification or to exercise my rights.</p>
                </div>
                <button pButton pRipple type="button" label="Submit" (click)="varifySubmit()" class="form-btn"
                    [loading]="submitBtnLoading"></button>
            </div>
        </div>
        <div *ngIf="schoolCode === 'wileyedge399' || schoolCode === 'wileyedge537'" class="footer">
            <p>Powered by Wiley Edge</p>
        </div>
    </div>
    <p-toast></p-toast>
</div>

<div *ngIf="otpRequired && !otpVerified">
    <app-otp-verification (otpVerified)="otpVerified = $event; prefillForm()" [candidateId]="candidateId" [eventId]="eventId"></app-otp-verification>
</div>