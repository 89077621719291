import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { saveAs } from 'file-saver';
import { EventServicesService } from 'src/app/services/event-services.service';
import { JobServicesService } from 'src/app/services/job-services.service';
import { MenuItem, MessageService } from 'primeng/api';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Tooltip } from 'primeng/tooltip';
import { GlobalServicesService } from 'src/app/services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { nanoid } from 'nanoid'
import { SettingsService } from 'src/app/services/settings.service';

enum CandidateStatus {
  applied = 'Applied',
  pending = 'Pending',
  selected = 'Selected',
  rejected = 'Rejected',
  archived = 'Archived',
  shortlisted = 'Shortlisted',
  addedToVettedPool = 'Added To Vetted Pool',
  submitted = 'Submitted',
  scheduled = 'Scheduled',
  allClear = 'All Clear',
  eligible = 'Eligible',
  attended = 'Attended',
  noteligible = 'Not Eligible',
  waitlisted = 'Waitlisted',
  noShow = 'No Show',
  slotSend = 'Slot Sent',
  submittedPartially = 'Submitted Partially',
  submissionPending = 'Submission Pending',
  partiallyApproved = 'Partially Approved',
  joiningConfirmed = 'Joining Confirmed',
  joined = 'Joined',
  notJoining = 'Not Joining',
  notJoined = 'Not Joined',
  yetToJoin = 'Yet To Join',
  mailPending = 'Mail Pending',
  offered = 'Offered',
  offerPending = 'Offer Confirmation Sent',
  offerRevision = 'Offer Revision',
  offerAccepted = 'Offer Accepted',
  offerDeclined = 'Offer Declined',
  onHold = 'On Hold',
  futureCandidate = 'Future Candidate',
}

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css']
})
export class CandidateListComponent implements OnInit {
  @ViewChild(Tooltip) tooltip!: Tooltip;
  // Booloan Var
  displayCandidates: boolean = false;
  displayJobDetails: boolean = false;
  displayWorkflow: boolean = false;
  displayInterview: boolean = false;
  loadingSpinner: boolean = false;
  showFilterSidebar: boolean = false;
  showSettingsSidebar: boolean = false;
  showDownloadSidebar: boolean = false;
  displaySaveFilterSettings: boolean = false;
  displaySaveColumnSettings: boolean = false;
  displayUserDetails: boolean = false;
  isInEvent!: boolean;
  showBulkUpload: boolean = false;
  sendingInvite: boolean = false;
  clicked: boolean = false;
  uploadingFile: boolean = false;
  bulkFile: boolean = false;
  inviteActivate: boolean = false;
  displayInterviewFilter: boolean = false;

  // Array Var
  candidateList: any = [];
  usersCol: Array<any> = [];
  _usersCol: Array<any> = [];
  _selectedColumns: Array<any> = [];
  colsToDownload: Array<any> = [];
  pageLimits: Array<any> = [];
  checkedColumns: Array<any> = [];
  columnsToSave: Array<any> = [];
  savedColumnSettings: Array<any> = [];
  selectedUser: Array<any> = [];
  candidateStatus: any = {}
  statusColor: Array<any> = [];
  excel: File[] = [];
  stageDetails: Array<any> = [];
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  dateFormat: any;
  timeFormat: any;
  statusSettings: any = {};

  // Number Var
  first: number = 1;
  last: number = 10;
  pageSize: number = 10;
  pageNumber: number = 1;
  totalLength: number = 0;
  numberOfStages: number = 0;

  // String Var
  columnSearchTerm: string = '';
  filterSearchTerm: string = '';
  downloadSearchTerm: string = '';
  downloadAs: string = 'excel';
  exportName: string = 'userList';
  selectedJobId: string = '';
  selectedEventId: string = '';
  bulkUpload!: MenuItem[];
  _selectedCategory: string = 'single-invite';
  // eventRadio: string = 'technical';

  selectedJobName: string = '';
  schoolId: string = '';
  selectedEventName: string = '';
  schoolData: any;
  schoolCode: string = '';
  inviteUrl: string = ''
  eventId: string = '';
  eventName: string = '';
  eventCategory: string = '';
  link: string = '';
  category: any = localStorage.getItem("eventCategory");
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.assetBucketName;

  selectedInterviewType: string = 'all';
  interviewTypeOptions: MenuItem[] = [];

  singleInvite: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    emailID: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    source: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required)
  })

  bulkInvite: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl(''),
    eventCategory: new FormControl(''),
    actionId: new FormControl('')
  })

  constructor(
      private jobService: JobServicesService, 
      private eventService: EventServicesService, 
      private _router: Router, 
      private activatedRoute: ActivatedRoute, 
      private breadcrumbService: NgDynamicBreadcrumbService,
      private fileUploadService: FileUploadService,
      private messageService: MessageService,
      private globalService: GlobalServicesService,
      private settingsService: SettingsService,
    ) {
    this.pageLimits = [10, 25, 50, 100];

    this.usersCol = [
      { index: 1, field: 'degree', header: 'Education' },
      { index: 2, field: 'stageName', header: 'Stage Name' },
      { index: 3, field: 'source', header: 'Source' },
      { index: 4, field: 'owner', header: 'Owner' },
      { index: 5, field: 'status', header: 'Status' },
      { index: 6, field: 'stageId', header: 'Stage' },
      { index: 7, field: 'email', header: 'E-mail' },
      { index: 8, field: 'mobile_number', header: 'Phone' },
      { index: 9, field: 'gender', header: 'Gender' },
      { index: 10, field: 'dob', header: 'Date Of Birth' },
      { index: 11, field: 'createdAt', header: 'Created On' },
      { index: 12, field: 'college_name', header: 'College Name' },
    ]

    this._selectedColumns = [
      { index: 1, field: 'degree', header: 'Education' },
      { index: 2, field: 'stageName', header: 'Stage Name' },
      { index: 5, field: 'status', header: 'Status' },
      { index: 6, field: 'stageId', header: 'Stage' },
      { index: 7, field: 'email', header: 'E-mail' },
      { index: 8, field: 'mobile_number', header: 'Phone' },
      { index: 9, field: 'gender', header: 'Gender' },
    ]
  }

  columnSettings: FormGroup = new FormGroup({
    columnName: new FormControl(''),
    columnValue: new FormControl(this.columnsToSave)
  })

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].dateFormat : '';
    // this.globalService.dateFormat.subscribe((res: any) => {
    //   this.dateFormat = res;
    // })
    // this.globalService.timeFormat.subscribe((res: any) => {
    //   this.timeFormat = res;
    // })

    this.selectedJobId = window.localStorage.getItem('selectedJobId') || '';
    this.selectedEventId = window.localStorage.getItem('selectedEventId') || '';
    this.selectedJobName = window.localStorage.getItem('selectedJobName') || '';
    this.selectedEventName = window.localStorage.getItem('selectedEventName') || '';
    // let category = localStorage.getItem("eventCategory");
    this.singleInvite.controls['eventCategory'].setValue(this.category);
    this.bulkInvite.controls['eventCategory'].setValue(this.category);
    this.isInEvent = this.selectedJobId === this.selectedEventId;
    this.getUsers();
    this.getSavedColumns();
    this._usersCol = this.usersCol
    this.colsToDownload = this.usersCol;
    this.checkedColumns = this._selectedColumns;
    this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';
    
    this.schoolId = this.schoolData.school_id;
    this.schoolCode = this.schoolData.school_code;

    this.bulkUpload = [
      {
        label: 'Send Invite', command: () => {
          this.inviteActivate = true;
        }
      },
    ]

    // this.candidateStatus = CandidateStatus;

    this.statusColor = [
      { status: "applied", color: 'var(--clr-violet-bg-light)' },
      { status: "scheduled", color: 'var(--clr-violet-bg-light)' },
      { status: "addedToVettedPool", color: 'var(--clr-violet-bg-light)' },
      { status: "slotSend", color: 'var(--clr-violet-bg-light)' },
      { status: "submitted", color: 'var(--clr-violet-bg-light)' },
      { status: "joiningConfirmed", color: 'var(--clr-violet-bg-light)' },
      { status: "offered", color: 'var(--clr-violet-bg-light)' },

      { status: 'offerAccepted', color: 'var(--clr-green-bg-light)' },
      { status: 'offerRevision', color: 'var(--clr-blue-bg-light)' },
      { status: 'offerDeclined', color: 'var(--clr-red-bg-light)' },

      { status: "selected", color: 'var(--clr-green-bg-light)' },
      { status: "shortlisted", color: 'var(--clr-green-bg-light)' },
      { status: "allClear", color: 'var(--clr-green-bg-light)' },
      { status: "eligible", color: 'var(--clr-green-bg-light)' },
      { status: "joined", color: 'var(--clr-green-bg-light)' },

      { status: "waitlisted", color: '#FFE0B4' },
      { status: "noShow", color: '#FFE0B4' },
      { status: "submittedPartially", color: '#FFE0B4' },
      { status: "notJoining", color: '#FFE0B4' },

      { status: "rejected", color: 'var(--clr-red-bg-light)' },
      { status: "noteligible", color: 'var(--clr-red-bg-light)' },
      { status: "notJoined", color: 'var(--clr-red-bg-light)' },

      { status: "pending", color: 'var(--clr-blue-bg-light)' },
      { status: "submissionPending", color: 'var(--clr-blue-bg-light)' },
      { status: 'offerPending', color: 'var(--clr-violet-bg-light)' },
      { status: 'mailPending', color: '--clr-blue-bg-light' },

      { status: "attended", color: '#E1E6CC' },
      { status: "partiallyApproved", color: '#E1E6CC' },
      { status: "futureCandidate", color: '#E1E6CC' },
      { status: 'yetToJoin', color: '#E1E6CC' },

      { status: "archived", color: 'var(--clr-grey)' },
      { status: 'onHold', color: '#7a859275' },

    ]

    this.routeChange();

    this.eventId = window.localStorage.getItem('selectedEventId') || '';
    this.eventName = window.localStorage.getItem('selectedEventName') || '';
    this.schoolCode = this.schoolData.school_code;

    this.interviewTypeOptions = [
      {
        label: 'All',
        command: () => {
          this.selectedInterviewType = 'all';
        },
      },
      {
        label: 'Online',
        command: () => {
          this.selectedInterviewType = 'online';
        },
      },
      {
        label: 'Offline',
        command: () => {
          this.selectedInterviewType = 'offline';
        },
      },
    ];

    let formLink = '';
    this.eventCategory = window.localStorage.getItem('eventCategory') || 'technical';
    
    if(this.eventCategory == 'technical') formLink = 'registration-form?form=Registration%20Form';
    else if(this.eventCategory == 'nonTechnical') formLink = 'registration-form?form=Registration%20Form%20Non-Technical';
    this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/${formLink}&eventId=${this.eventId}&eventTitle=${this.eventName}`;
  }

  routeChange = () => {
    let routeChanged = false;
    let tabView = '';
    this._router.events.subscribe(path => {
      routeChanged = true;
      if (path instanceof NavigationEnd) {
        let pathView = path.urlAfterRedirects.split('=')
        tabView = pathView[1] || '';
      }
      this.tabSwitch(tabView);
    })
    if (!routeChanged) {
      const snapshot: RouterStateSnapshot = this._router.routerState.snapshot;
      const queryParams = snapshot.root.queryParams;
      this.tabSwitch(queryParams['view']);
    };
  }

  tabSwitch = (tabView: string) => {
    switch (tabView) {
      case 'candidates': {
        this.displayCandidates = true;
        this.displayJobDetails = false;
        this.displayWorkflow = false;
        this.displayInterview = false;
        break;
      }
      case 'jobDetails':
      case 'eventDetails': {
        this.displayJobDetails = true;
        this.displayCandidates = false;
        this.displayWorkflow = false;
        this.displayInterview = false;
        break;
      };
      case 'workflow': {
        this.displayWorkflow = true;
        this.displayJobDetails = false;
        this.displayCandidates = false;
        this.displayInterview = false;
        break;
      }
      case 'interview': {
        this.displayInterview = true;
        this.displayCandidates = false;
        this.displayJobDetails = false;
        this.displayWorkflow = false;
        break;
      }
    }
    this.updateBreadcrumb();
  }

  updateBreadcrumb(): void {
    this.selectedJobName = window.localStorage.getItem('selectedJobName') || '';
    this.selectedEventName = window.localStorage.getItem('selectedEventName') || '';
    const breadcrumbs = [
      {
        label: this.isInEvent ? 'Manage Events' : 'Manage Drives',
        url: this.isInEvent ? 'events' : 'jobs'
      },
      {
        label: this.isInEvent ? this.selectedEventName : this.selectedJobName,
        url: ''
      },
    ];
    this.breadcrumbService.updateBreadcrumb(breadcrumbs);
  }

  // API Functions
  getUsers = () => {
    this.loadingSpinner = true;
    this.candidateList = [];
    this.jobService.getCandidatesByJob(this.selectedJobId, this.pageNumber, this.pageSize).subscribe((result: any) => {
      this.candidateList = result?.data ? result.data?.items : [];
      this.totalLength = result.data.totalItems || 0;
      // this.isInEvent ? this.getStages(this.selectedEventId) : this.getStages(this.selectedJobId);
      this.getStages();
      // this.loadingSpinner = false;
    })
  }

  getUsersByLimit = () => {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.pageNumber = 1;
    this.first = 1;
    this.last = this.pageSize < this.totalLength ? this.pageSize : this.totalLength;
    this.getUsers();
  };

  showUserDetails = (user: any) => {
    this.selectedUser = user;
    this.displayUserDetails = true;
  }

  getStages = () => {
    this.candidateList.forEach((candidate: any) => {
      this.stageDetails.forEach((stage: any) => {
        if (candidate.stageId == stage.stageId){
          candidate.stageName = stage.name;
          candidate._stageId = stage._stageId;
          candidate.stageType = stage.type;
        }
      })
    })
    this.getStatusSettings();
  }

  getStatusSettings = () => {
    this.loadingSpinner = true;
    this.settingsService.getStageStatusData().subscribe(res => {
      if(res.status == 200){
        this.statusSettings = res?.data ? res.data.stageStatus : {};
        this.loadingSpinner = false;
      }
    })
  }

  setFormLink(){
    let formLink = '';
    
    if(this.category == 'technical') formLink = 'registration-form?form=Registration%20Form';
    else if(this.category == 'nonTechnical') formLink = 'registration-form?form=Registration%20Form%20Non-Technical';

    this.inviteUrl = `${window.location.protocol}//${window.location.hostname}/${formLink}&eventId=${this.eventId}&eventTitle=${this.eventName}`;
    this.bulkInvite.controls['link'].setValue(this.link);
  
    this.link = `${this.inviteUrl}`;
    this.bucketName = this.globalService.assetBucketName;
  }

  sidebarClose(){
    this.inviteActivate = false;
  }

  saveColumnSettings = () => {
    this.loadingSpinner = true;
    this.columnsToSave = this.checkedColumns.map(e => e.field);

    this.columnSettings.setValue({
      columnName: this.columnSettings.get("columnName")?.value,
      columnValue: this.columnsToSave
    });

    if (this.isInEvent) {
      this.eventService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value], this.selectedEventId).subscribe(result => {
        this.savedColumnSettings = result.data.selectedColumns;
      })
    } else
      this.jobService.saveColumnCandidate(this.selectedJobId, [this.columnSettings.value]).subscribe(result => {
        this.savedColumnSettings = result.data.selectedColumns;
      })

    this.loadingSpinner = false;
    this.displaySaveColumnSettings = false;

  }

  getSavedColumns = () => {
    this.jobService.getJob(this.selectedJobId).subscribe(res => {
      this.savedColumnSettings = res.data?.selectedColumns;
    })
  }

  deleteSavedColumns = (columnId: string) => {
    this.loadingSpinner = true;
    this.jobService.deleteColumnCandidates(this.selectedJobId, columnId).subscribe(res => {
      this.savedColumnSettings = res.data?.selectedColumns;
      this.loadingSpinner = false;
    })
  }

  // Normal Functions
  addCandidateForm = () => {
    window.open(this.inviteUrl);
  }


  applySelectedColumns = () => {
    this._selectedColumns = this.sortedColumns();
    this.showSettingsSidebar = false;
  }

  download() {
    window.open('assets/Book1.xlsx', 'Download');
  }


  private sortedColumns(): any[] {
    return this.checkedColumns.sort((a, b) => (a.index < b.index) ? -1 : 1);
  }

  clearSelectedColumns = () => {
    this.checkedColumns = [];
  }

  showSaveColumnSettings = () => {
    this.displaySaveColumnSettings = true;
  }

  searchColumns = (searchTerm: string) => {
    if (this.showSettingsSidebar) {
      this._usersCol = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (this.showDownloadSidebar) {
      this.colsToDownload = this.usersCol.filter(val => val.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  applySavedSettings = (savedColumnValue: Array<any>) => {
    this.checkedColumns = this.usersCol.filter(e => savedColumnValue.includes(e.field));
  }

  showCandidates = () => {
    if (this.isInEvent) this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'candidates' } })
    else this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'candidates' } })
    this.routeChange();
  }

  showJobDetails = () => {
    if (this.isInEvent) this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'eventDetails' } })
    else this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'jobDetails' } })
    this.routeChange();
  }

  showWorkflow = () => {
    if (this.isInEvent) this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'workflow' } })
    else this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'workflow' } })
    this.routeChange();
  }

  showInterview = () => {
    if (this.isInEvent) this._router.navigate(['events/candidates/', this.selectedEventName], { queryParams: { view: 'interview' } })
    else this._router.navigate(['jobs/candidates/', this.selectedJobName], { queryParams: { view: 'interview' } })
    this.routeChange();
  }

  exportCandidate =  (type: string) => {
    let checkedColumnFields: any = ['name', 'id'];
    this.checkedColumns.forEach((each) => {
      checkedColumnFields.push(each.field);
    });
    let afterFilter: any = [];
    this.candidateList.forEach((eachData: any) => {
      let filterData: any = {};
      checkedColumnFields.forEach((item: any) => {
        eachData.hasOwnProperty(item) && (filterData[item] = eachData[item]);
      });
      if (
        checkedColumnFields.find((element: string) => element === 'location')
      ) {
        let locationString: string = '';
        eachData.locationInformation.forEach((eachlocation: any) => {
          locationString +=
            `${locationString.length ? ', ' : ''}` + eachlocation.location;
        });
        filterData['location'] = locationString;
      }
      afterFilter.push(filterData);
    });
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(afterFilter);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: type === 'excel' ? 'xlsx' : 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, this.exportName, type);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], {
      type: FILE_TYPE,
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  sampleData(){
    let filePath: string = 'manage-candidate/bulk-invite-template/Sample_Bulk_Invite.xlsx'
    let fileData: string = `${this.s3Domain}/${this.globalService.bucketName}/${filePath}`;
    window.open(fileData);
  }

  // Pagination
  next(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first + this.pageSize;
    this.last = this.last + this.pageSize < this.totalLength ? this.pageSize + this.last : this.totalLength;
    this.pageNumber += 1;
    this.getUsers();
  }

  prev(): void {
    this.showFilterSidebar = false;
    this.showSettingsSidebar = false;
    this.showDownloadSidebar = false;
    this.first = this.first - this.pageSize;
    this.last = this.last - this.pageSize > this.pageSize ? ( this.last == this.totalLength ? this.totalLength - this.candidateList.length : this.last - this.pageSize ) : this.pageSize;
    this.pageNumber -= 1;
    this.getUsers();
  }

  isLastPage(): boolean {
    return this.totalLength ? this.first > this.totalLength - this.pageSize : true; 
  }

  isFirstPage(): boolean {
    return this.first > 1 ? false : true;
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.usersCol.filter(col => val.includes(col));
  }

  getArray(num: number) {
    return new Array(num);
  }

  copyMessage() {
    let val = new URL(this.inviteUrl).href;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.tooltip.activate();
  }
}
