<div>
  <div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
  <div class="live-chat-header">
    <div class="detail-panel">
      <div>
        <p><b><i title="User Name" class="pi pi-user info"></i>{{candidateName}}</b><span class="line-height">|</span><b><i title="Candidate ID" class="pi pi-id-card info"></i>{{userId}}</b><span class="line-height">|</span><b><i title="Degree" class="pi pi-book info"></i>{{degreeDetails[0].degree}}</b></p>
      </div>
      <div *ngIf="assessmentScore?.length" class="test-score">
        <div *ngFor="let eachScore of assessmentScore">
          <div><b>{{eachScore.stageName}} : </b>{{eachScore.scoreData.marks}}/{{eachScore.scoreData.total_marks}} 
          <div (click)="getResultAnalysis(eachScore.scoreData.test_id)" class="pi pi-chart-bar result-icon"></div>
        </div>
      </div>
      </div>
    </div>
    <span>
    </span>
    <div class="header-btns">
      <button pButton class="joinBtn" label="Submit Feedback" (click)="getAllRoles()"></button>
    </div>
  </div>
  <div *ngIf="meetingEnd && !adminReload" class="candidate-body-content">
    Your Interview has been Completed.
  </div>
  <div *ngIf="adminReload" class="candidate-body-content">
    Reloading Interview.
  </div>
  <div id="jaas-container" class="live-chat"
    style="height:80vh; background-color: black; margin-top: 0.5rem; display: none;">
  </div>

  <p-sidebar model="true" class="addRole-slider" [style]="{width: '55rem'}" [(visible)]="feedbackSlider"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right">
    <div style="position:relative; padding: 20px 50px; background-color: #F8F8F8;">
      <app-feedback-form [headerData]="paramItems" [formName]="formName"></app-feedback-form>
    </div>
  </p-sidebar>

</div>