<div class="sendEmail-header">  
    <div class="email-heading">{{sendMailHeader}}</div>
    <div class="header-btn">
        <button pButton pRipple [loading]="loader" [label]="sendMailHeader" type="submit" class="p-button-raised p-button-text button-align" (click)="submit()"></button>
    </div>
</div>
<p-divider class="p-divider"></p-divider>

<div *ngIf="!assignCohortData">

  <form class="emailForm" [formGroup]="sendEmailForm">
      <span class="p-float-label emailForm-elements">
          <p-dropdown class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true" filterBy="templateTitle" optionLabel="templateTitle"
          formControlName="template" [options]="templatesList" (onChange)="emailWrite($event)">
          </p-dropdown>
          <label for="inputtext">Email Template</label>
      </span>
  
      <span class="p-float-label emailForm-elements">
          <input type="email" name="From :" id="inputtext" pInputText formControlName="fromAddress" readonly>
          <label for="inputtext">From <span class="imp">*</span></label>
      </span>
  
      <span class="p-float-label emailForm-elements">
          <p-multiSelect [options]="emails" formControlName="toAddress" id="chips" display="chip" [(ngModel)]="emails">
          </p-multiSelect>
          <label for="chips">To <span class="imp">*</span></label>
      </span>
  
      <div class="fileAttachment form-details">
          <div class="upload-heading">Attachment</div>
          <div class="template-upload fileUpload-dropzone" ngx-dropzone
            [accept]="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'"
            [multiple]="false" (change)="onSelectMedia($event, true)" [maxFileSize]="2000000">
            <ngx-dropzone-label *ngIf="!uploadingFile">
              <div class="dropzone-label">
                <em class="pi pi-cloud-upload"></em>
                <p class="upload-text">
                  Click to
                  <span [style]="{ color: 'var(--fc-primary)' }">upload</span> or Drag
                  & Drop
                </p>
                <p class="format-text">.XLS, .XLSX, .CSV .DOCX .PDF Formats only</p>
                <p class="format-text">File should be Less than 2MB</p>
              </div>
            </ngx-dropzone-label>
            <div style="display: flex; justify-content:center; align-items:center" *ngIf="uploadingFile">
              <p-progressSpinner id="progress" [style]="{height: '30px', width: '30px'}" strokeWidth="7"
                animationDuration="0.5s">
              </p-progressSpinner>
            </div>
            <div *ngIf="!uploadingFile">
              <ngx-dropzone-preview class="file-preview" ngProjectAs="ngx-dropzone-preview"
                *ngFor="let f of attachmentFile" [file]="f" [removable]="true" (removed)="onRemoveMedia(f)">
                <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                </ngx-dropzone-label>
              </ngx-dropzone-preview>
            </div>
          </div>
        </div>
  
      <span class="p-float-label emailForm-elements">
          <input type="text" name="Subject :" id="inputtext" pInputText formControlName="subjectTitle"
          [ngClass]="{'invalid': clicked && sendEmailForm.controls['subjectTitle'].errors}">
          <label for="inputtext">Subject <span class="imp">*</span></label>
          <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('subjectTitle')" name="Subject">
          </app-handle-error>
      </span>
  
      <form>
          <textarea placeholder="Body" id="content"></textarea>
      </form>
  
  </form>
</div>

<div *ngIf="assignCohortData">
  <form class="offerForm" [formGroup]="sendOfferForm">

    <span class="p-float-label offerForm-elements">
      <p-dropdown class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true"
        filterBy="cohort_name" optionLabel="cohort_name" optionValue="id" formControlName="cohort_id" [options]="cohortStatuses"
        (onChange)="selectCohort($event)">
      </p-dropdown>
      <label for="inputtext">Cohort Name <span class="imp">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="sendOfferForm.get('cohort_id')" name="Cohort Name">
      </app-handle-error>
    </span>

    <span class="p-float-label offerForm-elements">
      <p-multiSelect class="dropdown" id="chips" inputId="dropdown" display="chip" formControlName="cohortLocation" 
      [options]="jobLocationsList" optionLabel="name" optionValue="value" [(ngModel)]="selectedLocation"> 
      </p-multiSelect>
      <label for="chips">Cohort Job Location <span class="imp">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="sendOfferForm.get('cohortLocation')" name="Cohort Job Location">
      </app-handle-error>
    </span>
    
    <span class="p-float-label offerForm-elements">
      <p-dropdown class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" formControlName="cohortJobRole" 
      [options]="jobRolesList">
      </p-dropdown>
      <label for="inputtext">Cohort Job Role <span class="imp">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="sendOfferForm.get('cohortJobRole')" name="Cohort Job Role">
      </app-handle-error>
    </span>

    <span class="p-float-label offerForm-elements">
      <p-dropdown class="dropdown" id="inputtext" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true" filterBy="templateTitle" optionLabel="templateTitle"
      formControlName="template" [options]="templatesList" (onChange)="emailWrite($event)">
      </p-dropdown>
      <label for="inputtext">Email Template</label>
    </span>

    <span class="p-float-label offerForm-elements">
      <input type="text" name="Subject :" id="inputtext" pInputText formControlName="subjectTitle"
        [ngClass]="{'invalid': clicked && sendOfferForm.controls['subjectTitle'].errors}">
      <label for="inputtext">Subject <span class="imp">*</span></label>
      <app-handle-error [clicked]="clicked" [control]="sendOfferForm.get('subjectTitle')" name="Subject">
      </app-handle-error>
    </span>
    
    <form>
      <textarea placeholder="Body" id="content"></textarea>
    </form>
  
  </form>
</div>
<p-toast></p-toast>