<div class="addUser-header">
    <div class="addUser-heading">Add User</div>
    <div class="header-btn">
        <button pButton pRipple type="submit" label="Cancel" class="p-button-outlined button-align save-btn"
            (click)="cancel()"></button>
        <button pButton pRipple type="submit" label="Save" class="p-button-raised p-button-text button-align"
            (click)="saveUser()" [loading]="saveBtnLoading"></button>
    </div>
</div>
<p-divider class="p-divider"></p-divider>

<form class="userForm" [formGroup]="addUserForm" (ngSubmit)="onSave()">

    <span class="p-float-label userForm-elements">
        <input type="text" class="inputtext" id="inputtext" pInputText formControlName="name"
            [ngClass]="{'p-filled': addUserForm.value.name,'invalid': clicked && addUserForm.controls['name'].errors}">
        <label for="inputtext">Name <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('name')" name="Name"></app-handle-error>
    </span>

    <span class="p-float-label userForm-elements">
        <input type="text" class="inputtext" id="inputtext" pInputText formControlName="email"
            [ngClass]="{'p-filled': addUserForm.value.email, 'invalid': clicked && addUserForm.controls['email'].errors}">
        <label for="inputtext">Official Email ID <span [style]="{color: '#E24A47'}">*</span></label>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('email')" name="Email"></app-handle-error>
        <div *ngIf="(addUserForm.get('email')?.hasError('pattern') && clicked)" class="email-error">
            <span
                [ngClass]="[(!addUserForm.get('email')?.valid && addUserForm.get('email')?.touched && addUserForm.get('email')?.dirty) ? 'visible-element' : 'hidden-element' ]">
                Enter a valid Email ID </span>
        </div>
    </span>

    <span class="p-float-label userForm-elements">
        <input (keypress)="restrictNumber($event)" [maxlength]="10" type="tel" class="inputtext" id="inputtext" pInputText formControlName="mobile_number"
            [ngClass]="{'p-filled': addUserForm.value.mobile_number, 'invalid': clicked && addUserForm.controls['mobile_number'].errors}">
        <label for="inputtext">Mobile Number </label>
        <div class="passwrod-error" *ngIf="clicked && addUserForm.controls['mobile_number'].errors?.['pattern']">
            <p> Mobile Number must contain 10 digits</p>
        </div>
        <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('mobile_number')"
            name="Mobile Number"></app-handle-error>
    </span>

    <span class="p-float-label userForm-elements">
        <input type="password" class="inputtext passwordInput" id="inputtext" pInputText formControlName="password"
            [ngClass]="{'p-filled': addUserForm.value.password, 'invalid': clicked && addUserForm.controls['password'].errors}">
        <span *ngIf="!showPassword" class="eye-icon pi pi-eye" (click)="showToggle()"></span>
        <span *ngIf="showPassword" class="eye-icon pi pi-eye-slash" (click)="showToggle()"></span>
        <label for="inputtext">Password <span [style]="{color: '#E24A47'}">*</span></label>
        <div class="passwrod-error" *ngIf="clicked && addUserForm.controls['password'].errors?.['pattern']">
            <p>Password must contain a Lowercase, Uppercase, Number, Special character and must be alteast 8 characters</p>
            <!-- <p> At least 8 characters in length</p>
            <p> At least 1 Lowercase letter</p>
            <p> At least 1 Uppercase letter</p>
            <p> At least 1 Number</p>
            <p> At least 1 Special character</p> -->
        </div>
        <!-- <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('password')"
            name="Password"></app-handle-error> -->
    </span>

    <div>
        <span class="p-float-label userForm-elements">
            <p-dropdown id="associated-role_dropdown" class="dropdown" inputId="dropdown" [autoDisplayFirst]="false" optionLabel="label"
                optionValue="value" (onChange)="showInfo = false" [options]="associativeRoles"
                formControlName="userType" [required]="true"
                [styleClass]="clicked && addUserForm.controls['userType'].errors ? 'invalid' : ''">
            </p-dropdown>
            <label for="dropdown">Associated Role <span class="imp">*</span></label>
            <app-handle-error class="err-msg" [clicked]="clicked" [control]="addUserForm.get('userType')" name="User type">
            </app-handle-error>
            <br>
            <em class="info-button" (click)="onClick()"></em>
        </span>
        <div class="infoBox" *ngIf="!addUserForm.value.userType">Please assign the role for this employee</div>
        <div class="infoBox" *ngIf="addUserForm.value.userType && showInfo">{{ dis }}</div>
    </div>
    <p-toast></p-toast>
</form>