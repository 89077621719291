import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EmailServicesService {

  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;


  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '', 
      Origin: window.location.origin,
      schoolid: schoolId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  // Email APIs
  AddTemplteCategory(payload: any): Observable<any> {
    return this.http.put(this.api_url + '/company-template/category/detail', payload, this.options());
  }

  getTemplates(categoryId?: string): Observable<any> {
    return this.http.get(this.api_url + '/company-template/?categoryId=' + categoryId, this.options());
  }

  addTemplate(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/company-template', payload, this.options());
  }

  editTemplate(payload: any, templateId: string): Observable<any> {
    return this.http.put(this.api_url + '/company-template/' + templateId, payload, this.options());
  }

  deleteTemplate(templateId: string): Observable<any> {
    return this.http.delete(this.api_url + '/company-template/' + templateId, this.options());
  }

  sendEmail(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/email-template/sendEmail', payload, this.options());
  }
}
