import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})

export class BranchesComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  branchData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  branchList: any;
  edit: boolean = false;
  clicked: boolean = false;
  isLoading: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  // selectedBranch: string = '';
  editIndex: any = -1;
  branchDetails: FormGroup = new FormGroup({
    branchName: new FormControl('', Validators.required),
    branchId: new FormControl(),
    branchContact: new FormControl(),
    branchEmail: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    branchAddress: new FormControl('', Validators.required),
    address2: new FormControl(),
    branchArea: new FormControl(),
    branchCity: new FormControl(),
    branchState: new FormControl(),
    branchZip: new FormControl()
  })

  constructor(private settings: SettingsService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.settings.getBranchData().subscribe((res: any) => {
      if (res.data) {
        if(res.status == 200){
          this.branchData = res.data;
        }else{
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!', })
        }
      }
    })
  }

  sliderHide(){
    this.branchDetails.reset(); 
    this.showPlaceholder = false;
    this.clicked = false;
    this.edit = false;
  }

  editBranch(id: number) {
    this.edit = true;
    this.addTemplateSidebar = true
    this.branchDetails.patchValue(this.branchData[id]);
  }

  deleteBranch(id:any) {
    this.isLoading = true;
    const branchId = this.branchData[id].branchId;
    this.settings.deleteBranchData(branchId).subscribe((res:any)=>{
      if(res.status == 200){
        this.getData();
        this.isLoading = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted Successfully' });
      }
    })
  }

  openAddTemplate() {
    this.addTemplateSidebar = true;
  }

  cancel = () => {
    this.branchDetails.reset();
    this.addTemplateSidebar = false;
    this.clicked = false;
    this.branchDetails.reset();
    this.edit = false;
    this.saveBtnLoading = false;
  }

  saveTemplate() {
    this.clicked = true;
    this.saveBtnLoading = true;
    if (this.branchDetails.valid) {
      if (this.edit) {
        const payload = this.branchDetails.value;
        this.settings.updateBranchData(payload.branchId, payload).subscribe((res:any) => {
          this.saveBtnLoading = false;
          if(res.status == 200){
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully' });
            this.addTemplateSidebar = false
            this.clicked = false;
            this.branchDetails.reset();
            this.edit = false;
            this.getData();
          }
        });
      } else {
        const payload = {
          branchName: this.branchDetails.value.branchName,
          branchContact: this.branchDetails.value.branchContact,
          branchEmail: this.branchDetails.value.branchEmail,
          branchArea: this.branchDetails.value.branchArea,
          branchAddress: this.branchDetails.value.branchAddress,
          branchCity: this.branchDetails.value.branchCity,
          branchState: this.branchDetails.value.branchState,
          branchZip: this.branchDetails.value.branchZip
        };
        this.settings.addBranchData(payload).subscribe((res:any) => {
          this.saveBtnLoading = false;
          if(res.status == 200){
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added Successfully' });
            this.addTemplateSidebar = false
            this.clicked = false;
            this.branchDetails.reset();
            this.getData();
          }
        })
      }
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please Fill the Required Fields', })
      this.saveBtnLoading = false;
      this.getData();
    }
  }

}


