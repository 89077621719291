import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MicrosoftAuthserviceService } from 'src/app/services/microsoft-authservice.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { EventServicesService } from 'src/app/services/event-services.service';

@Component({
  selector: 'app-calendar-invite',
  templateUrl: './calendar-invite.component.html',
  styleUrls: ['./calendar-invite.component.css']
})
export class CalendarInviteComponent implements OnInit {

  @Input() candidatesList: any[] = [];
  @Input() stage: any;

  @Output() emitResult = new EventEmitter<boolean>(false);

  slotDurations: any = [];
  slotsList: any = [];
  recruitersList: any = [];
  selectedRecruiters: any = [];
  selectedSlot: any;
  selectedSlotDuration: string = '1H';
  calendarSubject: string = '';
  calendarContent: string = '';
  calendarStartTime: string = '';
  calendarEndTime: string = '';
  calendarEmail: string = '';
  calendarDate: string = '';
  calendarName: string = '';
  oauthToken: string = '';
  eventId: string = '';
  principalUser: string = '';

  tokenValid: boolean = false;
  teamsScheduleSlider: boolean = false;
  uploadBtnLoading: boolean = false;
  tokenLoading: boolean = false;
  clicked: boolean = false;

  currentDate: any = new Date();
  encryptKey = environment.ENCRYPTION.ENCRYPTION_KEY;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private ms_authservice: MicrosoftAuthserviceService,
    private eventService: EventServicesService
  ) { }

  ngOnInit(): void {
    this.getAccessToken();
    this.slotDurations = [
      { label: '15m', value: '15M' },
      { label: '30m', value: '30M' },
      { label: '45m', value: '45M' },
      { label: '1h', value: '1H' },
      { label: '1h 30m', value: '1H30M' },
      { label: '2h', value: '2H' },
      { label: '2h 30m', value: '2H30M' },
    ]
    this.eventId = localStorage.getItem('selectedEventId') || '';
    this.eventService.getAllUser().subscribe(res => {
      this.recruitersList = res.data?.items ? res.data?.items : [];
    })
  }

  teamsSchedule() {
    this.clicked = true;
    if (this.selectedSlotDuration && this.calendarDate && this.selectedSlot && this.calendarSubject) {
      this.uploadBtnLoading = true;
      let attendees = this.candidatesList.map((each: any) => {
        return { emailAddress: { address: each.email, name: each.name }, type: "required" }
      })

      this.selectedRecruiters.forEach((item: any) => {
        attendees.push({ emailAddress: { address: item.email, name: item.name }, type: "required" })
      });

      const body = {
        subject: this.calendarSubject,
        body: {
          contentType: "HTML",
          content: this.calendarContent
        },
        start: this.selectedSlot.start,
        end: this.selectedSlot.end,
        attendees: attendees,
        isOnlineMeeting: true,
        onlineMeetingProvider: "teamsForBusiness",
      };
      this.ms_authservice.createEvent(body).subscribe((res: any) => {
        this.uploadBtnLoading = false;
        this.emitResult.emit(true);
        this.createCalendarInvite(res);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: "Calendar Event Created Successfully!",
        })
      },
        (err: any) => {
          this.uploadBtnLoading = false;
          this.tokenValid = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Token Expired",
          })
          setTimeout(() => {
            this.router.navigateByUrl('/general-settings');
          }, 3000)
        }
      );
    }
  }

  createCalendarInvite = (eventResponse: any) => {
    let payload: any = {};
    let attendees: any = [{
      type: 'candidate',
      name: eventResponse.attendees[0].emailAddress.name,
      email: eventResponse.attendees[0].emailAddress.address,
      candidateId: this.candidatesList[0].id,
      stageId: this.stage.stageId
    }];

    eventResponse.attendees.slice(1).forEach((each: any) => {
      let attendee: any = {};
      attendee.type = this.recruitersList.filter((i: any) => i.email == each.emailAddress.address)[0].userType;
      attendee.name = each.emailAddress.name;
      attendee.email = each.emailAddress.address;
      attendee.candidateId = this.recruitersList.filter((candidate: any) => candidate.email == each.emailAddress.address)[0].id;
      attendee.stageId = this.stage.stageId;
      attendees.push(attendee);
    })
    payload.attendees = attendees;
    payload.organizer = eventResponse.organizer.emailAddress;
    payload.teamsLink = eventResponse.onlineMeeting;
    payload.calendarLink = eventResponse.webLink;
    payload.calendarStartTime = eventResponse.start.dateTime;
    payload.calendarEndTime = eventResponse.end.dateTime
    payload.calendarScheduledAt = eventResponse.createdDateTime;
    payload.eventId = localStorage.getItem('selectedEventId');
    payload.meetingStatus = 'scheduled';

    this.ms_authservice.createCalendarEvent(this.eventId, this.stage.stageId, payload).subscribe(res => {
      console.log(res);
    })
  }

  getAccessToken() {
    const token = this.ms_authservice.getToken();
    console.log("token", token)

    if (token) {
      this.tokenLoading = true;
      this.ms_authservice.checkTokenValidity().subscribe(res => {
        if (res?.id) {
          this.oauthToken = token;
          this.tokenValid = true;
          this.tokenLoading = false;
          this.principalUser = res.userPrincipalName;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Access Token Retrieved!' })
        }
      },
        error => {
          this.tokenValid = false;
          this.tokenLoading = false;
          this.messageService.add({ severity: 'error', summary: 'Token Expired', detail: 'Kindly login to Microsoft to access token.' })
        }
      )
    } else {
      this.tokenValid = false;
    }
  }

  redirectToSettings() {
    let redirectURL = window.location.pathname + window.location.search;
    localStorage.setItem('redirectAfterTokenRetrive', redirectURL);
    this.router.navigateByUrl('/general-settings');
  }

  getCalendarSlots(event: any) {
    let _startDate:any
    let _endDate:any
    
    if(event.toISOString().split('T')[1] != '18:30:00.000Z'){
      let defaultDate = moment.utc(event).subtract(1, 'days').toISOString().split('T')[0] + 'T18:30:00.000Z';
      _startDate = moment.utc(defaultDate).add(moment().hour(), 'hours').startOf('minutes').toISOString();
      _endDate = moment.utc(defaultDate).add(22, 'hours').toISOString();
    } else {
      _startDate = moment.utc(event).add(8, 'hours').toISOString();
      _endDate = moment.utc(event).add(22, 'hours').toISOString();
    }

    let requestBody = {
      attendees: [{ emailAddress: { address: this.principalUser } }],
      timeConstraint: {
        activityDomain: "unrestricted",
        timeslots: [
          {
            start: {
              dateTime: _startDate,
              timeZone: "UTC"
            },
            end: {
              dateTime: _endDate,
              timeZone: "UTC"
            }
          }
        ]
      },
      meetingDuration: `PT${this.selectedSlotDuration}`,
      maxCandidates: 50
    }
    this.ms_authservice.getMeetingTimes(requestBody).subscribe(res => {
      if (res?.meetingTimeSuggestions.length) {
        let meetingTimes = res?.meetingTimeSuggestions;

        let timeslots: any = [];
        for (let index = 0; index < meetingTimes.length; index++) {
          let startTime = moment.utc(meetingTimes[index].meetingTimeSlot.start.dateTime).toISOString();
          let endTime = moment.utc(meetingTimes[index].meetingTimeSlot.end.dateTime).toISOString();
          meetingTimes[index].meetingTimeSlot.start.dateTime = startTime;
          meetingTimes[index].meetingTimeSlot.end.dateTime = endTime;
          timeslots.push({
            value: meetingTimes[index].meetingTimeSlot,
            label: moment(startTime).format('hh:mm A') + ' - ' + moment(endTime).format('hh:mm A'),
          });

        }
        this.slotsList = timeslots.sort((a: any, b: any) => a.value.start.dateTime - b.value.start.dateTime);

      } else {
        this.messageService.add({ severity: 'info', summary: 'No slots available!', detail: 'No slots are available for given date.' })
      }
    })
  }
}
