<div class="infoContainer">
    <div class="pageInfo">
        <div style="width: 100%; display: flex; justify-content: space-between; align-items:center;">
            <div>
                <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">Joining Cohort Fields</p>
            </div>
            <div>
                <button pButton pRipple label="Add Cohort" (click)="displayAddCohort = true"
                class="p-button-raised p-button save-align" [loading]="addBtnLoading"></button>                           
            </div>    
        </div>
    </div>
</div>
<p-sidebar model="true" class="addRole-slider" [(visible)]="displayAddCohort || displayEditCohort" styleClass="p-sidebar-lg"
    [showCloseIcon]="true" position="right" (onHide)="sliderClose()">

    <div class="cohort-add-header">
        <div class="cohort-heading">{{displayEditCohort? 'Edit Cohort' : 'Add Cohort'}}</div>
        <div class="header-btn">
            <button pButton pRipple type="submit"
                [loading]="publishBtnLoading" (click)="displayEditCohort ? ( isEdited ? confirmUpdate() : displayEditCohort = false ) : saveCohort()" 
                [label]="displayEditCohort ? 'Update' : 'Save'" class="p-button-raised p-button-text button-align"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    <form class="cohortForm" [formGroup]="cohortForm">
    
        <span class="p-float-label container_input">
            <input type="text" id="inputtext" pInputText formControlName="cohort_name" (change)="isEdited = true"
            [ngClass]="{'invalid': clicked && cohortForm.controls['cohort_name'].errors}">
            <label for="inputtext">Cohort Name <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="cohortForm.get('cohort_name')" name="Cohort Name">
            </app-handle-error>
        </span>
    
        <span class="p-float-label  container_input">
            <p-calendar [readonlyInput]="true" formControlName="cohort_date" dateFormat="m/d/y" dataType="date" id="inputtext"
                [showIcon]="true" optionLabel="name" optionValue="name" (onFocus)="isEdited = true"
                [inputStyleClass]="clicked && cohortForm.controls['cohort_date'].errors ? 'invalid' : ''">
            </p-calendar>
            <label for="inputtext">Cohort Date <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="cohortForm.get('cohort_date')" name="Cohort Date">
            </app-handle-error>
        </span>
    
        <span class="p-float-label container_input">
            <p-dropdown class="dropdown" inputId="dropdown" [autoDisplayFirst]="false"  [filter]="true" (onChange)="isEdited = true" 
            formControlName="cohort_spoc" [options]="cohortSpocList" [style]="{width: '25vw'}" optionLabel="name"
            [styleClass]="clicked && cohortForm.controls['cohort_spoc'].errors ? 'invalid' : ''">
            </p-dropdown>
            <label for="dropdown">Cohort SPOC <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="cohortForm.get('cohort_spoc')" name="Cohort SPOC">
            </app-handle-error>
        </span>
    </form>
    <p-confirmDialog [style]="{width: '50vw'}" rejectLabel="Cancel" acceptLabel="Proceed" acceptIcon="" rejectIcon="" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

</p-sidebar>
<div class="infoContainer">
    <p-table [columns]="cohortCols" [value]="cohortList" [resizableColumns]="true" [rows]="pageSize"
    [loading]="loadingSpinner" [scrollable]="true" scrollHeight="75vh" scrollDirection="both" [selection]="selectedCohort"
    (selectionChange)="onSelectedCohortChange($event)" responsiveLayout="scroll" dataKey="id"
    [style]="{width: '70vw'}">

        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="table-header-left">
                    <label>Total List: <span [style]="{color: '#333333'}">{{ totalLength }}</span></label>
                </span>
                <span class="table-menu">
                    <p [style]="{paddingRight: '10px'}">View</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="getCohortByLimit()"
                        [style]="{ maxWidth: '5rem', fontSize: '0.9rem' }"></p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                        {{ first }} to {{ last }}
                    </button>
                    <button pButton (click)="prev()" [disabled]="isFirstPage()" icon="pi pi-angle-left"
                        class="p-button-secondary p-button-text simple-btn"></button>
                    <button pButton (click)="next()" [disabled]="isLastPage()" icon="pi pi-angle-right"
                        class="p-button-secondary p-button-text simple-btn"></button>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr class="table-heading">

                <!-- Table Header Checkbox and Selected div Commented for future use -->

                <!-- <th scope="col" [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th> -->

                <th [style]="{width: '15vw',paddingLeft: '2vw'}" scope="col" pSortableColumn="{{ cohort.field }}"
                    *ngFor="let cohort of columns" pResizableColumn>
                    {{ cohort.header }}
                    <p-sortIcon class="sort-icon" field="{{ cohort.field }}"></p-sortIcon>
                </th>
            </tr>
            <!-- <tr class="selection-operations-row" *ngIf="selectedCohort.length">
                <td pFrozenColumn class="selection-operations-options">
                    <span>{{selectedCohort.length}} Selected</span>
                </td>
            </tr> -->
        </ng-template>

        <ng-template pTemplate="body" let-user let-columns="columns">
            <tr class="row-candidate-list" [style]="{cursor: 'pointer'}">

                <!-- Table Data Checkbox Commented for future use -->

                <!-- <td [style]="{width: '5vw', justifyContent: 'center', padding: 0, margin: 0}" pFrozenColumn>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                </td> -->
                <td *ngFor="let col of columns" [style]="{width: '15vw'}" (click)="editSlider(user)">
                    <span [ngSwitch]="true">
                        <span *ngSwitchCase="col.field === 'cohort_name'">
                            <span [style]="{paddingLeft: '1vw'}">{{ user[col.field] || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'cohort_date'">
                            <span [style]="{paddingLeft: '1vw'}">{{ user[col.field] | date: dateFormat || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'cohort_spoc'">
                            <span [style]="{paddingLeft: '1vw'}">{{ user[col.field]?.name || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'createdAt'">
                            <span [style]="{paddingLeft: '1vw'}">{{ user[col.field] | date: dateFormat || '-'}}</span>
                        </span>
                        <span *ngSwitchCase="col.field === 'createdBy'">
                            <span [style]="{paddingLeft: '1vw'}">{{ user[col.field] || '-'}}</span>
                        </span>
    
                        <span *ngSwitchDefault>
                            {{user[col.field] ? user[col.field] : '-'}}
                        </span>
                    </span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td>No Records Found</td>
            </tr>
        </ng-template>
    </p-table>
</div>